import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../utils/axiosIntance";
import { LOGIN } from "../routes";
import CustomLoader from "../components/dashboard/loader/CustomLoader";

const ConfirmationToken = () => {
  const { token } = useParams();

  /* States for data */
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  /* Navigate component */
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    axiosInstance({
      method: "GET",
      url: `/auth/confirm-token?token=${token}`,
    })
      .then(({ status }) => {
        setData(status === 200);
      })
      .catch((err) => setData(false))
      .finally(() => setLoading(false));
  }, [token]);

  const resendEmail = () => {
    console.log(token);
  };

  return (
    <div className="flex flex-row h-full">
      <div className="flex flex-col w-full gap-7">
        {loading ? (
          <>
            <CustomLoader />
          </>
        ) : (
          <>
            <div className="flex flex-row">
              <i
                className="text-primary text-5xl font-roboto bi bi-chevron-left cursor-pointer"
                onClick={() => navigate(LOGIN)}
              ></i>
            </div>
            <div className="flex flex-row justify-center">
              {data ? (
                <i className="text-success text-5xl font-roboto bi bi-patch-check-fill"></i>
              ) : (
                <i className="text-danger text-5xl font-roboto bi bi-x-octagon-fill"></i>
              )}
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col">
                {data ? (
                  <>
                    <h1 className="text-primary font-roboto">
                      Tu correo ha sido confirmado
                    </h1>
                    <h6 className="text-gray-600 font-roboto mt-[7px] mb-0">
                      {"Haz click "}
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => navigate(LOGIN)}
                      >
                        {"aquí"}
                      </span>
                      {" para iniciar sesión"}
                    </h6>
                  </>
                ) : (
                  <>
                    <h1 className="text-primary font-roboto">
                      No pudimos confirmar tu correo
                    </h1>
                    <h6 className="text-gray-600 font-roboto mt-[7px] mb-0">
                      {"Haz click "}
                      <span
                        className="text-primary cursor-pointer"
                        onClick={resendEmail}
                      >
                        {"aquí"}
                      </span>
                      {
                        " para reenviar el link de confirmación a tu correo electrónico"
                      }
                    </h6>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmationToken;
