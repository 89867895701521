import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";import SquareLogo from "../../assets/imgs/logo/square.svg";
import { ACCOUNTS, BUDGET, CATEGORIES, REPORTS } from "../../routes";
import { useStateContext } from "../../context/ContextProvider";
import CustomLoader from "./loader/CustomLoader";
import { getSessionStorageItem } from "../../utils/service/localStorageService";
import AddAccountModal from "../AddAccountModal";
import { Button, Tooltip } from "antd";
import AccountsContext from "../../context/AccountContext";
import LoansContext from "../../context/LoanContext";
import CreditCardContext from "../../context/CreditCardContext";
import { Breadcrumb, Layout, Menu, theme } from 'antd';

const activeLink =
  "flex items-center gap-2 pl-2 pt-1 pb-2 rounded-lg text-primary text-md m-2 no-underline";

const normalLink =
  "flex items-center gap-2 pl-2 pt-1 pb-2 rounded-lg text-md text-gray-700 hover:bg-light m-2 no-underline";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, setRefetchTransactions } =
    useStateContext();

  const { email, id: userId } = JSON.parse(getSessionStorageItem("user"));

  const [accountsData, setAccountsData] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);

  /**
   * Handlers for the new account modal
   */
  const [newAccount, setNewAccount] = useState(false);

  const toggleNewAccount = () => setNewAccount(!newAccount);

  /**
   * Handler for the new category filter
   */
  const [newCategoryFilter, setNewCategoryFilter] = useState(false);

  const toggleNewCategoryFilter = () =>
    setNewCategoryFilter(!newCategoryFilter);

  const { accounts, fetchAccounts } = useContext(AccountsContext);
  const { loans, fetchLoans } = useContext(LoansContext);
  const { creditCards, fetchCreditCards } = useContext(CreditCardContext);

  useEffect(() => {
    fetchAccounts();
    fetchLoans();
    fetchCreditCards();
  }, []);
  
  const [collapsed, setCollapsed] = useState(false);
  const { Header, Content, Footer, Sider } = Layout;

  return (
    <Sider 
      collapsible 
      collapsed={!activeMenu}
      trigger={null}
      className="sidebar"
    >
      <div className="ml-3 w-full h-screen  overflow-auto pb-10">
        {activeMenu ? (
          <>
            <div className="flex justify-between items-center">
              <Link
                to={`${ACCOUNTS}/0`}
                className="items-center gap-2 ml-2 mt-4 flex text-primary tracking-tight no-underline"
                onClick={() => {}}
              >
                <img
                  src={SquareLogo}
                  alt="logo"
                  style={{ width: "32px", height: "32px" }}
                />
                <span>OnMission</span>
              </Link>

              {/* <button
                type="button"
                onClick={() => {}}
                className="text-xl rounded-full p-3 hover:bg-gray-100 mt-4 "
              >
                <i className="bi bi-three-dots-vertical"></i>
              </button> */}
            </div>
            <div>
              <div>
                {/* MAIN MENU ITEM */}
                <NavLink
                  to={`/cuentas/0`}
                  key="Todas las cuentas"
                  onClick={() => {}}
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <i className="bi bi-bank2"></i>
                  <span className="capitalize">Todas las cuentas</span>
                </NavLink>
                <NavLink
                  to={BUDGET}
                  key="Presupuestos"
                  onClick={() => {}}
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <i className="bi bi-piggy-bank"></i>
                  <span className="capitalize">Presupuestos</span>
                </NavLink>
                <NavLink
                  to={REPORTS}
                  key="Reportes"
                  onClick={() => {}}
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <i className="bi bi-bar-chart-fill"></i>
                  <span className="capitalize">Reportes</span>
                </NavLink>

                {/* ACOUNTS MENU ITEM */}

                <div className="flex flex-row justify-start items-center align-middle gap-2 ">
                  <p className="text-gray-700 ml-2 mt-2">Cuentas</p>
                  <i
                    onClick={() => toggleNewAccount()}
                    className="bi bi-plus-circle-dotted h-4 w-4 justify-content-center hover:cursor-pointer"
                  ></i>
                </div>

                {/* ACCOUNTS ITEM */}
                {accountsLoading ? (
                  <CustomLoader />
                ) : (
                  accounts.map((account) => {
                    const { color, name, id } = account;
                    return (
                      <NavLink
                        to={`/cuentas/${id}`}
                        key={id}
                        onClick={() => {}}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        <i
                          className="bi bi-circle-fill"
                          style={{ color: color, fontSize: "12px" }}
                        ></i>
                        <span className="capitalize">{name}</span>
                      </NavLink>
                    );
                  })
                )}

                {/* LOANS ITEM */}
                {accountsLoading ? (
                  <CustomLoader />
                ) : (
                  loans.map((account) => {
                    const { color, name, id } = account;
                    return (
                      <NavLink
                        to={`/cuentas/${id}`}
                        key={id}
                        onClick={() => {}}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        <i
                          className="bi bi-circle-fill"
                          style={{ color: color, fontSize: "12px" }}
                        ></i>
                        <span className="capitalize">{name}</span>
                      </NavLink>
                    );
                  })
                )}

                {/* CREDITCARDS ITEM */}
                {accountsLoading ? (
                  <CustomLoader />
                ) : (
                  creditCards?.map((account) => {
                    const { color, name, id } = account;
                    return (
                      <NavLink
                        to={`/cuentas/${id}`}
                        key={id}
                        onClick={() => {}}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        <i
                          className="bi bi-circle-fill"
                          style={{ color: color, fontSize: "12px" }}
                        ></i>
                        <span className="capitalize">{name}</span>
                      </NavLink>
                    );
                  })
                )}

                {/* ADMINISTRACION MENU ITEM */}
                <p className="text-gray-700 ml-2 mt-2">Administración</p>
                
                <NavLink
                  to={CATEGORIES}
                  key="Categorias"
                  onClick={() => {}}
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <i className="bi bi-pin-angle-fill"></i>
                  <span className="capitalize">Categorías</span>
                </NavLink>
              </div>
            </div>
            <div className="absolute bottom-5 right-5">
              <button
                type="button"
                className="btn bg-primary text-white hover:bg-light"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
              >
                <i className="bi bi-arrow-bar-left"></i>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <Link
                to="/"
                className="items-center gap-2 ml-2 mt-4 flex text-primary tracking-tight"                
              >
                <img
                  onClick={() => console.log('Side Menu', !activeMenu)}
                  src={SquareLogo}
                  alt="logo"
                  className="hover:cursor-pointer"
                  style={{ width: "32px", height: "32px" }}
                />
              </Link>
            </div>

            <div>
              <div>
                {/* MAIN MENU ITEM */}
                <Tooltip
                  placement="right"
                  title={"Todas las cuentas"}
                  color="#0D6EFD"
                >
                  <NavLink
                    to={`${ACCOUNTS}/0`}
                    key="Todas las cuentas"
                    onClick={() => {}}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <i className="bi bi-bank2"></i>
                  </NavLink>
                </Tooltip>

                <Tooltip
                  placement="right"
                  title={"Presupuestos"}
                  color="#0D6EFD"
                >
                  <NavLink
                    to={BUDGET}
                    key="Presupuestos"
                    onClick={() => {}}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <i className="bi bi-piggy-bank"></i>
                  </NavLink>
                </Tooltip>

                <Tooltip placement="right" title={"Reportes"} color="#0D6EFD">
                  <NavLink
                    to={REPORTS}
                    key="Reportes"
                    onClick={() => {}}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <i className="bi bi-bar-chart-fill"></i>
                  </NavLink>
                </Tooltip>

                {/* ACOUNTS MENU ITEM */}
                <p className="text-gray-700 ml-2 mt-2">
                  <i className="bi bi-chevron-down"></i>
                </p>

                {/* ACCOUNTS ITEM */}
                {accountsLoading ? (
                  <CustomLoader />
                ) : (
                  accountsData.map((account) => {
                    const { color, name, id } = account;

                    return (
                      <Tooltip placement="right" title={name} color="#0D6EFD">
                        <NavLink
                          to={`/cuentas/${id}`}
                          key={id}
                          onClick={() => {}}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                        >
                          <i
                            className="bi bi-circle-fill"
                            style={{ color: color, fontSize: "12px" }}
                          ></i>
                        </NavLink>
                      </Tooltip>
                    );
                  })
                )}

                <div className="flex flex-col gap-3 items-center">
                  <Tooltip
                    placement="right"
                    title={"Añadir cuenta nueva"}
                    color="#0D6EFD"
                  >
                    <Button
                      className="bg-primary text-white"
                      onClick={() => toggleNewAccount()}
                    >
                      <i className="bi bi-plus"></i>
                    </Button>
                  </Tooltip>

                  <Tooltip
                    color="#0D6EFD"
                    p
                    placement="right"
                    title={"Añadir filtro de categoría"}
                  >
                    <Button
                      className="bg-primary text-white"
                      onClick={() => toggleNewCategoryFilter()}
                    >
                      <i className="bi bi-funnel"></i>
                    </Button>
                  </Tooltip>
                </div>

                {/* APP MENU ITEM */}
                
                {/* ADMINISTRACION MENU ITEM */}
                <p className="text-gray-700 ml-2 mt-2">
                  <i className="bi bi-chevron-down"></i>
                </p>                

                <Tooltip placement="right" title={"Categorías"} color="#0D6EFD">
                  <NavLink
                    to={CATEGORIES}
                    key="Categorias"
                    onClick={() => {}}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <i className="bi bi-pin-angle-fill"></i>
                  </NavLink>
                </Tooltip>
              </div>
            </div>
            <div className="absolute bottom-5 right-5">
              <button
                type="button"
                className="btn bg-primary text-white hover:bg-light"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
              >
                <i className="bi bi-arrow-bar-right"></i>
              </button>
            </div>
          </>
        )}
      </div>
      <AddAccountModal
        isModalOpen={newAccount}
        toggleNewAccount={toggleNewAccount}
        email={email}
        fetchAccounts={fetchAccounts}
        fetchLoans={fetchLoans}
        fetchCreditCards={fetchCreditCards}
      />
    </Sider>
  );
};

export default Sidebar;
