import React from "react";
import { NavLink } from "react-router-dom";

import {
  GENERAL_SETTINGS,
  SECURITY_SETTINGS,
  NOTIFICATIONS_SETTINGS,
  IMPORT_FILE,
  EXPORT_FILE,
  INTEGRATIONS_SETTINGS,
} from "../../routes";

const activeLink =
  "flex items-center gap-5 pl-2 pt-1 pb-2 rounded-lg text-primary text-md m-2 no-underline";

const normalLink =
  "flex items-center gap-5 pl-2 pt-1 pb-2 rounded-lg text-md text-gray-500 hover:bg-light m-2 no-underline";

const Sidebar = () => {
  
  return (
    <div className="ml-3 w-72 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto">
      <div>
        <div>
          {/* MAIN MENU ITEM */}
          <NavLink
            to={GENERAL_SETTINGS}
            key="General"
            onClick={() => {}}
            className={({ isActive }) =>
              isActive ? activeLink : normalLink
            }
          >
            <span className="capitalize">General</span>
          </NavLink>
          <NavLink
            to={SECURITY_SETTINGS}
            key="Seguridad"
            onClick={() => {}}
            className={({ isActive }) =>
              isActive ? activeLink : normalLink
            }
          >
            <span className="capitalize">Seguridad</span>
          </NavLink>
          <NavLink
            to={NOTIFICATIONS_SETTINGS}
            key="Notificaciones"
            onClick={() => {}}
            className={({ isActive }) =>
              isActive ? activeLink : normalLink
            }
          >
            <span className="capitalize">Notificaciones</span>
          </NavLink>
          <NavLink
            to={IMPORT_FILE}
            key="Importar"
            onClick={() => {}}
            className={({ isActive }) =>
              isActive ? activeLink : normalLink
            }
          >
            <span className="capitalize">Importar</span>
          </NavLink>
          <NavLink
            to={EXPORT_FILE}
            key="Exportar"
            onClick={() => {}}
            className={({ isActive }) =>
              isActive ? activeLink : normalLink
            }
          >
            <span className="capitalize">Exportar</span>
          </NavLink>
          <NavLink
            to={INTEGRATIONS_SETTINGS}
            key="Integraciones"
            onClick={() => {}}
            className={({ isActive }) =>
              isActive ? activeLink : normalLink
            }
          >
            <span className="capitalize">Integraciones</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
