export const setLocalStorageItem = (storage, data) => {
  return window.localStorage.setItem(storage, JSON.stringify(data));
};

export const getLocalStorageItem = (storage) => {
  const data = localStorage.getItem(storage);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return false;
    }
  }
};

export const removeLocalStorageItem = (storage) => {
  return window.localStorage.removeItem(storage);
};

export const setSessionStorageItem = (storage, data) => {
  return window.sessionStorage.setItem(storage, data);
};

export const getSessionStorageItem = (storage) => {
  const data = sessionStorage.getItem(storage);
  if (data) {
    try {
      return data;
    } catch (e) {
      return false;
    }
  }
};

export const removeSessionStorageItem = (storage) => {
  return window.sessionStorage.removeItem(storage);
};
