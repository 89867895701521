import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "../utils/axiosIntance";
import { ToastContainer, toast } from "react-toastify";
import CustomModalLoader from "./customModalLoader/CustomModalLoader";
import AccountsContext from "../context/AccountContext";

const EditCreditCardModal = ({
  isModalOpen,
  toggleEditCreditCard,
  account,
  fetchAccountData,
}) => {
  const [form] = Form.useForm();

  const [step, setStep] = useState(0);
  const [isLoadingEditing, setIsLoadingEditing] = useState(false);

  const { fetchAccounts } = useContext(AccountsContext);

  const onOk = async () => {
    try {
      const { nombreTarjeta, limiteTdc, tasaInteres } =
        await form.validateFields();
      setIsLoadingEditing(true);
      const updatedAccount = {
        ...account,
        name: nombreTarjeta,
        limit: limiteTdc,
        interestRate: tasaInteres,
      };

      const params = {
        ...updatedAccount,
      };
      axiosInstance({
        method: "put",
        url: "/accounts/update-account",
        data: params,
      })
        .then(() => setStep(1))
        .catch(() => {
          toast.error("Error al crear la cuenta", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        })
        .finally(() => {
          setIsLoadingEditing(false);
          form.resetFields();
          fetchAccountData();
          fetchAccounts();
        });
    } catch (errorInfo) {
      console.error("Validate Failed!");
      console.log("ErrorInfo: ", errorInfo);
      return;
    }
  };

  const handleClosemodal = () => {
    form.resetFields();
    setStep(0);
    toggleEditCreditCard();
    isModalOpen = false;
  };

  useEffect(() => {
    if (isModalOpen && step === 0) {
      form.setFieldsValue({
        nombreTarjeta: account.name || "",
        limiteTdc: account.limit || "",
        tasaInteres: account.interestRate || "",
      });
    }
  }, [isModalOpen, form, account]);

  return (
    <>
      <Modal
        title="Editar tarjeta de credito"
        open={isModalOpen}
        onCancel={handleClosemodal}
        footer={
          step === 0
            ? [
                <Popconfirm
                  title="Desea cancelar?"
                  onConfirm={handleClosemodal}
                  cancelText="No"
                  okText="Sí"
                  okButtonProps={{ className: "bg-primary text-white" }}
                >
                  <Button>Cancelar</Button>
                </Popconfirm>,
                <Popconfirm
                  title="Desea continuar?"
                  onConfirm={onOk}
                  cancelText="No"
                  okText="Sí"
                  okButtonProps={{ className: "bg-primary text-white" }}
                >
                  <Button className="bg-primary text-white">Continuar</Button>
                </Popconfirm>,
              ]
            : [
                <Button
                  className="bg-primary text-white"
                  onClick={handleClosemodal}
                >
                  Continuar
                </Button>,
              ]
        }
      >
        {step === 0 && (
          <Form form={form} component={false}>
            <div className="flex flex-col gap-2">
              <label>Nombre de la tarjeta</label>
              <Form.Item
                className="mb-2"
                name="nombreTarjeta"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="Ingrese el nombre de la tarjeta de crédito"
                />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              <label>Límite de tarjeta de crédito</label>
              <Form.Item
                name="limiteTdc"
                className="mb-2"
                rules={[
                  {
                    required: false,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa el límite de tu tarjeta de crédito"
                  min={0}
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              <label>Tasa de interés</label>
              <Form.Item
                name="tasaInteres"
                className="mb-2"
                rules={[
                  {
                    required: false,
                    message: "Este campo es obligatorio!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || (value >= 0 && value <= 100)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("El valor debe estar entre 0 y 100!")
                      );
                    },
                  }),
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa la tasa de interés de tu tarjeta de crédito"
                  min={0}
                  max={100}
                  addonAfter={"%"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
          </Form>
        )}
        {step === 1 && (
          <div className="container">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-row w-full justify-center">
                <i
                  className="bi bi-patch-check-fill"
                  style={{
                    color: "#198754",
                    fontSize: "68px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </div>
              <div className="flex flex-row w-full justify-center">
                <h4>La tarjeta se ha editado con éxito</h4>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <CustomModalLoader isOpen={isLoadingEditing} />
      <ToastContainer />
    </>
  );
};

export default EditCreditCardModal;
