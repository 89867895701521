export const colourOptions = [
  { label: "Escarlata", value: "Escarlata", color: "#E41D32" },
  { label: "Naranja Zanahoria", value: "naranjaZanahoria", color: "#EB5F32" },
  { label: "Naranja", value: "naranja", color: "#F39329" },
  { label: "Oro", value: "oro", color: "#FCC30D" },
  { label: "Amarillo", value: "amarillo", color: "#FFED00" },
  { label: "Verde Oliva", value: "verdeOliva", color: "#B2B745" },
  { label: "Verde", value: "verde", color: "#528A61" },
  { label: "Turquesa", value: "turquesa", color: "#137E93" },
  { label: "Azul", value: "azul", color: "#0072BA" },
  { label: "Azul Marino", value: "azulMarino", color: "#4C5684" },
  { label: "Púrpura", value: "purpura", color: "#AF08C4" },
  { label: "Púrpura Profundo", value: "purpuraProfundo", color: "#763455" },
];
