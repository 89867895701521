import { useContext, createContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ACCOUNTS, LOGIN } from "../routes/index";
import {
  removeSessionStorageItem,
  setSessionStorageItem,
} from "../utils/service/localStorageService";
import { useCallback } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();

  const login = useCallback(
    async (user, token) => {
      setSessionStorageItem("token", token);
      setSessionStorageItem("user", JSON.stringify(user));

      navigate(`${ACCOUNTS}/0`);
    },
    [navigate]
  );

  const logout = useCallback(() => {
    removeSessionStorageItem("token");
    removeSessionStorageItem("user");
    navigate(LOGIN);
  }, [navigate]);

  const value = useMemo(() => ({ login, logout }), [login, logout]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => useContext(UserContext);
