import { Route, Routes as Switch } from "react-router-dom";

import * as Routes from "./routes";

import "./App.css";
//import MainLayout from "./layout/MainLayout-1";
import SettingsLayout from "./layout/SettingsLayout";
import ConfirmationToken from "./pages/ConfirmationToken";

import { ProtectRoutes } from "./hooks/protectRoutes";
import Landing from "./pages/landing/Landing";
import MainLayout from "./layout/MainLayout";
import LoginForm from "./components/login/LoginForm";
import SignUpForm from "./components/login/SignUpForm";
import TokenSent from "./components/login/TokenSent";
import { useState } from "react";
import Auth from "./pages/Auth/Auth";

export function App() {
  const [userEmail, setUserEmail] = useState("");

  return (
    <Switch>
      <Route path={Routes.LANDING} element={<Landing />} />
      <Route path={Routes.LOGIN} element={<Auth children={<LoginForm />} />} />
      <Route
        path={Routes.SIGN_UP}
        element={<Auth children={<SignUpForm setUserEmail={setUserEmail} />} />}
      />
      <Route
        path={Routes.CONFIRM_SIGN_UP}
        element={
          <Auth
            children={
              <TokenSent userEmail={userEmail} setUserEmail={setUserEmail} />
            }
          />
        }
      />
      {/* <Route path={Routes.CONFIRMATION_TOKEN} element={<ConfirmationToken />} /> */}
      <Route
        path={Routes.CONFIRMATION_TOKEN}
        element={<Auth children={<ConfirmationToken />} />}
      />
      <Route element={<ProtectRoutes />}>
        {Routes.DASHBOARD_ROUTES.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <MainLayout>
                  <route.component />
                </MainLayout>
              }
            ></Route>
          );
        })}
        {Routes.ACCOUNT_ROUTES.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <SettingsLayout>
                  <route.component />
                </SettingsLayout>
              }
            ></Route>
          );
        })}
      </Route>
    </Switch>
  );
}
