import React, { useCallback, useEffect, useState } from "react";
import { dollarUS } from "../../../../utils/dollarFormat";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";

import axiosInstance from "../../../../utils/axiosIntance";
import CustomLoader from "../../loader/CustomLoader";
import InfiniteScroll from "react-infinite-scroller";

import esES from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "../../../../context/ContextProvider";
import ConfirmDeleteTransactionModal from "./ConfirmDeleteTransactionModal";
import NewTransactionAccounModal from "./NewTransactionAccounModal";
import AddCategoryFilter from "../../../AddCategoryFilter";
import EditCreditCardModal from "../../../EditCreditCardModal";
import PropTypes from 'prop-types';

dayjs.locale("es");

const CellCategory = ({ category }) => {
  if (category.id !== null) {
    const { bgColor, textColor, name } = category;

    return (
      <div className="flex flex-row h-full items-center">
        <div
          className="text-center content-center text-xs rounded-full w-3 h-3"
          style={{
            color: textColor,
            backgroundColor: bgColor,
            marginRight: "5px",
          }}
        ></div>
        <span>{name}</span>
      </div>
    );
  } else {
    return (
      <div className="category-cell">
        <span>-</span>
      </div>
    );
  }
};

CellCategory.propTypes = {
  category: PropTypes.object,
};

const DropdownTag = ({ row }) => {
  const editTransactionTag = (tagColor) => {
    const params = {
      tag: tagColor,
      userId: row.user.id,
      account: row.account.id,
      subcategoryId: row.subcategory ? row.subcategory.id : null,
      id: row.id,
      description: row.description,
      transactionTypeEnum: row.transactionTypeEnum,
      payee: row.payee,
      type: row.transactionTypeEnum,
      ammount: row.ammount,
    };

    axiosInstance({
      url: "/transactions/update-massive-transaction",
      method: "put",
      data: [params],
    })
      .then(({ data }) => console.log(data))
      .catch((err) => console.log(err));
  };
  const tags = [
    {
      label: "Sin tag",
      key: "",
      icon: <i className="bi bi-tag text-primary"></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
    {
      label: "Rojo",
      key: "#E41D32",
      icon: <i className="bi bi-tag-fill" style={{ color: "#E41D32" }}></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
    {
      label: "Naranja",
      key: "#F39329",
      icon: <i className="bi bi-tag-fill" style={{ color: "#F39329" }}></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
    {
      label: "Amarillo",
      key: "#FFED00",
      icon: <i className="bi bi-tag-fill" style={{ color: "#FFED00" }}></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
    {
      label: "Verde",
      key: "#528A61",
      icon: <i className="bi bi-tag-fill" style={{ color: "#528A61" }}></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
    {
      label: "Azul",
      key: "#0072BA",
      icon: <i className="bi bi-tag-fill" style={{ color: "#0072BA" }}></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
    {
      label: "Púrpura",
      key: "#AF08C4",
      icon: <i className="bi bi-tag-fill" style={{ color: "#AF08C4" }}></i>,
      onClick: (e) => editTransactionTag(e.key),
    },
  ];

  const tagsProps = { items: tags };

  return (
    <Dropdown menu={tagsProps}>
      <Space>
        {row.tag ? (
          <i className="bi bi-tag-fill" style={{ color: row.tag }}></i>
        ) : (
          <i className="text-primary bi bi-tag"></i>
        )}
      </Space>
    </Dropdown>
  );
};

DropdownTag.propTypes = {
  row: PropTypes.object,
};

const Detailed = ({
  account,
  user: { email, userId },
  setLastUpdate,
  totalStates: { setTotalInflow, setTotalOutflow, setTotalSelectedBalance },
  setTotalSelectedRows,
  setSelectedAccount,
}) => {
  /**
   * Context to refetch transactions from sidebar
   */
  const { refetchTransactions, setRefetchTransactions } = useStateContext();
  const [searchTimeout, setSearchTimeout] = useState(null);

  /**
   * Editable cells state
   */
  const [form] = Form.useForm();

  const [newCategoryFilter, setNewCategoryFilter] = useState(false);
  const [editCreditCard, setEditCreditCard] = useState(false);
  const [accountData, setAccountData] = useState({});

  /**
   * State for pagination
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  /**
   * State for new transaction
   */
  const [editingKey, setEditingKey] = useState(null);

  /**
   * Handle states of new transaction inflow and outflow
   */
  const [newInflow, setNewInflow] = useState(null);
  const [newOutflow, setNewOutflow] = useState(null);

  const toggleNewCategoryFilter = () =>
    setNewCategoryFilter(!newCategoryFilter);

  const toggleEditCreditCard = () => setEditCreditCard(!editCreditCard);
  /**
   * Handle confirm to dlete transactions
   */
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);

  /**
   * Handle new transaction account
   */
  const [
    isOpenNewTransactionAccountModal,
    setIsOpenNewTransactionAccountModal,
  ] = useState(false);

  const toggleNewTransaccionAccountModal = () =>
    setIsOpenNewTransactionAccountModal(!isOpenNewTransactionAccountModal);

  /**
   * Handle search state
   */
  const [search, setSearch] = useState("");
  const [itsCreditCard, setItsCreditCard] = useState(false);
  const fetchAccountData = useCallback(() => {
    if (account === null || account === 0) return;
    axiosInstance({
      method: "GET",
      url: `/accounts/${account}/${userId}`,
    })
      .then((res) => {
        setAccountData(res.data);
        setItsCreditCard(res.data.type === "6");
        setSelectedAccount([res.data]);
      })
      .catch((err) => console.log(err));
  }, [account]);

  useEffect(() => {
    fetchAccountData();
  }, [account]);

  /**
   * Fetching transactions data from backend
   */
  const fetchTransactions = useCallback(
    (pageParam) => {
      axiosInstance({
        url: `/transactions/get-transactions/${pageParam}/20`,
        method: "GET",
        params: {
          email: email,
          account: account,
        },
      })
        .then(({ data }) => {
          const { content, number, totalPages } = data;
          setCurrentPage(pageParam);

          const newTransactions = Object.values(content || {})?.map((tra) => ({
            key: tra.id,
            ...tra,
          }));
         
          const merge = [...transactions, ...newTransactions]
            .reverse()
            .map(item => [item.id, item])
            .reduce((map, [id, item]) => map.set(id, item), new Map())
            .values();

          const uniqueTransactions = Array.from(merge);

          setTransactions([...uniqueTransactions]);

          if (number < totalPages) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadingTransactions(false);
          setRefetchTransactions(false);
        });
    },
    [account, email, setRefetchTransactions, transactions]
  );

  const handleInfiniteScroll = () => {
    fetchTransactions(currentPage + 1);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  /* States for categories data */
  const [categories, setCategories] = useState([]);

  /* States for accounts data */
  const [accounts, setAccounts] = useState([]);

  /**
   * Loading categories and accounts
   */
  const [loadingOptions, setLoadingOptions] = useState(false);

  // State to keep track of the current sort column and order
  const [sortState, setSortState] = useState({ column: null, order: null });

  // Update the sort state when the table is sorted
  const handleTableChange = (pagination, filters, sorter) => {
    setSortState({ column: sorter.field, order: sorter.order });
  };


  // Sort function that sorts by the current sort column and order
  const sortTransactions = (a, b) => {
    if (!sortState.column || !sortState.order) return 0;

    const isAsc = sortState.order === 'ascend';
    if (a[sortState.column] < b[sortState.column]) return isAsc ? -1 : 1;
    if (a[sortState.column] > b[sortState.column]) return isAsc ? 1 : -1;
    return 0;
  };

  useEffect(() => {
    setEditingKey(null);
    setNewInflow(null);
    setNewOutflow(null);
    setLoadingOptions(true);

    axiosInstance({
      url: `/transactions/get-transactions/1/20`,
      method: "GET",
      params: {
        email: email,
        account: account,
      },
    })
      .then(({ data }) => {
        const { content, number, totalPages } = data;
        setLastUpdate(content.length > 0 ? content[0].createdAt : "-");
        setCurrentPage(1);

        const newTransactions = Object.values(content || {})?.map(
          (tra) => ({
            key: tra.id,
            ...tra,
          })
        ).sort(sortTransactions);
        
        setTransactions(newTransactions);
        setSelectedRows([]);

        if (number < totalPages) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => console.log(err));

    // Fetching categories
    axiosInstance({
      method: "get",
      url: `/subcategory/${userId}`,
    })
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => console.log(err));

    // Fetching accounts
    axiosInstance({
      method: "GET",
      url: `/accounts/list-accounts/${userId}`,
    })
      .then(({ data }) => {
        if (Number(account) !== 0) {
          setSelectedAccount(data.filter((acc) => acc.id === Number(account)));
          setAccounts(data.filter((acc) => acc.id !== Number(account)));
        } else {
          setAccounts(data);
          setSelectedAccount(null);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingOptions(false));

    // Fetching transactions total ammount
    axiosInstance({
      url: `/transactions/${account}/${userId}`,
      method: "get",
    })
      .then(({ data: { inflows, outflows } }) => {
        setTotalInflow(inflows);
        setTotalOutflow(outflows);
      })
      .catch((err) => console.log(err));
  }, [
    account,
    email,
    userId,
    setLastUpdate,
    setTotalInflow,
    setTotalOutflow,
    setSelectedAccount,
  ]);

  useEffect(() => {
    if (refetchTransactions) {
      setLoadingTransactions(true);

      axiosInstance({
        url: `/transactions/get-transactions/1/20`,
        method: "GET",
        params: {
          email: email,
          account: account,
        },
      })
        .then(({ data }) => {
          const { content, number, totalPages } = data;
          setLastUpdate(content.length > 0 ? content[0].createdAt : "-");
          setCurrentPage(1);

          const newTransactions = Object.values(content || {})?.map(
            (tra) => ({
              key: tra.id,
              ...tra,
            })
          ).sort(sortTransactions);

          setTransactions(newTransactions);
          setSelectedRows([]);

          if (number < totalPages) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingTransactions(false));
    }
  }, [refetchTransactions, account, email, setLastUpdate]);

  const handleEditTransactionSubcategory = (category) => {
    if (selectedRows.length > 0) {
      const rowsToEdit = selectedRows
        .filter((transaction) => transaction.account.id !== account.id)
        .map((transaction) => {
          const {
            id: tId,
            description,
            transactionTypeEnum,
            payee,
            ammount,
            tag,
            account,
          } = transaction;

          return {
            id: tId,
            key: tId,
            userId: userId,
            description: description,
            transactionTypeEnum: transactionTypeEnum,
            payee: payee,
            type: transactionTypeEnum,
            ammount: ammount,
            tag: tag,
            accountId: account.id,
            subcategoryId: category,
          };
        });

      if (rowsToEdit.length > 0) {
        setLoadingTransactions(true);

        axiosInstance({
          url: "/transactions/update-massive-transaction",
          method: "put",
          data: rowsToEdit,
        })
          .then(() => {
            setLoadingTransactions(true);

            axiosInstance({
              url: `/transactions/get-transactions/${1}/20`,
              method: "GET",
              params: {
                email: email,
                account: account,
              },
            })
              .then(({ data }) => {
                const { content, number, totalPages } = data;
                setCurrentPage(1);

                const newTransactions = Object.values(content || {})?.map(
                  (tra) => ({
                    key: tra.id,
                    ...tra,
                  })
                ).sort(sortTransactions);
                
                setTransactions(newTransactions);

                if (number < totalPages) {
                  setHasMore(true);
                } else {
                  setHasMore(false);
                }
              })
              .catch((err) => console.log(err))
              .finally(() => setLoadingTransactions(false));
          })
          .catch((err) => {
            toast.error("No se pudo modificar la transacción", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
            console.log(err);
          });
      }
    }
  };

  const handleEditTransactionAccount = (account) => {
    if (selectedRows.length > 0) {
      const rowsToEdit = selectedRows
        .filter((transaction) => transaction.account.id !== account.id)
        .map((transaction) => {
          const {
            id: tId,
            description,
            transactionTypeEnum,
            payee,
            ammount,
            tag,
            subcategory,
          } = transaction;

          return {
            id: tId,
            userId: userId,
            description: description,
            transactionTypeEnum: transactionTypeEnum,
            payee: payee,
            type: transactionTypeEnum,
            ammount: ammount,
            tag: tag,
            accountId: account,
            subcategoryId: subcategory?.id || null,
          };
        });

      if (rowsToEdit.length > 0) {
        setLoadingTransactions(true);

        axiosInstance({
          url: "/transactions/update-massive-transaction",
          method: "put",
          data: rowsToEdit,
        })
          .then(() => {
            setLoadingTransactions(true);

            axiosInstance({
              url: `/transactions/get-transactions/${1}/20`,
              method: "GET",
              params: {
                email: email,
                account: account,
              },
            })
              .then(({ data }) => {
                const { content, number, totalPages } = data;
                setCurrentPage(1);

                const newTransactions = Object.values(content || {})?.map(
                  (tra) => ({
                    key: tra.id,
                    ...tra,
                  })
                );

                setTransactions(newTransactions);

                if (number < totalPages) {
                  setHasMore(true);
                } else {
                  setHasMore(false);
                }
              })
              .catch((err) => console.log(err))
              .finally(() => setLoadingTransactions(false));
          })
          .catch((err) => {
            toast.error("No se pudo modificar la transacción", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
            console.log(err);
          });
      }
    }
  };

  const handleDeleteTransactions = () => {
    const transactions = selectedRows.map((row) => ({
      accountId: row.accountId,
      ammount: row.ammount,
      date: row.date,
      description: row.description,
      payee: "",
      subcategoryId: row.subcategory?.id || null,
      tag: "",
      transactionTypeEnum: row.transactionTypeEnum,
      userEmail: email,
      userId,
      id: row.id,
    }));

    setLoadingTransactions(true);

    axiosInstance({
      url: `/transactions/deleteTransaction`,
      method: "POST",
      data: transactions,
    })
      .then(() => {
        axiosInstance({
          url: `/transactions/get-transactions/1/20`,
          method: "GET",
          params: {
            email: email,
            account: account,
          },
        })
          .then(({ data }) => {
            const { content, number, totalPages } = data;
            setLastUpdate(content.length > 0 ? content[0].createdAt : "-");
            setCurrentPage(1);

            const newTransactions = Object.values(content || {})?.map(
              (tra) => ({
                key: tra.id,
                ...tra,
              })
            ).sort(sortTransactions);

            setTransactions(newTransactions);

            setSelectedRows([]);

            if (number < totalPages) {
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoadingTransactions(false);
            toggleConfirmDeleteModal();
          });
      })
      .catch((err) => {
        toast.error("No se pudo eliminar la transacción", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
      })
      .finally(() => setLoadingTransactions(false));
  };

  const editOptions = () => {
    const categoriesOptions = loadingOptions
      ? [{ label: "Cargando...", key: "loading" }]
      : Object.values(categories || {})?.map(({ name, id, textColor }) => ({
          label: name,
          key: id+"cat",
          icon: (
            <i
              className="bi bi-circle-fill"
              style={{
                color: textColor,
                fontSize: "12px",
              }}
            ></i>
          ),
          className: "text-primary text-sm",
        }));

    const accountsOptions = loadingOptions
      ? [{ label: "Cargando...", key: "loading" }]
      : Object.values(accounts || {})?.map(({ name, id, color }) => ({
          label: name,
          key: id+"acc",
          icon: (
            <i
              className="bi bi-circle-fill"
              style={{ color: color, fontSize: "12px" }}
            ></i>
          ),
          className: "text-primary text-sm",
        }));

    return [
      {
        label: "Duplicar",
        key: "duplicate",
        icon: <i className="bi bi-files"></i>,
        onClick: (e) => {
          console.log(e);
        },
        //className: "text-primary text-sm",
      },
      {
        label: "Marcador",
        key: "tag",
        icon: <i className="bi bi-box-arrow-in-right"></i>,
        onClick: (e) => {
          console.log(e);
        },
        //className: "text-primary text-sm",
      },
      {
        label: "Categorizar",
        key: "categorize",
        icon: <i className="bi bi-pin-angle"></i>,
        //className: "text-primary text-sm",
        children: categoriesOptions,
        onClick: (e) => {
          handleEditTransactionSubcategory(e.key);
        },
      },
      {
        label: "Mover",
        key: "move",
        icon: <i className="bi bi-box-arrow-in-right"></i>,
        //className: "text-primary text-sm",
        children: accountsOptions,
        onClick: (e) => {
          handleEditTransactionAccount(e.key);
        },
      },
      {
        label: "Eliminar",
        key: "delete",
        icon: <i className="bi bi-trash2"></i>,
        onClick: () => {
          if (selectedRows.length > 0) {
            toggleConfirmDeleteModal();
          } else {
            toast.error("Debe seleccionar una o más transacciones", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
          }
        },
        //className: "text-primary text-sm",
      },
    ];
  };

  const menuProps = { items: editOptions() };

  const rowSelection = {
    onChange: (selectedRowKeys, selected) => {
      let inflows = 0;
      let outflows = 0;

      for (const row of selected) {
        const { transactionTypeEnum, ammount } = row;

        inflows += transactionTypeEnum === "Inflow" ? ammount : 0;
        outflows += transactionTypeEnum === "Outflow" ? ammount : 0;
      }

      setTotalSelectedRows(selected.length);
      setTotalSelectedBalance(inflows - outflows);
      setSelectedRows(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.key === 0,
    }),
  };

  const handleAddTransaction = () => {
    if (editingKey !== 0) {
      form.resetFields();
      const newData = {
        key: 0,
        tag: "",
        date: dayjs,
        description: "",
        subcategory: "",
        ammount: 0,
        transactionTypeEnum: "",
      };
      setEditingKey(0);
      setTransactions([newData, ...transactions]);
    }
  };

  const saveNewtransaction = async (accountId) => {
    try {
      const { newDescription, newDate, newTag, newIn, newOut, newSubcategory } =
        await form.validateFields();

      const tipo = newIn ? "Inflow" : "Outflow";

      const param = {
        userId: userId,
        userEmail: email,
        description: newDescription,
        date: new Date(newDate.$d).toISOString().split("T")[0],
        type: tipo,
        transactionTypeEnum: tipo,
        payee: "",
        tag: newTag?.value || null,
        ammount: newIn || newOut,
        subcategoryId: newSubcategory || null,
        accountId: parseInt(accountId),
      };

      setLoadingTransactions(true);

      axiosInstance({
        url: "/transactions/add-transaction",
        method: "post",
        data: param,
      })
        .then(async () => {
          await axiosInstance({
            url: `/transactions/get-transactions/${1}/20`,
            method: "GET",
            params: {
              email: email,
              account: account,
            },
          })
            .then(({ data }) => {
              setIsOpenNewTransactionAccountModal(false);

              const { content, number, totalPages } = data;
              setCurrentPage(1);

              const newTransactions = Object.values(content || {})?.map(
                (tra) => ({
                  key: tra.id,
                  ...tra,
                })
              ).sort(sortTransactions);
              
              setTransactions(newTransactions);

              if (number < totalPages) {
                setHasMore(true);
              } else {
                setHasMore(false);
              }

              setEditingKey(null);
            })
            .catch((err) => console.log(err));

          await axiosInstance({
            url: `/transactions/${account}/${userId}`,
            method: "get",
          })
            .then(({ data: { inflows, outflows } }) => {
              setTotalInflow(inflows);
              setTotalOutflow(outflows);
            })
            .catch((err) => console.log(err));
        })
        .catch(() => {
          toast.error("No se pudo agregar la transacción", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          setIsOpenNewTransactionAccountModal(false);
        })
        .finally(() => {
          setLoadingTransactions(false);
        });
    } catch (error) {
      console.log("Validations failed: ", error);
    }
  };

  const cancelNewTransaction = () => {
    setEditingKey(null);
    setTransactions(
      transactions.filter((transaction) => transaction.key !== 0)
    );
  };

  const editTransaction = async (row) => {
    try {
      const { description, date, tag, editOut, editIn, subcategory } =
        await form.validateFields();

      const params = {
        id: row.id,
        userId,
        description,
        transactionTypeEnum: editIn ? "Inflow" : "Outflow",
        payee: "",
        type: editIn ? "Inflow" : "Outflow",
        ammount: editIn || editOut,
        tag: tag?.value || null,
        accountId: row.accountId,
        subcategoryId: subcategory || null,
        date: new Date(date.$d).toISOString().split("T")[0],
      };

      const transaction = [params];

      setLoadingTransactions(true);

      axiosInstance({
        url: "/transactions/update-massive-transaction",
        method: "put",
        data: transaction,
      })
        .then(() => {
          setLoadingTransactions(true);

          axiosInstance({
            url: `/transactions/get-transactions/1/20`,
            method: "GET",
            params: {
              email: email,
              account: account,
            },
          })
            .then(({ data }) => {
              const { content, number, totalPages } = data;
              setLastUpdate(content.length > 0 ? content[0].createdAt : "-");
              setCurrentPage(1);

              const newTransactions = Object.values(content || {})?.map(
                (tra) => ({
                  key: tra.id,
                  ...tra,
                })
              ).sort(sortTransactions);
              
              setTransactions(newTransactions);
              setSelectedRows([]);

              if (number < totalPages) {
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoadingTransactions(false));
        })
        .catch((err) => {
          toast.error("No se pudo modificar la transacción", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        })
        .finally(() => {
          form.resetFields();
          setEditingKey(null);
          setLoadingTransactions(false);
        });
    } catch (error) {
      console.log("Validations failed: ", error);
    }
  };

  const columns = [
    {
      title: <i className="text-primary bi bi-tag"></i>,
      dataIndex: "tag",
      render: (text, row) => {
        if (editingKey === row.key) {
          const tags = [
            {
              label: "N/A",
              value: "",
            },
            {
              label: (
                <i className="bi bi-tag-fill" style={{ color: "#E41D32" }}></i>
              ),
              value: "#E41D32",
            },
            {
              label: (
                <i className="bi bi-tag-fill" style={{ color: "#F39329" }}></i>
              ),
              value: "#F39329",
            },
            {
              label: (
                <i className="bi bi-tag-fill" style={{ color: "#FFED00" }}></i>
              ),
              value: "#FFED00",
            },
            {
              label: (
                <i className="bi bi-tag-fill" style={{ color: "#528A61" }}></i>
              ),
              value: "#528A61",
            },
            {
              label: (
                <i className="bi bi-tag-fill" style={{ color: "#0072BA" }}></i>
              ),
              value: "#0072BA",
            },
            {
              label: (
                <i className="bi bi-tag-fill" style={{ color: "#AF08C4" }}></i>
              ),
              value: "#AF08C4",
            },
          ];

          if (editingKey === 0) {
            return (
              <Form.Item className="mb-0" name="newTag">
                <Select
                  labelInValue
                  options={tags}
                  showArrow={false}
                />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item 
                initialValue={row.tag ? { value: row.tag } : { value: "" }}
                className="mb-0" name="tag">
                <Select labelInValue options={tags} showArrow={false}>
                  {tags.map((tag) => (
                    <Select.Option key={tag.value} value={tag.value}>
                      <i className="bi bi-tag-fill" style={{ color: row.tag }}></i>
                      {tag.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }
        } else {
          return <DropdownTag row={row} />;
        }
      },
      width: "6%",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      sorter: (a, b) => {
        return dayjs(a.date, "YYYY-MM-DD").unix() - dayjs(b.date, "YYYY-MM-DD").unix();
      },
      showSorterTooltip: false,
      defaultSortOrder: (sortState.column === "date" && sortState.order) || null,
      render: (text, row) => {
        if (editingKey === row.key) {
          return editingKey === 0 ? (
            <ConfigProvider locale={esES}>
              <Form.Item
                className="mb-0"
                name="newDate"
                rules={
                  editingKey === 0 && [
                    {
                      required: true,
                      message: "La fecha está vacía!",
                    },
                  ]
                }
              >
                <DatePicker
                  placeholder="Seleccionar"
                  format="DD/MM/YYYY"
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                  allowClear={false}
                />
              </Form.Item>
            </ConfigProvider>
          ) : (
            <ConfigProvider locale={esES}>
              <Form.Item
                className="mb-0"
                name="date"
                rules={
                  editingKey === 0 && [
                    {
                      required: true,
                      message: "La fecha está vacía!",
                    },
                  ]
                }
                initialValue={dayjs(row.date, "YYYY-MM-DD")}
              >
                <DatePicker
                  placeholder="Seleccionar"
                  format="DD/MM/YYYY"
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                  allowClear={false}
                />
              </Form.Item>
            </ConfigProvider>
          );
        } else {
          return dayjs(row.date, "YYYY-MM-DD").format("DD/MM/YYYY");
        }
      },
    },
    {
      title: "Descripción",
      dataIndex: "description",
      showSorterTooltip: false,
      defaultSortOrder: (sortState.column === "description" && sortState.order) || null,
      key: "description",
      width: "40%",
      ellipsis: (text, row) =>
        editingKey !== row.key && {
          showTitle: false,
        },
      sorter: (a, b) => a.description.localeCompare(b.description),
      onCell: () => {
        return {
           style: {
              whiteSpace: 'nowrap',
              maxWidth: 380,
           }
        }
      },
      render: (text, row) => {
        if (editingKey === row.key) {
          return editingKey === 0 ? (
            <Form.Item
              className="mb-0"
              name="newDescription"
              rules={[
                {
                  required: true,
                  message: "La descripción está vacía!",
                },
              ]}
            >
              <Input
                onPressEnter={() => {
                  if (parseInt(account) === 0) {
                    form
                      .validateFields()
                      .then(() => toggleNewTransaccionAccountModal())
                      .catch((err) => console.log(err));
                  } else {
                    saveNewtransaction(account);
                  }
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              className="mb-0"
              name="description"
              rules={[
                {
                  required: true,
                  message: "La descripción está vacía!",
                },
              ]}
              initialValue={row.description}
            >
              <Input
                onPressEnter={() => {
                  form
                    .validateFields()
                    .then(() => editTransaction(row))
                    .catch((err) => console.log(err));
                }}
              />
            </Form.Item>
          );
        } else {
          return (
            <Tooltip placement="topLeft" title={text}>
              <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</div>
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Categoría",
      dataIndex: "subcategory",
      sorter: (a, b) => {
        if (a.subcategory?.id !== null && b.subcategory?.id === null) {
            return -1; // Move non-null values before null values
        } else if (b.subcategory?.id !== null && a.subcategory?.id === null) {
            return 1; // Move null values after non-null values
        } else if (a.subcategory?.id === null && b.subcategory?.id === null) {
            return -1;
        } else {
            return a.subcategory?.name.localeCompare(b.subcategory?.name);
        }
    },
      showSorterTooltip: false,
      defaultSortOrder: (sortState.column === "subcategory" && sortState.order) || null,
      width: "15%",
      render: (text, row) => {
        if (editingKey === row.key) {
          return editingKey === 0 ? (
            <Form.Item className="mb-0" name="newSubcategory">
              <Select>
                {Object.values(categories || {})?.map(({ name, id, textColor }) => (
                  <Select.Option key={id} value={id}>
                    <i
                      className="bi bi-circle-fill"
                      style={{
                        color: textColor,
                        fontSize: "12px",
                      }}
                    ></i>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              className="mb-0"
              name="subcategory"
              initialValue={row.subcategory.id}
            >
              <Select>
                {Object.values(categories || {})?.map(({ name, id, textColor }) => (
                  <Select.Option key={id} value={id}>
                    <i
                      className="bi bi-circle-fill"
                      style={{
                        color: textColor,
                        fontSize: "12px",
                      }}
                    ></i>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          );
        } else {
          return <CellCategory category={row.subcategory} />;
        }
      },
    },
    {
      title: "Débito",
      dataIndex: "outflow",
      sorter: (a, b) => {
        if (a.transactionTypeEnum === "Outflow" && b.transactionTypeEnum === "Inflow") {
            return -1; // Move Outflow values before Inflow values
        } else if (b.transactionTypeEnum === "Outflow" && a.transactionTypeEnum === "Inflow") {
            return 1; // Move Inflow values after Outflow values
        } else {
            return a.ammount - b.ammount;
        }
    },
      showSorterTooltip: false,
      defaultSortOrder: (sortState.column === "outflow" && sortState.order) || null,
      align: "right",
      render: (text, row) => {
        if (editingKey === row.key) {
          if (editingKey === 0) {
            return (
              <Form.Item
                className="mb-0"
                name="newOut"
                dependencies={["newIn"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !getFieldValue("newIn")) {
                        return Promise.reject(
                          new Error("Debe ingresar Débito o Crédito")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  min={0}
                  key="addOutflow"
                  onChange={(e) => setNewOutflow(e)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                  controls={false}
                  disabled={newInflow !== null}
                  onPressEnter={async () => {
                    if (parseInt(account) === 0) {
                      form
                        .validateFields()
                        .then(() => toggleNewTransaccionAccountModal())
                        .catch((err) => console.log(err));
                    } else {
                      saveNewtransaction(account);
                    }
                  }}
                />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                className="mb-0"
                name="editOut"
                initialValue={
                  row.transactionTypeEnum === "Outflow" && row.ammount
                }
              >
                <InputNumber
                  min={0}
                  key="addOutflow"
                  onChange={(e) => setNewOutflow(e)}
                  
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                  controls={false}
                  disabled={newInflow !== null}
                  onPressEnter={() => {
                    form
                      .validateFields()
                      .then(() => editTransaction(row))
                      .catch((err) => console.log(err));
                  }}
                />
              </Form.Item>
            );
          }
        } else {
          if (row.transactionTypeEnum === "Outflow") {
            return (
              <span style={{ verticalAlign: "middle", color: "#DC3545" }}>
                {dollarUS.format(row.ammount)}
              </span>
            );
          }
        }
      },
    },
    {
      title: "Crédito",
      dataIndex: "inflow",
      sorter: (a, b) => {
        if (a.transactionTypeEnum === "Inflow" && b.transactionTypeEnum === "Outflow") {
            return -1; // Move Outflow values before Inflow values
        } else if (b.transactionTypeEnum === "Inflow" && a.transactionTypeEnum === "Outflow") {
            return 1; // Move Inflow values after Outflow values
        } else {
            return a.ammount - b.ammount;
        }
      },
      showSorterTooltip: false,
      defaultSortOrder: (sortState.column === "inflow" && sortState.order) || null,
      align: "right",
      render: (text, row) => {
        if (editingKey === row.key) {
          if (editingKey === 0) {
            return (
              <Form.Item
                className="mb-0"
                name="newIn"
                dependencies={["newOut"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !getFieldValue("newOut")) {
                        return Promise.reject(
                          new Error("Debe ingresar Débito o Crédito")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  min={0}
                  key="addinflow"
                  onChange={(e) => setNewInflow(e)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                  controls={false}
                  disabled={newOutflow !== null}
                  onPressEnter={() => {
                    if (parseInt(account) === 0) {
                      form
                        .validateFields()
                        .then(() => toggleNewTransaccionAccountModal())
                        .catch((err) => console.log(err));
                    } else {
                      saveNewtransaction(account);
                    }
                  }}
                />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                className="mb-0"
                name="editIn"
                initialValue={
                  row.transactionTypeEnum === "Inflow" && row.ammount
                }
              >
                <InputNumber
                  min={0}
                  key="addinflow"
                  onChange={(e) => setNewInflow(e)}
                  value={newInflow}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                  controls={false}
                  disabled={newOutflow !== null}
                  onPressEnter={() => {
                    form
                      .validateFields()
                      .then(() => editTransaction(row))
                      .catch((err) => console.log(err));
                  }}
                />
              </Form.Item>
            );
          }
        } else if (row.transactionTypeEnum === "Inflow") {
            return (
              <span style={{ verticalAlign: "middle", color: "#2C7BE5" }}>
                {dollarUS.format(row.ammount)}
              </span>
            );
          }
      },
    },
    {
      dataIndex: "operation",
      className: 'flex-1',
      render: (text, row) =>
        editingKey === row.key ? (
          <div className="flex flex-row gap-2">
            <div className="flex flex-col">
              <Button
                className="bg-primary text-white text-xs w-full"
                onClick={
                  () => {
                    if (editingKey === 0) {
                      if (parseInt(account) === 0) {
                        toggleNewTransaccionAccountModal();
                      } else {
                        saveNewtransaction(account);
                      }
                    } else {
                      editTransaction(row);
                    }
                  }
              }
              >
                <i className="bi bi-save text-white"></i>
              </Button>
            </div>

            <div className="flex flex-col">
              <Button
                className="bg-white text-primary text-xs border border-primary"
                onClick={cancelNewTransaction}
              >
                <i className="bi bi-x-circle text-primary"></i>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row">
            <Button
              className="bg-primary text-white text-xs w-full"
              onClick={() => {
                form.resetFields();
                setTransactions(transactions.filter((tra) => tra.key !== 0));
                setEditingKey(row.id);

                if (row.transactionTypeEnum === "Inflow") {
                  setNewInflow(row.ammount);
                  setNewOutflow(null);
                } else {
                  setNewOutflow(row.ammount);
                  setNewInflow(null);
                }
              }}
            >
              <i className="bi bi-pencil-square text-white"></i>
            </Button>
          </div>
        ),
    },
  ];

  const searchTransactions = () => {
    setLoadingTransactions(true);
    if (search) {
      const params = {
        userId,
        accountId: account,
        word: search,
      };

      axiosInstance({
        url: "/transactions/search",
        data: params,
        method: "POST",
      })
        .then(({ data }) => {
          const summary = data.reduce(
            (acc, transaction) => {
              if (transaction.transactionTypeEnum === "Inflow") {
                acc.inflows += transaction.ammount;
              } else if (transaction.transactionTypeEnum === "Outflow") {
                acc.outflows += transaction.ammount;
              }
              return acc;
            },
            { inflows: 0, outflows: 0 }
          );

          setTransactions(
            Object.values(data || {})?.map((tra) => ({ key: tra.id, ...tra }))
          );

          setTotalInflow(summary.inflows);
          setTotalOutflow(summary.outflows);

          setHasMore(false);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoadingTransactions(false));
    } else {
      const numberOfRecords = currentPage * 20;

      axiosInstance({
        url: `/transactions/get-transactions/1/${numberOfRecords}`,
        method: "GET",
        params: {
          email: email,
          account: account,
        },
      })
        .then(({ data }) => {
          const { content, number, totalPages } = data;
          setLastUpdate(content.length > 0 ? content[0].createdAt : "-");
          setCurrentPage(1);

          const newTransactions = Object.values(content || {})?.map(
            (tra) => ({
              key: tra.id,
              ...tra,
            })
          ).sort(sortTransactions);
          
          setTransactions(newTransactions);
          setSelectedRows([]);

          if (number < totalPages) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingTransactions(false));
    }

    // Fetching transactions total ammount
    axiosInstance({
      url: `/transactions/${account}/${userId}`,
      method: "get",
    })
      .then(({ data: { inflows, outflows } }) => {
        setTotalInflow(inflows);
        setTotalOutflow(outflows);
      })
      .catch((err) => console.log(err));
  };

  if (loadingOptions) return <CustomLoader />;

  return (
    <>
      <div
        style={
          {
           overflow: 'auto',
          }
        }
        className="flex flex-col gap-4 md:gap-0 md:flex-row items-center justify-between p-2 w-full h-auto">
        <div className="flex w-full text-primary my-4 mx-2  h-5 gap-4">
        <button
            type="button"
            onClick={handleAddTransaction}
            className="cursor-pointer justify-start align-middle gap-1"
          >
            <i className="bi bi-plus-circle-dotted h-4 w-4"></i>
            <span>Añadir transacción</span>
          </button>
          <div className="cursor-pointer justify-start align-middle gap-4">
            <Dropdown 
              menu={menuProps} 
              className="text-primary">
              <Space>
                <div className="flex flex-row justify-start align-middle gap-3">
                  <div className="flex flex-row justify-between align-middle gap-1">
                    <i className="bi bi-pencil h-4 w-4"></i>
                    <span className="text-sm">{`Editar(${
                      selectedRows.length || 0
                    })`}</span>
                  </div>
                  <i className="bi bi-chevron-down"></i>
                </div>
              </Space>
            </Dropdown>
          </div>

          

          <button
            type="button"
            className="flex cursor-pointer flex-row justify-start align-middle gap-1"
            onClick={() => toggleNewCategoryFilter()}
          >
            <i className="bi bi-plus-circle-dotted h-4 w-4"></i>
            <span>Categorizar</span>
          </button>

          {itsCreditCard && (
            <button
              type="button"
              className="flex cursor-pointer items-center justify-start gap-1"
              onClick={() => toggleEditCreditCard()}
            >
              <i className="bi bi-pencil h-4 w-8"></i>
              <span className="w-auto">Editar tarjeta</span>
            </button>
          )}
        </div>
        <div className="flex flex-row lg:justify-end align-middle w-full">
          <Space.Compact>
            <Input
              placeholder="Buscar"
              className="rounded-l-xl"
              onChange={(e) => {
                setSearch(e.target.value);
                // Clear the previous timeout
                clearTimeout(searchTimeout);
                // Set a new timeout
                setSearchTimeout(
                  setTimeout(() => {
                    // Only call searchTransactions if the length of the search query is less than or equal to 10
                    if (e.target.value.length <= 10) {
                      searchTransactions();
                    }
                  }, 1000)
                );
              }}
            />
            <Button
              className="rounded-r-xl bg-primary"
              onClick={searchTransactions}
            >
              <i className="bi bi-search relative text-white text-xs"></i>
            </Button>
          </Space.Compact>
        </div>
      </div>
      <hr className="border-gray-300 mt-8 md:mt-2" />
      <div className="flex flex-row p-4 w-full m-0 bg-white justify-center">
        {loadingTransactions ? (
          <CustomLoader />
        ) : (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleInfiniteScroll}
            className="w-full"
            loader={<CustomLoader key={0} />}
          >
            <Form 
              initialValues={
                { 
                  newTag: { label: "N/A", value: "" }, 
                  newDescription: "",
                  newDate: dayjs(),
                }
              }
              form={form} component={false}>
              <Table
                onChange={handleTableChange}
                dataSource={transactions}
                columns={columns}
                className="w-full"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                loading={loadingTransactions}
                scroll={{
                  x: "max-content",
                }}
                pagination={false}
                locale={{
                  emptyText: () => (
                    <p className="text-center text-xs font-medium text-gray-700 my-4">
                      No existen transacciones
                    </p>
                  ),
                }}
              />
            </Form>
          </InfiniteScroll>
        )}
      </div>
      <ToastContainer />
      <ConfirmDeleteTransactionModal
        isOpen={confirmDeleteModal}
        toggle={toggleConfirmDeleteModal}
        handleDeleteTransactions={handleDeleteTransactions}
      />

      <NewTransactionAccounModal
        isOpen={isOpenNewTransactionAccountModal}
        toggle={toggleNewTransaccionAccountModal}
        accountOptions={accounts}
        saveNewTransaction={saveNewtransaction}
      />
      <AddCategoryFilter
        isModalOpen={newCategoryFilter}
        toggleNewCategoryFilter={toggleNewCategoryFilter}
        userId={userId}
        setRefetchTransactions={setRefetchTransactions}
      />

      <EditCreditCardModal
        isModalOpen={editCreditCard}
        toggleEditCreditCard={toggleEditCreditCard}
        account={accountData}
        fetchAccountData={fetchAccountData}
      />
    </>
  );
};


Detailed.propTypes = {
  account: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  setLastUpdate: PropTypes.func.isRequired,
  totalStates: PropTypes.object.isRequired,
  setTotalSelectedRows: PropTypes.func.isRequired,
  setSelectedAccount: PropTypes.func.isRequired,
};

export default Detailed;
