import { Button, Form, Input, Modal, Popconfirm } from "antd";
import React, { useState } from "react";

const AddEditGroup = ({ isOpen, toggle, handleCreateGroup }) => {
  /**
   * Instance of form
   */
  const [form] = Form.useForm();

  /**
   * Account modal content handler
   */
  const [step, setStep] = useState(0);

  const handleCloseModal = () => {
    form.resetFields();
    toggle();
  };

  return (
    <Modal
      title="Crear grupo"
      open={isOpen}
      onCancel={handleCloseModal}
      footer={
        step === 0
        ? [
            <Button
              key="cancel"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>,
            <Button
              key="create"
              className="bg-primary text-white"
              onClick={async () => {
                try {
                  const { name } = await form.validateFields();
                  await handleCreateGroup(name);
                  setStep(1);
                } catch (e) {
                } finally {
                  // form.resetFields();
                }
              }}
            >
              Crear
            </Button>,
          ]
        : [
            <Button
              key="createAnother"
              onClick={() => {
                setStep(0);
              }}
            >
              Crear otra
            </Button>,
            <Button
              key="continue"
              className="bg-primary text-white"
              onClick={handleCloseModal}
            >
              Continuar
            </Button>,
          ]
      }
    >
      {step === 0 && (
        <Form form={form} component={false}>
          <div className="flex flex-col gap-2 my-2">
            <label htmlFor="name">Nombre</label>
            <Form.Item
              className="mb-2"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio!",
                },
              ]}
            >
              <Input
                className="w-full"
                placeholder="Ingrese el nombre del grupo"
              />
            </Form.Item>
          </div>
        </Form>
      )}
      {step === 1 && (
        <div className="container">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row w-full justify-center">
              <i
                className="bi bi-patch-check-fill"
                style={{
                  color: "#198754",
                  fontSize: "68px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></i>
            </div>
            <div className="flex flex-row w-full justify-center">
              <h4>El grupo se ha creado con éxito</h4>
            </div>
            <div className="flex flex-row w-full justify-center text-center">
              Ahora puedes crear categorías y organizarlas por grupo.
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddEditGroup;
