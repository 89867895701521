import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastContainer } from "react-toastify";

import Papa from "papaparse";
import { X } from "react-feather";
import { Table } from "antd";

const DataPreview = ({ setAssignedData, fileHeaders, file }) => {
  const [dataPreview, setDataPreview] = useState([]);
  const [dataHeaders, setDataHeaders] = useState(
    fileHeaders.filter((fh) => fh)
  );

  const twoColumns = !fileHeaders
    .map((item) => item.toLowerCase())
    .includes("monto");

  let zonesOrder = [];

  if (twoColumns) {
    zonesOrder = [
      "file-headers",
      "date",
      "payee",
      "description",
      "category",
      "debit",
      "credit",
    ];
  } else {
    zonesOrder = [
      "file-headers",
      "date",
      "payee",
      "description",
      "category",
      "ammount",
    ];
  }

  const variableDndZones = twoColumns
    ? {
        debit: {
          title: (
            <span className="text-sm">
              Débito <span className="text-sm text-red-500">*</span>
            </span>
          ),
          column: [],
        },
        credit: {
          title: (
            <span className="text-sm">
              Crédito <span className="text-sm text-red-500">*</span>
            </span>
          ),
          column: [],
        },
      }
    : {
        ammount: {
          title: (
            <span className="text-sm">
              Monto <span className="text-sm text-red-500">*</span>
            </span>
          ),
          column: [],
        },
      };

  const [dndZones, setDndZones] = useState({
    "file-headers": {
      title: "Columnas del documento",
      column: fileHeaders.filter((fh) => fh),
    },
    date: {
      title: (
        <span className="text-sm">
          Fecha <span className="text-sm text-red-500">*</span>
        </span>
      ),
      column: [],
    },
    payee: {
      title: <span className="text-sm">Proveedor</span>,
      column: [],
    },
    description: {
      title: (
        <span className="text-sm">
          Descripción <span className="text-sm text-red-500">*</span>
        </span>
      ),
      column: [],
    },
    category: {
      title: <span className="text-sm">Categoría</span>,
      column: [],
    },
    ...variableDndZones,
  });

  useEffect(() => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => {
      const { data } = Papa.parse(reader.result);
      const headers = data[0];

      setDataHeaders(headers);
      setDataPreview(data.slice(1, 6));
    };
  }, [file]);

  const onDragEnd = (res) => {
    const { destination, source, draggableId } = res;

    if(!source || !destination) return;

    const sourceZone = dndZones[source.droppableId];
    const destinationZone = dndZones[destination.droppableId];

    if (sourceZone === destinationZone) {
      const newColumnIds = Array.from(sourceZone.column);

      newColumnIds.splice(source.index, 1);
      newColumnIds.splice(destination.index, 0, draggableId);

      const newZone = { ...sourceZone, column: newColumnIds };

      const newDndZones = { ...dndZones, [source.droppableId]: newZone };

      setDndZones(newDndZones);
      return;
    }

    if (
      destination.droppableId !== "file-headers" &&
      destinationZone.column.length > 0
    )
      return;

    const sourceColumnIds = Array.from(sourceZone.column);
    sourceColumnIds.splice(source.index, 1);

    const newSource = { ...sourceZone, column: sourceColumnIds };

    const destinationColumnIds = Array.from(destinationZone.column);
    destinationColumnIds.splice(destination.index, 0, draggableId);

    const newDestination = { ...destinationZone, column: destinationColumnIds };

    const newDndZones = {
      ...dndZones,
      [source.droppableId]: newSource,
      [destination.droppableId]: newDestination,
    };
    const auxAssignedData = twoColumns
      ? [
          { debit: newDndZones["debit"].column[0] },
          { credit: newDndZones["credit"].column[0] },
        ]
      : [{ ammount: newDndZones["ammount"].column[0] }];

    setAssignedData([
      { date: newDndZones["date"].column[0] },
      { payee: newDndZones["payee"].column[0] },
      { description: newDndZones["description"].column[0] },
      { category: newDndZones["category"].column[0] },
      ...auxAssignedData,
    ]);

    setDndZones(newDndZones);
  };

  // find indices of data columns
  const dataIndices = dataHeaders.reduce((indices, header, index) => {
    if (header.trim() !== "") {
      indices.push(index);
    }
    return indices;
  }, []);

  // map data to table rows
  const tableData = dataPreview.map((row) => {
    var rowData = {};
    dataIndices.forEach((index) => {
      rowData[dataHeaders[index]] = row[index];
    });
    return rowData;
  });

  // create antd table component
  const columns = dataIndices.map((index) => {
    return {
      title: dataHeaders[index],
      dataIndex: dataHeaders[index],
      key: dataHeaders[index],
    };
  });

  return (
    <div className="flex flex-row justify-between items-start h-full w-full gap-2 p-6 bg-white border-2 border-gray-200 rounded-md">
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row justify-center items-center h-full border-2 border-gray-200 rounded-md bg-white p-6 w-full">
          <div className="flex flex-col w-full h-full gap-2">
            <span
              style={{
                fontWeight: "510",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#212529",
              }}
            >
              Previsualización de data a importar:
            </span>
            <div className="w-full">
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-full border-2 border-gray-200 rounded-md bg-white p-6 w-full gap-3">
          <div className="flex flex-col w-full h-full gap-2">
            <span
              style={{
                fontWeight: "510",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#212529",
              }}
            >
              Arrastre y suelte elementos de la forma en que desea que su
              información sea almacenada
            </span>
          </div>
          <div className="flex flex-col w-full h-full gap-2">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="flex flex-col w-full bg-white rounded-md p-2 gap-2">
                <span className="text-sm">Columnas del documento</span>
                <Droppable droppableId="file-headers" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`flex flex-row gap-2 px-2 py-4 rounded-md ${
                        snapshot.isDraggingOver
                          ? "bg-blue-200 border-blue-300"
                          : "bg-blue-50 border-blue-300"
                      }`}
                    >
                      {dndZones["file-headers"].column.map((fh, index) => (
                        <Draggable draggableId={fh} index={index} key={fh}>
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              key={fh}
                            >
                              <span
                                className={`text-xs border-solid border-2 rounded-md p-1 ${
                                  snapshot.isDragging
                                    ? "bg-green-100 border-green-300"
                                    : "bg-blue-50 border-blue-300"
                                }`}
                              >
                                {fh}
                              </span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="flex flex-row w-full p-2 gap-2">
                {zonesOrder.slice(1).map((zoneId, index) => {
                  const { title, column } = dndZones[zoneId];

                  return (
                    <div
                      key={zoneId}
                      className={`flex rounded-md p-2 gap-2 flex-col w-1/6 bg-blue-50`}
                    >
                      {title}
                      <Droppable droppableId={zoneId} direction="horizontal">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={`flex flex-row gap-2 px-2 py-3 rounded-md ${
                              snapshot.isDraggingOver
                                ? "bg-blue-200 border-blue-300"
                                : "bg-blue-50 border-blue-300"
                            }`}
                           
                          >
                            {column.map((value, index) => (
                              <Draggable
                                draggableId={value}
                                index={index}
                                key={value}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    key={value}
                                  >
                                    <div
                                      className={`flex flex-row gap-2 text-xs border-solid border-2 rounded-md p-1 ${
                                        snapshot.isDragging
                                          ? "bg-green-100 border-green-300"
                                          : "bg-blue-50 border-blue-300"
                                      }`}
                                    >
                                      <span>{value}</span>
                                      <X
                                        size={12}
                                        className="text-danger cursor-default"
                                        onClick={() => console.log(value)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  );
                })}
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DataPreview;
