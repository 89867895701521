import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import esES from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";

import "./MonthPicker.scss";
import axiosInstance from "../../../utils/axiosIntance";
import CustomLoader from "../loader/CustomLoader";

dayjs.locale("es");

const dateFormatter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const AddNewGroup = ({
  isModalOpen,
  toggleNewBudget,
  subcategoryOptions,
  userId,
  fetchBudgets,
}) => {
  /**
   * Form instance
   */
  const [form] = Form.useForm();

  /**
   * Account modal content handler
   */
  const [step, setStep] = useState(0);

  /**
   * State to show loader
   */
  const [isLoading, setIsLoading] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  const handleClosemodal = () => {
    form.resetFields();
    setStep(0);
    toggleNewBudget();
    fetchBudgets();
  };

  const onOk = async () => {
    try {
      const { assigned, category } = await form.validateFields();

      const parts = dateFormatter.format(selectedMonth.$d).split("/");

      const date = `${parts[2]}-${parts[0]}-01`;

      const params = {
        userId: userId,
        subcategory: {
          id: category,
        },
        amount: assigned,
        date: date,
      };

      setIsLoading(true);

      axiosInstance({
        url: "/budget",
        method: "POST",
        data: params,
      })
        .then(() => setStep(1))
        .catch(() => {
          toast.error("Error al crear el presupuesto", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        })
        .finally(() => setIsLoading(false));
    } catch (errorInfo) {
      return;
    }
  };

  return (
    <>
      <Modal
        title="Nuevo presupuesto"
        open={isModalOpen}
        onCancel={handleClosemodal}
        footer={
          step === 0
            ? [
                <Popconfirm
                  title="Desea cancelar?"
                  onConfirm={handleClosemodal}
                  cancelText="No"
                  okText="Sí"
                  okButtonProps={{ className: "bg-primary text-white" }}
                >
                  <Button>Cancelar</Button>
                </Popconfirm>,
                <Popconfirm
                  title="Desea crear el presupuesto?"
                  onConfirm={onOk}
                  cancelText="No"
                  okText="Sí"
                  okButtonProps={{ className: "bg-primary text-white" }}
                >
                  <Button className="bg-primary text-white">Crear</Button>
                </Popconfirm>,
              ]
            : [
                <Button
                  onClick={() => {
                    setStep(0);
                    form.resetFields();
                    fetchBudgets();
                  }}
                >
                  Crear otro
                </Button>,
                <Button
                  className="bg-primary text-white"
                  onClick={handleClosemodal}
                >
                  Continuar
                </Button>,
              ]
        }
      >
        {isLoading ? (
          <CustomLoader />
        ) : (
          <>
            {step === 0 && (
              <Form form={form} component={false}>
                <div className="flex flex-col gap-2">
                  <label>Categoría</label>
                  <Form.Item
                    className="mb-2"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es obligatorio!",
                      },
                    ]}
                  >
                    <Select placeholder="Seleccione una categoría">
                      {subcategoryOptions.map((item) => {
                        const { key, label } = item;
                        return (
                          <Select.Option key={key} value={key}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex flex-col gap-2">
                  <label>Monto asignado</label>
                  <Form.Item
                    className="mb-2"
                    name="assigned"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es obligatorio!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Ingresa el monto asignado al presupuesto"
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      addonAfter={"$"}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      className="w-full"
                      precision={2}
                      controls={false}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col gap-2 new-budget-monthpicker">
                  <label>Mes del presupuesto</label>
                  <Form.Item className="mb-2" name="date">
                    <ConfigProvider locale={esES}>
                      <DatePicker
                        format="MMMM YYYY"
                        picker="month"
                        allowClear={false}
                        value={selectedMonth}
                        suffixIcon={<></>}
                        onChange={({ $M, $y }) =>
                          setSelectedMonth(dayjs(`${$y}-${$M + 1}-01`))
                        }
                        placeholder="Seleccione un mes"
                        disabledDate={(current) =>
                          current &&
                          dayjs(current).isBefore(
                            dayjs().startOf("month"),
                            "month"
                          )
                        }
                      />
                    </ConfigProvider>
                  </Form.Item>
                </div>
              </Form>
            )}
            {step === 1 && (
              <div className="container">
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-row w-full justify-center">
                    <i
                      className="bi bi-patch-check-fill"
                      style={{
                        color: "#198754",
                        fontSize: "68px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></i>
                  </div>
                  <div className="flex flex-row w-full justify-center">
                    <h4>El presupuesto se ha creado con éxito</h4>
                  </div>
                  <div className="flex flex-row w-full justify-center text-center">
                    Ahora puedes crear una meta mensual con tu nuevo
                    presupuesto.
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddNewGroup;
