export const getDatesRange = (start, end) => {
  let current = new Date(start);
  let months = [];

  while (current <= end) {
    months.push(new Date(current));
    current.setMonth(current.getMonth() + 1);
  }

  return months.map((month) => {
    return new Date(month).toLocaleDateString("es-ES", {
      month: "short",
      year: "2-digit",
    });
  });
};
