import Categories from "../pages/dashboard/Categories";
import Accounts from "../pages/dashboard/Accounts";
import Budget from "../pages/dashboard/Budget";

import General from "../pages/settings/General";
import ImportFile from "../pages/settings/ImportFile";
import Reports from "../pages/dashboard/Reports";
import LoanTool from "../components/dashboard/budget/LoanTools";

export const LANDING = `${process.env.PUBLIC_URL}/`;
export const LOGIN = `${process.env.PUBLIC_URL}/auth/login`;
export const SIGN_UP = `${process.env.PUBLIC_URL}/auth/registro`;
export const CONFIRM_SIGN_UP = `${process.env.PUBLIC_URL}/auth/registrado`;
export const CONFIRMATION_TOKEN = `${process.env.PUBLIC_URL}/auth/confirmacion/:token`;
export const PRIVACY_POLICIES = `${process.env.PUBLIC_URL}/politicasPrivacidad`;
export const TERMS_CONDITIONS = `${process.env.PUBLIC_URL}/terminosCondiciones`;

/* DASHBOARD */
/* MAIN */
export const ACCOUNTS = `${process.env.PUBLIC_URL}/cuentas`;
export const BUDGET = `${process.env.PUBLIC_URL}/presupuesto`;
export const REPORTS = `${process.env.PUBLIC_URL}/reportes`;
export const LOANTOOL = `${process.env.PUBLIC_URL}/herramienta/:type/:id`;

/* CUENTAS */
export const ACCOUNTS_BY_ID = `${process.env.PUBLIC_URL}/cuentas/:id`;

/* APP */
export const CALENDAR = `${process.env.PUBLIC_URL}/calendario`;

/* ADMINSITRACION */
export const USERS = `${process.env.PUBLIC_URL}/usuarios`;
export const CATEGORIES = `${process.env.PUBLIC_URL}/categorias`;

/* SETTINGS */
export const GENERAL_SETTINGS = `${process.env.PUBLIC_URL}/configuracion/general`;
export const SECURITY_SETTINGS = `${process.env.PUBLIC_URL}/configuracion/seguridad`;
export const NOTIFICATIONS_SETTINGS = `${process.env.PUBLIC_URL}/configuracion/notificaciones`;
export const IMPORT_FILE = `${process.env.PUBLIC_URL}/configuracion/import-file`;
export const EXPORT_FILE = `${process.env.PUBLIC_URL}/configuracion/export-file`;
export const INTEGRATIONS_SETTINGS = `${process.env.PUBLIC_URL}/configuracion/integraciones`;

export const DASHBOARD_ROUTES = [
  { path: ACCOUNTS_BY_ID, component: Accounts },
  { path: BUDGET, component: Budget },
  { path: CATEGORIES, component: Categories },
  { path: REPORTS, component: Reports },
  { path: LOANTOOL, component: LoanTool },
];

export const ACCOUNT_ROUTES = [
  { path: GENERAL_SETTINGS, component: General },
  { path: SECURITY_SETTINGS, component: General },
  { path: NOTIFICATIONS_SETTINGS, component: General },
  { path: IMPORT_FILE, component: ImportFile },
  { path: EXPORT_FILE, component: General },
  { path: INTEGRATIONS_SETTINGS, component: General },
];
