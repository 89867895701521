import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../hooks/userConstext";

import { Checkbox, Form, Input } from "antd";
import axios from "axios";
import CustomModalLoader from "../customModalLoader/CustomModalLoader";

import { SIGN_UP } from "../../routes";

const LoginForm = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { login } = useAuth();

  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { email, password } = await form.validateFields();

      setLoadingLogin(true);

      axios({
        url: `${process.env.REACT_APP_SERVER}/auth/authenticate`,
        method: "post",
        data: {
          email,
          password,
        },
      })
        .then(({ data: { user, token } }) => {
          login(user, token);
        })
        .catch((err) => {
          toast.error("Error en credenciales", {
            position: "bottom-right",
            autoClose: 3000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        })
        .finally(() => {
          form.setFieldValue("email", "");
          form.setFieldValue("password", "");
          setLoadingLogin(false);
        });
    } catch (error) {}
  };

  return (
    <div className="flex flex-col h-full gap-7">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h1 className="text-primary font-roboto">Iniciar sesión</h1>
          <h6 className="text-gray-600 font-roboto mt-[7px] mb-0">
            Bienvenido a OnMission
          </h6>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col w-full">
          <Form form={form}>
            <div className="flex flex-col gap-2">
              <Form.Item
                name="email"
                className="mb-2"
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="Correo electrónico"
                  size="small"
                  suffix={
                    <i className=" bi bi-person text-gray-600 text-base"></i>
                  }
                />
              </Form.Item>
              <Form.Item
                className="mb-2"
                name="password"
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <Input.Password
                  className="w-full"
                  placeholder="Contraseña"
                  size="small"
                />
              </Form.Item>
              <div className="flex flex-row justify-between">
                <Form.Item name="keepLoggedIn" valuePropName="checked">
                  <Checkbox>Recordarme</Checkbox>
                </Form.Item>
                <Link to="#" className="body-s">
                  {"¿Olvidaste tu contraseña?"}
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col gap-2 justify-center w-full">
          <Button
            color="primary"
            className="flex flex-row"
            onClick={handleSubmit}
          >
            <i className="bi bi-lock" />
            Iniciar sesión
          </Button>
          <span className="text-center">o</span>
          <Button
            className="btn-google-login"
            style={{ width: "100%" }}
            disabled
          >
            Iniciar sesión con Google
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-center text-sm text-gray-600">
        ¿No tienes cuenta?{" "}
        <button
          className="border-0 text-primary"
          onClick={() => navigate(SIGN_UP)}
        >
          {"Registrate"}
        </button>
      </div>
      <ToastContainer />
      <CustomModalLoader isOpen={loadingLogin} />
    </div>
  );
};

export default LoginForm;
