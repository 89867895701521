import { Button, Modal } from "antd";
import React from "react";
import PropsTypes from "prop-types";

const DeleteCategoriesWarning = ({ toggle, isOpen, handleDelete }) => {
  return (
    <Modal
      title="¿Desea eliminar la/s categoría/s?"
      open={isOpen}
      onCancel={toggle}
      footer={[
        <Button key={'cancel'} onClick={toggle}>No</Button>,
        <Button key={'ok'} onClick={handleDelete} className="bg-danger text-white">
          Sí
        </Button>,
      ]}
    >
      <div className="flex flex-col w-full h-fit justify-start my-8">
        <span>Tenga en cuenta que al eliminar la/s categoría/s:</span>
        <ul className="p-3 space-y-3 list-disc list-inside">
          <li className="space-x-2">
            <span>{`Las transacciones asociadas quedarán "Sin categorizar".`}</span>
          </li>
          <li className="space-x-2">
            <span>{`Los presupuestos y filtros de autocategorización asociados serán eliminados.`}</span>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

DeleteCategoriesWarning.propTypes = {
  toggle: PropsTypes.func.isRequired,
  isOpen: PropsTypes.bool.isRequired,
  handleDelete: PropsTypes.func.isRequired,
};

export default DeleteCategoriesWarning;
