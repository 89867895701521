import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosIntance";
import { getSessionStorageItem } from "../../../utils/service/localStorageService";
import { Form, InputNumber, Progress, Table } from "antd";
import { dollarUS } from "../../../utils/dollarFormat";
import { ToastContainer, toast } from "react-toastify";
import CustomLoader from "../loader/CustomLoader";
import AddNewGroup from "./AddNewGroup";
import CustomModalLoader from "../../customModalLoader/CustomModalLoader";
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';

const BudgetBody = ({ setAvailableAmount, selectedMonth, blockEdit }) => {
  /**
   * Getting user values
   */
  const { id: userId } = JSON.parse(getSessionStorageItem("user"));

  /**
   * State to handle budget data from backend
   */
  const [budgetData, setBudgetData] = useState([]);
  const [isLoadingBudgets, setIsLoadingBudgets] = useState(false);

  /**
   * Indicates what row is selected to edit
   */
  const [editingRow, setEditingRow] = useState(0);

  /**
   * Form instance
   */
  const [form] = Form.useForm();

  /**
   * Categories options for new budgets
   */
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [loadingCategoriesOptions, setLoadingCategoriesOptions] =
    useState(false);

  /**
   * New budget modal states
   */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleNewBudgetModal = () => setIsModalOpen(!isModalOpen);

  /**
   * Store all the categories in the selected month
   */
  const [existingCategoriesInBudgets, setExistingCategoriesInBudgets] = useState([]);

  const [loadingEditBudget, setLoadingEditBudget] = useState(false);

  const fetchBudgets = useCallback(async () => {
    setIsLoadingBudgets(true);
  
    try {
      const { data } = await axiosInstance.get(`/budget/${userId}`, {
        params: { date: selectedMonth },
      });

      const finalData = data.map((category, index) => {
        const categoryData = {
          ...category,
          id: category.id || index,
          key: index + 'cat',
          type: "category",
          children: category.subcategories.map((subc, index) => ({
            ...subc, 
            type: "subcategory",
            key: index + 'subc',
          })),
        };
  
        if (category.name === "Sin categorizar") {
          categoryData.children = undefined;
        }
        
  
        return categoryData;
      });
  
      try {
        const { data: availableAmount } = await axiosInstance.get(
          `/budget/totalAvailable/${userId}`,
          {
            params: { date: selectedMonth },
          }
        );
  
        setAvailableAmount(availableAmount);
      } catch (err) {
        console.log(err);
      }
      setBudgetData(finalData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingBudgets(false);
    }
  }, [userId, selectedMonth, setAvailableAmount]);
  
  const [loanData, setLoanData] = useState([]);

  const fetchLoanData = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/loan/list-loans/${userId}`, {
        params: { date: selectedMonth },
      });
  
      //add unique key to each loan
      data.forEach((loan, index) => { 
        loan.key = index;
      });

      setLoanData(data);
    } catch (err) {
      console.log(err);
    }
  }, [userId, selectedMonth]);
  
  const fetchSubcategories = useCallback(async () => {
    setLoadingCategoriesOptions(true);
  
    try {
      const { data } = await axiosInstance.get(`/subcategory/${userId}`);
  
      const categories = Object.values(data || {}).map((category) => ({
        label: category.name,
        key: category.id,
        icon: (
          <i
            className="bi bi-circle-fill"
            style={{
              color: category.textColor,
              fontSize: "12px",
            }}
          ></i>
        ),
        className: "text-primary text-sm",
        subcategory: category,
      }));

  
      setCategoriesOptions(categories);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingCategoriesOptions(false);
    }
  }, [userId]);
  
  useEffect(() => {
    fetchBudgets();
    fetchLoanData();
    fetchSubcategories();
  }, [fetchBudgets, fetchLoanData, fetchSubcategories]);

  const editBudget = async (row) => {
    try {
      setLoadingEditBudget(true);
  
      const { newAssigned } = await form.validateFields();
  
      const params = {
        id: row.budgetId,
        userId: userId,
        amount: newAssigned,
        date: selectedMonth,
        subcategory: { id: row.id },
      };
  
      const method = row.budgetId ? "PUT" : "POST";
      const url = row.budgetId ? `/budget/${row.budgetId}` : `/budget`;
  
      await axiosInstance({ url, method, data: params });
  
      toast.success("Transaction modified successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error("No se pudo modificar la transacción", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoadingEditBudget(false);
      fetchBudgets();
  
      setEditingRow(0);
      form.resetFields();
    }
  };

  const renderGroup = (_, row) => (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row">{row.name}</div>
      <div className="flex flex-row">
      <Progress
          percent={getPercentage(row.activity, row.amount)}
          className="w-full h-2"
          status="active"
          strokeColor={row.availableMoney < 0 ? "#e53e3e" : "#38a169"}
          format={() => `${getPercentage(row.activity, row.amount)}%`}
        />
      </div>
    </div>
  );

  const getPercentage = (activity, amount) => {
    if (typeof activity === 'number' && amount !== 0 && typeof amount === 'number') {
      return ((activity / amount) * 100).toFixed(0);
    } else {
      return '0';
    }
  };
  
  const renderAssigned = (_, row) =>
    editingRow === row.id ? (
      <Form.Item className="mb-0" name="newAssigned" initialValue={row.amount}>
        <InputNumber
          onKeyDown={({ key }) => {
            if (key === "Enter") editBudget(row);
            if (key === "Escape") setEditingRow(0);
          }}
          controls={false}
          min={0}
          value={row.amount}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          addonAfter={"$"}
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          precision={2}
        />
      </Form.Item>
    ) : (
        <button
          onClick={(e) => {e.preventDefault(); editAmount(row)}}
          className="text-sm"
        >
          {dollarUS.format(row.amount)}
        </button>
    );

  const editAmount = (row) => {
    if (!row?.subcategories) {
      setEditingRow(row.id);
    }
  };
  
  const renderActivity = (_, row) => (
    <div className="flex flex-row w-full justify-end">
      {dollarUS.format(row.activity)}
    </div>
  );
  
  const renderAvailable = (_, row) => (
    <div className="flex flex-row w-full justify-end">
      <span className={`text-sm ${row.availableMoney < 0 ? "text-red-600" : "text-green-600"}`}>
        {dollarUS.format(row.availableMoney)}
      </span>
    </div>
  );

  const columns = [
    {
      title: "Grupo",
      key: "name",
      render: renderGroup,
    },
    {
      title: "Asignado",
      key: "assigned",
      render: renderAssigned,
      align: "right",
      width: "15%",
    },
    {
      title: "Actividad",
      key: "activity",
      render: renderActivity,
      align: "right",
      width: "15%",
    },
    {
      title: "Disponible",
      key: "available",
      render: renderAvailable,
      align: "right",
      width: "15%",
    },
  ];

  const renderDebt = (_, row) => (
    <Link
      className="capitalize no-underline text-gray-900"
      to={`/herramienta/${row.type}/${row.id}`}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row hover:cursor-pointer">{row.name}</div>
        <div className="flex flex-row">
          <Progress
            percent={(100 / row.loan) * row.payed}
            className={`w-full h-2 `}
            status="active"
            strokeColor={`${row.loan < 0 ? "red" : "#1677ff"}`}
          />
        </div>
      </div>
    </Link>
  );
  
  const renderPayed = (_, row) =>
    editingRow === row.id ? (
      <Form.Item className="mb-0" name="newAssigned" initialValue={row.payedAmount}>
        <InputNumber
          onKeyDown={({ key }) => {
            if (key === "Enter") editBudget(row);
            if (key === "Escape") setEditingRow(0);
          }}
          controls={false}
          min={0}
          value={row.payed}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          addonAfter={"$"}
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          precision={2}
        />
      </Form.Item>
    ) : (
      <button 
        onClick={() => !row?.subcategories && setEditingRow(row.id)} 
        className="text-sm"
        disabled={!!row?.subcategories}
      >
        {dollarUS.format(row.payedAmount)}
      </button>
    );
  
  const renderActivityCredit = (_, row) => (
    <div className="flex flex-row w-full justify-end">
      {dollarUS.format(row.activity)}
    </div>
  );
  
  const renderBalance = (_, row) => (
    <div className="flex flex-row w-full justify-end">
      <span className={`text-sm ${row.loan < 0 ? "text-red-600" : "text-green-600"}`}>
        {dollarUS.format(row.loanAmount)}
      </span>
    </div>
  );
  
  const columnsCredits = [
    {
      title: "Deuda",
      key: "name",
      render: renderDebt,
    },
    {
      title: "Pagado",
      key: "payed",
      render: renderPayed,
      align: "right",
      width: "15%",
    },
    {
      title: "Actividad",
      key: "activity",
      render: renderActivityCredit,
      align: "right",
      width: "15%",
    },
    {
      title: "Saldo",
      key: "loan",
      render: renderBalance,
      align: "right",
      width: "15%",
    },
  ];

  if (isLoadingBudgets || loadingCategoriesOptions) return <CustomLoader />;

  const finalCategoryOptions = Object.values(categoriesOptions || {}).filter(
    (item) => {
      const subcategoryId = item.subcategory.id;
      return budgetData.length > 0
        ? !existingCategoriesInBudgets.includes(subcategoryId)
        : true;
    }
  );

  const EmptyTextComponent = ({ message }) => (
    <p className="text-center text-xs font-medium text-gray-700 my-4">
      {message}
    </p>
  );

  const TableComponent = ({ columns, data, form }) => (
    <div className="flex flex-row p-4 w-full m-0 bg-white">
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={false}
          className="w-full"
          locale={{ emptyText: () => <EmptyTextComponent message="No existen presupuestos para este periodo y este grupo" /> }}
        />
      </Form>
    </div>
  );
  
  return (
    <>
      {loadingCategoriesOptions || isLoadingBudgets ? (
        <CustomLoader />
      ) : (
        <>
          <TableComponent columns={columns} data={budgetData} form={form} />
          <TableComponent columns={columnsCredits} data={loanData} form={form} />
          <AddNewGroup
            isModalOpen={isModalOpen}
            toggleNewBudget={toggleNewBudgetModal}
            subcategoryOptions={finalCategoryOptions}
            userId={userId}
            fetchBudgets={fetchBudgets}
          />
          <CustomModalLoader isOpen={loadingEditBudget} />
          <ToastContainer />
        </>
      )}
    </>
  );

};

BudgetBody.propTypes = {
  setAvailableAmount: PropTypes.func.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  blockEdit: PropTypes.bool.isRequired,
};

export default BudgetBody;
