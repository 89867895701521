function Banner3() {
  return (
    <div className="first-cta-2">
      <div className="container-2">
        <div className="small-container-4">
          <div className="text-23">
            <img
              src={require("../images/Vectors-Wrapper_18.svg").default}
              loading="lazy"
              width="40"
              height="40"
              alt=""
              className="vectors-wrapper-21"
            />
            <div className="text-24">
              “Con OnMission, estás en control de tus finanzas personales y
              puedes lograr la libertad financiera que siempre has deseado”
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner3;
