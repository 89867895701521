import React, { useState } from "react";
import BudgetHeader from "../../components/dashboard/budget/Header";
import BudgetBody from "../../components/dashboard/budget/Body";
import dayjs from "dayjs";

const Budget = () => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs().startOf("month"));
  const [totalAvailable, setTotalAvailable] = useState(0);

  const [blockEdit, setBlockEdit] = useState(false);

  return (
    <div className="p-4 bg-container-bg rounded-2xl shadow-custom-shadow">
      <BudgetHeader
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        totalAvailable={totalAvailable}
        setBlockEdit={setBlockEdit}
      />
      <BudgetBody
        setAvailableAmount={setTotalAvailable}
        selectedMonth={selectedMonth.format("DD/MM/YYYY")}
        blockEdit={blockEdit}
      />
    </div>
  );
};

export default Budget;
