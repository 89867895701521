import { Button, Form, Input, Modal, Popconfirm, Select } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../utils/axiosIntance";
import { ToastContainer, toast } from "react-toastify";
import CustomModalLoader from "./customModalLoader/CustomModalLoader";

const AddCategoryFilter = ({
  isModalOpen,
  toggleNewCategoryFilter,
  userId,
  setRefetchTransactions,
}) => {
  /**
   * Form instance
   */
  const [form] = Form.useForm();

  /**
   * Account modal content handler
   */
  const [step, setStep] = useState(0);

  /**
   * State to show loader
   */
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);

  /**
   * State for category options
   */
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    setIsLoadingCategory(true);
    /**
     * Fetching categories
     */
    axiosInstance({
      method: "get",
      url: `/subcategory/${userId}`,
    })
      .then(({ data }) => {
        setCategoryOptions(
          Object.values(data || {})?.map(({ name, id, textColor }) => (
            <Select.Option key={id} value={id}>
              <i
                className="bi bi-circle-fill"
                style={{
                  color: textColor,
                  fontSize: "12px",
                  marginRight: "5px",
                }}
              ></i>
              {name}
            </Select.Option>
          ))
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingCategory(false));
  }, [userId]);

  const onOk = async () => {
    try {
      const { filterValue, category } = await form.validateFields();

      const params = {
        filter: filterValue,
        description: "",
        userId,
        subcategoryId: category,
      };

      axiosInstance({
        url: "/tags",
        method: "POST",
        data: params,
      })
        .then(() => setStep(1))
        .catch(() => {
          toast.error("Error al crear filtro de categoría", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        })
        .finally(() => {
          form.resetFields();

          setIsLoadingCreate(false);
          setRefetchTransactions(true);
        });
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <Modal
        title="Nueva filtro de categoría"
        open={isModalOpen}
        onCancel={toggleNewCategoryFilter}
        footer={
          step === 0
            ? [
                // <Popconfirm
                //   title="Desea cancelar?"
                //   onConfirm={toggleNewCategoryFilter}
                //   cancelText="No"
                //   okText="Sí"
                //   okButtonProps={{ className: "bg-primary text-white" }}
                // >
                //   <Button>Cancelar</Button>
                // </Popconfirm>,
                // <Popconfirm
                //   title="Desea crear el filtro?"
                //   onConfirm={onOk}
                //   cancelText="No"
                //   okText="Sí"
                //   okButtonProps={{ className: "bg-primary text-white" }}
                // >
                //   <Button className="bg-primary text-white">Crear</Button>
                // </Popconfirm>,
                <Button
                  key={1}
                  className="bg-primary text-white"
                  onClick={() => {
                    form.resetFields();
                    toggleNewCategoryFilter();
                  }}
                >
                  Cancelar
                </Button>,
                <Button
                  key={2}
                  className="bg-primary text-white"
                  onClick={onOk}
                >
                  Crear
                </Button>,
              ]
            : [
                <Button
                  key={1}
                  className="bg-primary text-white"
                  onClick={() => {
                    form.resetFields();
                    setStep(0);
                  }}
                >
                  Crear otro
                </Button>,
                <Button
                  key={2}
                  className="bg-primary text-white"
                  onClick={() => {
                    form.resetFields();

                    setStep(0);
                    toggleNewCategoryFilter();
                  }}
                >
                  Continuar
                </Button>,
              ]
        }
      >
        {step === 0 && (
          <div className="flex flex-col h-full gap-7">
            <h6 className="text-gray-600 mt-2 mb-0">
              Selecciona una categoría y agrega una palabra para que sea
              categorizada automaticamente al importar un archivo.
            </h6>
            <div className="flex flex-col w-full">
              <Form form={form} component={false}>
                <div className="flex flex-col gap-2">
                  <label>Valor del filtro</label>
                  <Form.Item
                    className="mb-2"
                    name="filterValue"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es obligatorio!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full"
                      placeholder="Ingrese el valor del filtro"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col gap-2">
                  <label>Categoría</label>
                  <Form.Item
                    className="mb-2"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es obligatorio!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione una categoría"
                      notFoundContent={"No existen categorías"}
                      loading={isLoadingCategory}
                    >
                      {categoryOptions}
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        )}
        {step === 1 && (
          <div className="container">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-row w-full justify-center">
                <i
                  className="bi bi-patch-check-fill"
                  style={{
                    color: "#198754",
                    fontSize: "68px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </div>
              <div className="flex flex-row w-full justify-center">
                <h4>Se ha creado el filtro exitosamente</h4>
              </div>
              <div className="flex flex-row w-full justify-center text-center">
                Ahora tus transacciones cuya descripción contenga el valor que
                asignaste serán autocategorizadas.
              </div>
            </div>
          </div>
        )}
      </Modal>
      <CustomModalLoader isOpen={isLoadingCreate} />
      <ToastContainer />
    </>
  );
};

export default AddCategoryFilter;
