import React, { useState } from "react";
import { Check, Eye, EyeOff } from "react-feather";
import { Button, Form, FormGroup, Input, InputGroup, Label } from "reactstrap";

import Avatar from "../../assets/imgs/avatars/rcmen1.png";
import Avatar2 from "../../assets/imgs/avatars/rcmen2.png";
import Avatar3 from "../../assets/imgs/avatars/rcmen3.png";
import Avatar4 from "../../assets/imgs/avatars/rcmen4.png";
import Avatar5 from "../../assets/imgs/avatars/rcmen5.png";
import Avatar6 from "../../assets/imgs/avatars/rcwomen1.png";
import Avatar7 from "../../assets/imgs/avatars/rcwomen2.png";
import Avatar8 from "../../assets/imgs/avatars/rcwomen3.png";
import Avatar9 from "../../assets/imgs/avatars/rcwomen4.png";
import Avatar10 from "../../assets/imgs/avatars/rcwomen5.png";

import DeleteAccount from "../../components/settings/general/DeleteAccountModal";

const profilePicsOptions = [
  { src: Avatar },
  { src: Avatar2 },
  { src: Avatar3 },
  { src: Avatar4 },
  { src: Avatar5 },
  { src: Avatar6 },
  { src: Avatar7 },
  { src: Avatar8 },
  { src: Avatar9 },
  { src: Avatar10 },
];

const General = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(Avatar);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const toggleDeleteAccountModal = () =>
    setDeleteAccountModal(!deleteAccountModal);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row gap-4 w-full">
          <div className="flex flex-col gap-16 w-full">
            <div className="flex flex-col gap-2 w-full">
              <span className="settings-title">Imagen de perfil</span>
              <div className="flex flex-row gap-4">
                <img
                  src={selectedAvatar}
                  style={{ width: "76px", height: "76px" }}
                  alt={"selectedAvatar"}
                />
                <div className="flex flex-col gap-1">
                  <span className="settings-subtitle">
                    Cambiar imagen de perfil
                  </span>
                  <div className="flex flex-row">
                    {profilePicsOptions.map((profilePic, index) => {
                      return (
                        <div
                          style={{
                            width: "48px",
                            height: "48px",
                            cursor: "pointer",
                          }}
                          className="px-0 m-1"
                          onClick={() => setSelectedAvatar(profilePic.src)}
                          key={index}
                        >
                          <img
                            src={profilePic.src}
                            style={{
                              width: "48px",
                              height: "48px",
                              opacity:
                                selectedAvatar !== profilePic.src ? "0.4" : "",
                            }}
                            alt={`avatar-${index}`}
                          />
                          {selectedAvatar === profilePic.src && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                top: "-110%",
                                right: "-60%",
                                width: "15px",
                                borderRadius: "50%",
                                backgroundColor: "#f8f9fa",
                              }}
                            >
                              <Check color="green" size={16} />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex justify-between flex-row gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <span className="settings-title">Información Básica</span>
                  <Form autoComplete="off">
                    <FormGroup>
                      <Label for="nameField">Nombre</Label>
                      <Input
                        id="nameField"
                        name="name"
                        placeholder="Nombre"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="lastNameField">Apellido</Label>
                      <Input
                        id="lastNameField"
                        name="lastName"
                        placeholder="Apellido"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="emailField">Email</Label>
                      <Input
                        id="emailField"
                        name="email"
                        placeholder="email"
                        type="email"
                        disabled
                        value={"josefelixchaparrojacome@gmail.com"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="dateOfBirthField">Fecha de nacimiento</Label>
                      <Input
                        id="dateOfBirthField"
                        name="dateOfBirth"
                        type="date"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="countryField">País</Label>
                      <Input
                        id="countryField"
                        name="country"
                        placeholder="País"
                        type="text"
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="phoneField">Número de celular</Label>
                      <Input
                        id="phoneField"
                        name="phone"
                        placeholder="Número de celular"
                        type="text"
                      />
                    </FormGroup>
                    <Button color="primary">Guardar cambios</Button>
                  </Form>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <span className="settings-title">Cambiar Contraseña</span>
                  <Form autoComplete="off">
                    <FormGroup>
                      <Label for="currentPassword">
                        Verificar contraseña actual
                      </Label>
                      <InputGroup>
                        <Input
                          id="currentPassword"
                          name="currentPassword"
                          type={showCurrentPassword ? "text" : "password"}
                        />
                        <button
                          className="show-password"
                          onClick={(evt) => {
                            evt.preventDefault();
                            setShowCurrentPassword(!showCurrentPassword);
                          }}
                        >
                          {showCurrentPassword ? (
                            <EyeOff size={14} />
                          ) : (
                            <Eye size={14} />
                          )}
                        </button>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label for="newPassword">Ingresar contraseña nueva</Label>
                      <InputGroup>
                        <Input
                          id="newPassword"
                          name="newPassword"
                          type={showNewPassword ? "text" : "password"}
                        />
                        <button
                          className="show-password"
                          onClick={(evt) => {
                            evt.preventDefault();
                            setShowNewPassword(!showNewPassword);
                          }}
                        >
                          {showNewPassword ? (
                            <EyeOff size={14} />
                          ) : (
                            <Eye size={14} />
                          )}
                        </button>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmNewPassword">
                        Confirmar contraseña nueva
                      </Label>
                      <InputGroup>
                        <Input
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          type={showConfirmNewPassword ? "text" : "password"}
                        />
                        <button
                          className="show-password"
                          onClick={(evt) => {
                            evt.preventDefault();
                            setShowConfirmNewPassword(!showConfirmNewPassword);
                          }}
                        >
                          {showConfirmNewPassword ? (
                            <EyeOff size={14} />
                          ) : (
                            <Eye size={14} />
                          )}
                        </button>
                      </InputGroup>
                    </FormGroup>
                    <Button color="primary">Cambiar contraseña</Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <span className="settings-title">Eliminar cuenta</span>
              <span className="settings-subtitle">
                Esta acción es irreversible
              </span>
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#6C757D",
                }}
              >
                Si eliminas tu cuenta, se borrarán todos tus balances guardados,
                incluidas tus cuentas bancarias y otros datos sincronizados.{" "}
              </span>
              <button
                onClick={() => toggleDeleteAccountModal()}
                className="w-fit bg-danger text-white border-1 border-solid border-danger text-sm py-1 px-2 rounded-md"
              >
                Eliminar cuenta
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteAccount
        isOpen={deleteAccountModal}
        toggle={toggleDeleteAccountModal}
      />
    </>
  );
};

export default General;
