import { useCallback, useEffect, useState, useMemo } from "react";
import Navbar from "../components/dashboard/Navbar";
import Sidebar from "../components/dashboard/Sidebar";
import { getSessionStorageItem } from "../utils/service/localStorageService";
import axiosInstance from "../utils/axiosIntance";
import AccountsContext from "../context/AccountContext";
import LoansContext from "../context/LoanContext";
import CreditCardContext from "../context/CreditCardContext";
import PropTypes from 'prop-types';

import { Layout } from 'antd';
const { Content, } = Layout;

const MainLayout = ({ children }) => {
  const { id: userId } = JSON.parse(getSessionStorageItem("user"));

  const [accounts, setAccounts] = useState([]);
  const [loans, setLoans] = useState([]);
  const [creditCards, setCreditCards] = useState([]);

  const fetchAccounts = useCallback(() => {
    axiosInstance({
      method: "GET",
      url: `/accounts/list-accounts/${userId}`,
    })
      .then((res) => setAccounts(res.data))
      .catch((err) => 
        {
          console.log(err)
          //if status code 403 then redirect to login page
          if(err.code === "ERR_NETWORK"){
            window.location.href = "/auth/login";
          }
        }
      );
  }, [userId]);

  const fetchLoans = useCallback(() => {
    axiosInstance({
      method: "GET",
      url: `/loan/list-loans/${userId}`,
    })
      .then((res) => {
        setLoans(res.data);
      })
      .catch((err) => console.log(err));
  }, [userId]);

  const fetchCreditCards = useCallback(() => {
    axiosInstance({
      method: "GET",
      url: `/credit-card/list-credit-cards/${userId}`,
    })
      .then((res) => setCreditCards(res.data))
      .catch((err) => console.log(err));
  }, [userId]);

  useEffect(() => {
    fetchAccounts();
    fetchLoans();
    fetchCreditCards();
  }, [fetchAccounts, fetchLoans, fetchCreditCards]);

  const accountsValue = useMemo(() => ({ accounts, fetchAccounts}), [accounts, fetchAccounts]);
  const loansValue = useMemo(() => ({ loans, fetchLoans }), [loans, fetchLoans]);
  const creditCardsValue = useMemo(() => ({ creditCards, fetchCreditCards }), [creditCards, fetchCreditCards]);

  return (
    <AccountsContext.Provider value={accountsValue}>
      <LoansContext.Provider value={loansValue}>
        <CreditCardContext.Provider value={creditCardsValue}>
          <Layout
            style={{
              minHeight: '100vh',
            }}
          >
            <Sidebar />
            <Layout>
              <Navbar />
              <Content
                style={{
                  margin: '16px 16px',
                }}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
        </CreditCardContext.Provider>
      </LoansContext.Provider>
    </AccountsContext.Provider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
