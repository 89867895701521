function Footer() {
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="container-5">
          <div className="logo-3">
            <img
              src={require("../images/logo_1.svg").default}
              loading="lazy"
              width="52.8510627746582"
              height="52.8510627746582"
              alt=""
              className="logo-4"
            />
            <img
              src={require("../images/Vectors-Wrapper_27.svg").default}
              loading="lazy"
              width="115.97872161865234"
              height="20.553192138671875"
              alt=""
              className="vectors-wrapper-22"
            />
          </div>
          <div className="action">
            <div className="logos">
              <a href="/auth/login" className="w-inline-block">
                <img
                  src={require("../images/Social-media2.svg").default}
                  loading="lazy"
                  width="40"
                  height="40"
                  alt="facebook"
                  className="vectors-wrapper-21"
                />
              </a>
              <a href="/auth/login" className="w-inline-block">
                <img
                  src={require("../images/Social-media2-1.svg").default}
                  loading="lazy"
                  width="40"
                  height="40"
                  alt="linkedin"
                  className="vectors-wrapper-21"
                />
              </a>
              <a href="/auth/login" className="w-inline-block">
                <img
                  src={require("../images/Social-media2-2.svg").default}
                  loading="lazy"
                  width="40"
                  height="40"
                  alt="twitter"
                  className="vectors-wrapper-21"
                />
              </a>
            </div>
            <div className="frame-2934">
              <a href="#funciona" className="link">
                Como funciona
              </a>
              <a href="#beneficios" className="link">
                Beneficios
              </a>
            </div>
          </div>
        </div>
        <div className="dividerver"></div>
        <div className="action">
          <div className="cc">
            © 2023 OnMission. Todos los derechos reservados.
          </div>
          <div className="frame-2935">
            <a href="terminos-y-condiciones.html" className="link bottom">
              Términos y Condiciones
            </a>
            <a href="politica-de-privacidad.html" className="link bottom">
              Política de privacidad
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
