import React from "react";
import Navbar from "../components/settings/Navbar";
import Sidebar from "../components/settings/Sidebar";

const SettingsLayout = ({ children }) => {
  return (
    <div className="flex relative">
      <div className="bg-main-bg w-full min-h-screen">
        <div className="fixed md:static bg-light navbar w-full">
          <Navbar />
        </div>
        <div className="container flex flex-col bg-light">
          <div className="flex h-24 items-center py-8 px-12 text-xl text-gray-500">
            Configuración de cuenta
          </div>
          <div className="flex flex-row">
            <Sidebar />
            <div className="w-full rounded-xl py-6 px-12">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
