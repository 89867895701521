import React from "react";
import { ConfigProvider, DatePicker, Form, Select } from "antd";

import esES from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";

dayjs.locale("es");

const Bills = ({ accountOptions, categoryOptions, loadingOptions }) => {
  /**
   * Form instance
   */
  const [form] = Form.useForm();

  /**
   * Range date picker instance
   */
  const { RangePicker } = DatePicker;

  return (
    <>
      <div className="flex flex-row items-center justify-between p-2 w-full h-12 bg-white border-b-2 border-b-gray-200">
        <div className="flex flex-col justify-end">
          <div className="flex flex-row gap-3">
            <Form form={form} component={false}>
              <div className="flex flex-col w-full h-full align-middle">
                <Form.Item name="category" className="mb-0">
                  {Object.values(categoryOptions || {})?.map(
                    ({ id, name, textColor }) => (
                      <Select.Option key={id} value={id}>
                        <i
                          className="bi bi-circle-fill"
                          style={{
                            color: textColor,
                            fontSize: "12px",
                            marginRight: "5px",
                          }}
                        ></i>
                        {name}
                      </Select.Option>
                    )
                  )}
                </Form.Item>
              </div>
              <div className="flex flex-col w-full h-full align-middle">
                <Form.Item name="dates" className="mb-0">
                  <ConfigProvider locale={esES}>
                    <RangePicker
                      placeholder={["Fecha Inicial", "Fecha Final"]}
                      className="w-64"
                      format={"MM/YYYY"}
                      onChange={(e) => console.log(e)}
                    />
                  </ConfigProvider>
                </Form.Item>
              </div>
              <div className="flex flex-col w-full h-full align-middle">
                <Form.Item name="account" className="mb-0">
                  <Select
                    placeholder="Todas las cuentas"
                    notFoundContent={"No existen cuentas"}
                    loading={loadingOptions}
                    allowClear
                    onChange={(e) => console.log(e)}
                    className="w-52"
                  >
                    {accountOptions}
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
        <div className="flex flex-col align-middle">
          <div className="flex flex-row text-primary my-0 cursor-pointer gap-1">
            <i className="bi bi-download"></i>
            <span>Exportar</span>
          </div>
        </div>
      </div>
      <div className="flex flex-row p-4 w-full m-0 bg-white">
        <h1>TODOS LOS GASTOS</h1>
      </div>
    </>
  );
};

export default Bills;
