import React, { useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
} from "reactstrap";


import avatar from "../../assets/imgs/avatars/rcmen1.png";
import { Link } from "react-router-dom";

import { ACCOUNTS, GENERAL_SETTINGS } from "../../routes";
import { useAuth } from "../../hooks/userConstext";
import { useStateContext } from "../../context/ContextProvider";

const Navbar = () => {
  const { logout } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const { activeMenu, setActiveMenu } = useStateContext();

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="w-full text-gray-500 text-base"
    >
      <div className="w-full flex items-center justify-between p-2 relative bg-main-bg">
        <Button
          color="black"
          className="d-md-none mr-auto hover:bg-primary"
          onMouseOver={(e) => (e.target.style.color = "primary")}
          onMouseOut={(e) => (e.target.style.color = "black")}
          onClick={() => {
            console.log("SideMenu");
            setActiveMenu(!activeMenu);
            console.log(activeMenu);
          }}
        >
          ☰
        </Button>
        <DropdownToggle className="border-0 bg-transparent hover:bg-main">
          <img
            src={avatar}
            alt="avatar"
            style={{ width: "32px", height: "32px" }}
          />
        </DropdownToggle>
      </div>
      <DropdownMenu className="mt-1">
        <DropdownItem>
          <Link
            to={`${ACCOUNTS}/0`}
            style={{ textDecoration: "none", color: "#6C757D" }}
          >
            Ir al dashboard
          </Link>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem disabled>
          <i 
            className="bi bi-award-fill" 
            style={{ fontSize: "14px", color: "#2C7BE5" }}
          >
          </i>Mejorar a PRO
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <Link
            to={GENERAL_SETTINGS}
            style={{ textDecoration: "none", color: "#6C757D" }}
          >
            Configuración de cuenta
          </Link>
        </DropdownItem>
        <DropdownItem
          style={{ textDecoration: "none", color: "#6C757D" }}
          onClick={logout}
        >
          Cerrar sesión
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>    
  );
};

export default Navbar;
