import React, {useEffect, useState} from "react";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { Button, ConfigProvider, DatePicker } from "antd";

import esES from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "./MonthPicker.scss";

import { dollarUS } from "../../../utils/dollarFormat";

dayjs.locale("es");

const BudgetHeader = ({
  selectedMonth,
  setSelectedMonth,
  totalAvailable,
  setBlockEdit,
}) => {

    const [avaibleTotalText, setAvaibleTotalText] = useState('Total disponible');

    useEffect(() => {
        if (dayjs(selectedMonth).isBefore(dayjs().startOf("month"), "month")) {
            setAvaibleTotalText("Balance final");
        } else {
            setAvaibleTotalText("Total disponible");
        }
    }, [selectedMonth]);

  return (
    <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row  w-full justify-between items-center p-4">
      <div className="flex flex-col h-full w-full lg:w-1/2">
        <div className="flex flex-col w-full lg:w-1/3 justify-center align-middle budget-monthpicker">
          <div className="flex flex-row w-full">
            <i
              className="bi bi-chevron-left text-primary text-base cursor-pointer"
              onClick={() => {
                const previousMonth = dayjs(selectedMonth).subtract(1, "month");

                setBlockEdit(
                  previousMonth.isBefore(dayjs().startOf("month"), "month")
                );

                setSelectedMonth(previousMonth);
              }}
            ></i>
            <ConfigProvider locale={esES}>
              <DatePicker
                className="w-full bg-transparent text-primary font-roboto border-0"
                format="MMMM YYYY"
                picker="month"
                allowClear={false}
                value={selectedMonth}
                bordered={false}
                suffixIcon={<></>}
                onChange={(current) => {
                  const { $M, $y } = current;

                  setBlockEdit(
                    dayjs(current).isBefore(dayjs().startOf("month"), "month")
                  );
                  setSelectedMonth(dayjs(`${$y}-${$M + 1}-01`));
                }}
              />
            </ConfigProvider>
            <i
              className="bi bi-chevron-right text-primary text-base cursor-pointer"
              onClick={() => {
                const nextMonth = dayjs(selectedMonth).add(1, "month");

                setBlockEdit(
                  nextMonth.isBefore(dayjs().startOf("month"), "month")
                );

                setSelectedMonth(dayjs(selectedMonth).add(1, "month"));
              }}
            ></i>
          </div>
          <div className="flex flex-row w-full justify-center">
            <Button
              style={{ width: "70px" }}
              className="bg-primary text-white"
              onClick={() => setSelectedMonth(dayjs())}
            >
              {"Hoy"}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full w-1/2 align-start lg:align-middle">
        <div className="flex flex-row items-center justify-end w-full h-full gap-2">
          <div className="flex flex-row justify-start items-center h-10 ">
            <span className="text-lg font-semibold">{avaibleTotalText}:</span>
          </div>
          <div
            className={`flex flex-row justify-center items-center w-auto h-10 font-bold text-xl py-1 px-4 rounded
                ${
                  totalAvailable < 0
                    ? "text-red-500 bg-red-100"
                    : "text-green-500 bg-green-100"
                }`}
          >
            {dollarUS.format(totalAvailable)}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full h-full"></div>
      </div>
    </div>
  );
};

export default BudgetHeader;
