import { Select, Tabs } from "antd";
import { getSessionStorageItem } from "../../utils/service/localStorageService";
import Bills from "../../components/dashboard/reports/Bills";
import InVsOut from "../../components/dashboard/reports/InVsOut";
import Networth from "../../components/dashboard/reports/Networth";

import { useParams } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../utils/axiosIntance";
import Graphical from "../../components/dashboard/accounts/body/Graphical";

const Reports = () => {
  const { email, id: userId } = JSON.parse(getSessionStorageItem("user"));

  /**
   * States for categories
   */
  const [categoryOptions, setCategoryOptions] = useState([]);

  /**
   * States for categories
   */
  const [accountOptions, setAccountOptions] = useState([]);

  /**
   * Loading options state
   */
  const [loadingOptions, setLoadingOptions] = useState(false);

  //
  const user = { email: email, userId: userId };

  const { id: account } = useParams();

  useEffect(() => {
    setLoadingOptions(true);
    /**
     * Fetching subcategories
     */
    axiosInstance({
      url: `/subcategory/${userId}`,
      method: "GET",
    })
      .then(({ data }) => {
        setCategoryOptions(data);
      })
      .catch((err) => console.log(err));

    axiosInstance({
      method: "GET",
      url: `/accounts/list-accounts/${userId}`,
    })
      .then(({ data }) => {
        setAccountOptions(
          Object.values(data || {})?.map(({ id, name, color }) => (
            <Select.Option key={id} value={id}>
              <i
                className="bi bi-circle-fill"
                style={{
                  color: color,
                  fontSize: "12px",
                  marginRight: "5px",
                }}
              ></i>
              {name}
            </Select.Option>
          ))
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingOptions(false));
  }, [userId, email]);

  return (
    <div className="p-4 account-container h-full">
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Ingresos vs. Gastos",
            key: "3",
            children: (
              <InVsOut
                accountOptions={accountOptions}
                categoryOptions={categoryOptions}
                loadingOptions={loadingOptions}
                userId={userId}
              />
            ),
          },
          {
            label: "Reportes de Tendencias",
            key: "4",
            children: (
              <Graphical
                user={user}
                accountId={account}
                accountOptions={accountOptions}
                loadingOptions={loadingOptions}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default Reports;
