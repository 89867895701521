function Beneficios() {
  return (
    <div id="beneficios" className="beneficios">
      <div className="container-2">
        <div className="small-container-3">
          <div className="_2-columns f2wf-columns">
            <div className="column">
              <div className="text-19">
                <div className="text-20">Maneja tus finanzas</div>
                <div className="text-16">Nuestros beneficios</div>
                <div className="text-17">
                  ¡Nunca ha sido tan fácil manejar tus finanzas personales y
                  sentirte seguro sobre tu futuro financiero!
                </div>
              </div>
              <div className="grid">
                <div className="columns-3 f2wf-columns">
                  <div className="column-2">
                    <div className="content">
                      <div className="frame-1000004407">
                        <img
                          src={
                            require("../images/Vectors-Wrapper_17.svg").default
                          }
                          loading="lazy"
                          width="24"
                          height="24"
                          alt=""
                          className="vectors-wrapper-18"
                        />
                      </div>
                      <div className="text-21">Visualiza tus finanzas</div>
                      <div className="text-22">
                        Con su interfaz intuitiva, puedes ver claramente cuánto 
                        dinero estas ingresando, gastando 
                        y en qué lo estas invirtiendo.
                      </div>
                    </div>
                  </div>
                  <div className="column-2">
                    <div className="text-19">
                      <div className="frame-1000004407">
                        <img
                          src={
                            require("../images/Vectors-Wrapper_18.svg").default
                          }
                          loading="lazy"
                          width="24"
                          height="24"
                          alt=""
                          className="vectors-wrapper-18"
                        />
                      </div>
                      <div className="text-21">Fácil de usar</div>
                      <div className="text-22">
                        Accede a tu cuenta en cualquier lugar y momento para ver
                        tus transacciones y actualizar tu presupuesto.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="f2wf-columns columns-3-copy">
                  <div className="column-3">
                    <div className="content-2">
                      <div className="frame-1000004407">
                        <img
                          src={
                            require("../images/Vectors-Wrapper_19.svg").default
                          }
                          loading="lazy"
                          width="24"
                          height="24"
                          alt=""
                          className="vectors-wrapper-18"
                        />
                      </div>
                      <div className="text-21">Establece metas</div>
                      <div className="text-22">
                        Crea un plan de ahorro para alcanzarlas. No importa
                        cuáles sean tus objetivos financieros, OnMission puede
                        ayudarte a lograrlos.
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="text-19">
                      <div className="frame-1000004407">
                        <img
                          src={
                            require("../images/Vectors-Wrapper_20.svg").default
                          }
                          loading="lazy"
                          width="24"
                          height="24"
                          alt=""
                          className="vectors-wrapper-18"
                        />
                      </div>
                      <div className="text-21">Informes detallados</div>
                      <div className="text-22">
                        Consulta análisis y patrones de gastos para entender
                        mejor tus hábitos financieros. Te ayudarán a identificar
                        áreas de ahorro y cambios para mejorar tus finanzas
                        personales.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beneficios;
