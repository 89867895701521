import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./CustomModalLoader.scss";

const CustomModalLoader = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} size="sm" centered={true}>
      <ModalBody className="flex justify-center content-center w-full">
        <div className="contenedor-loader">
          <div className="loader1"></div>
          <div className="loader2"></div>
          <div className="loader3"></div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomModalLoader;
