import { UserProvider } from "../hooks/userConstext";

const AppProvider = ({ children }) => {
  return (
    <>
      <UserProvider>{children}</UserProvider>
    </>
  );
};

export default AppProvider;
