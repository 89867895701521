import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { IMPORT_FILE } from "../../../routes";
import { dollarUS } from "../../../utils/dollarFormat";

const GetLastUpdateHours = ({ lastUpdate }) => {
  if (!lastUpdate) return "-";

  const currentTime = new Date();
  const diffTime = Math.abs(currentTime - new Date(lastUpdate));
  const diffInMinutes = Math.floor(diffTime / 1000 / 60);
  const diffInHours = Math.floor(diffTime / 1000 / 60 / 60);
  const diffInDays = Math.floor(diffTime / 1000 / 60 / 60 / 24);
  
  if(isNaN(diffInMinutes) || isNaN(diffInHours) || isNaN(diffInDays)) 
    return (<span>-</span>);

  if (diffInMinutes < 60) {
    return <span>{`Actualizado hace ${diffInMinutes}m.`}</span>;
  } else if (diffInHours < 24) {
    return <span>{`Actualizado hace ${diffInHours}h.`}</span>;
  } else {
    return <span>{`Actualizado hace ${diffInDays}d.`}</span>;
  }

};

const AccountsHeader = ({
  lastUpdate,
  totalInflow,
  totalOutflow,
  totalSelectedBalance,
  totalSelectedRows,
  selectedAccount,
}) => {
  return (
    <section className="accounts-header">
      <div className="flex items-start justify-between gap-4">
        <div className="flex flex-col">
          <div className="flex gap-1 items-center">
            <span>
              <i className="bi bi-bank2"></i>
            </span>
            <h4 className="accounts-title">
              {selectedAccount && selectedAccount.length > 0
                ? selectedAccount[0].name
                : "Todas las Cuentas"}
            </h4>
          </div>
          <span className="accounts-subtitle">
            <GetLastUpdateHours lastUpdate={lastUpdate} />
          </span>
        </div>
        <div className="flex flex-col md:flex-row items-start gap-4 right-0">
          <Link className="flex items-start justify-end w-full" to={IMPORT_FILE}>
            <button type="button" className="btn btn-outline-primary">
              <i className="bi bi-upload mr-1"></i>
              Importar
            </button>
          </Link>
          <div className="flex gap-4 lg:gap-6 flex-col md:flex-row justify-end items-start">
            {/* Ingresos */}
            <div style={{ borderRight: "1px solid #E9ECEF"}} className="pr-4">
              <div className="kpi-card">
                <div className="flex items-start gap-2"> 
                  <i
                    className="bi bi-square-fill"
                    style={{
                      color: "#2C7BE5",
                      width: "8px",
                      height: "8px",
                      marginRight: "4px",
                    }}
                  ></i>
                  <span>Ingresos</span>
                </div>
                <div className="account-numbers">{`${dollarUS.format(
                  totalInflow
                )}`}</div>
              </div>
            </div>
            {/* Egresos */}
            <div style={{ borderRight: "1px solid #E9ECEF" }} className="pr-4">
              <div className="kpi-card">
                <div className="flex items-start gap-2">
                  <i
                    className="bi bi-square-fill"
                    style={{
                      color: "#DC3545",
                      width: "8px",
                      height: "8px",
                      marginRight: "4px",
                    }}
                  ></i>
                  <span>Egresos</span>
                </div>
                <div className="account-numbers">{`${dollarUS.format(
                  totalOutflow
                )}`}</div>
              </div>
            </div>
            {/* Balance */}
            <div md={3} lg={3} sm={6}>
              <div className="kpi-card">
                <div className="flex items-start gap-2">
                  <i
                    className="bi bi-square-fill"
                    style={{
                      color: "#198754",
                      width: "8px",
                      height: "8px",
                      marginRight: "4px",
                    }}
                  ></i>
                  <span>Balance</span>{" "}
                </div>
                <div className="account-numbers">{`${dollarUS.format(
                  totalInflow - totalOutflow
                )}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountsHeader;
