import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const DeleteAccount = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Estás a punto de eliminar tu cuenta
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Input
              style={{
                fontWeight: "800",
                fontSize: "16px",
                lineHeight: "19px",
              }}
              plaintext
              value="Ingresa ELIMINAR para confirmar."
            />
          </FormGroup>
          <FormGroup>
            <Input
              style={{
                height: "48px",
                fontWeight: "800",
                fontSize: "16px",
                lineHeight: "19px",
              }}
              type="text"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <>
          <Button outline>Cancelar</Button>
          <Button color="danger">Eliminar cuenta</Button>
        </>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteAccount;
