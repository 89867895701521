import React, { useEffect, useState, useCallback } from "react";
import { Form, Select, Table } from "antd";
import { getDatesRange } from "../../../utils/getDateRange";
import axiosInstance from "../../../utils/axiosIntance";
import { dollarUS } from "../../../utils/dollarFormat";
import PropTypes from "prop-types";

function renderSummaryRow(pageData, monthRange, dateRange, type) {
  return (
    <SummaryRow
      pageData={pageData}
      monthRange={monthRange}
      dateRange={dateRange}
      type={type}
    />
  );
}

function generateMonths(monthRange) {
  return monthRange.map((month, index) => ({
    title: (
      <span className="text-md font-semibold text-gray-900 capitalize">
        {month}
      </span>
    ),
    dataIndex: month,
    key: month,
    width: "150px",
    align: "right",
    render: (text, row) => {
      const matchingProperty = Object.keys(row).find((key) => 
        key.toLowerCase().includes(month.toLowerCase().slice(0, 3))
      );

      if (matchingProperty) {
        return dollarUS.format(row[matchingProperty].value);
      } else {
        return dollarUS.format(0);
      }
    },
    summary: (data) => {
      const total = data.reduce((acc, curr) => acc + curr[month].value, 0);
      return <strong>{total}</strong>;
    },
  }));
}

function getClassName(amount, type) {
  let className = "text-end font-semibold";
  if (type === "profit" && amount < 0) {
    className = "text-red-500 text-end font-semibold";
  } else if (type === "profit" && amount >= 0) {
    className = "text-green-500 text-end font-semibold";
  }
  return className;
}

const SummaryRow = ({ pageData, monthRange, dateRange, type }) => {
  let total = 0;
  let average = 0;

  const totalByMonths = monthRange.reduce((acc, month) => {
    pageData.forEach((item) => {
      let matchKey = Object.keys(item).find((key) => 
          key.startsWith(month.toLowerCase().slice(0, 3))
      );
  
      if (matchKey) {
        acc[month] = (acc[month] || 0) + item[matchKey].value || 0;
        total += item[matchKey].value || 0;
      }
    });
  
    return acc;
  }, {});
  
  if (monthRange.length > 0) {
    average = total / monthRange.length;
  }

  let text = "utilidad";
  if(type !== "profit"){
    text = type === "inflow" ? "ingresos" : "egresos";
  }
  
  let classNameTotal = "flex flex-row text-lg font-semibold";
  if(type === "profit"){
    classNameTotal = "flex flex-row text-lg font-semibold text-blue-500";
  } else if(type === "inflow"){
    classNameTotal = "flex flex-row text-lg font-semibold text-green-500";
  } else if(type === "outflow"){
    classNameTotal = "flex flex-row text-lg font-semibold text-red-500";
  }

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>
        <div 
          className={classNameTotal}
        >
          Total {text}
        </div>
      </Table.Summary.Cell>
      {monthRange.map((month) => {
        return (
          <Table.Summary.Cell
            key={month}
            index={month}
            className={getClassName(totalByMonths[month] || 0, type)}
          >
            {dollarUS.format(totalByMonths[month] || 0)}
          </Table.Summary.Cell>
        );
      })}
      <Table.Summary.Cell
        className={getClassName(average, type)}
        index={dateRange.length + 1}
      >
        {dollarUS.format(average)}
      </Table.Summary.Cell>
      <Table.Summary.Cell
        className={getClassName(total, type)}
        index={dateRange.length + 2}
      >
        {dollarUS.format(total)}
      </Table.Summary.Cell>
    </Table.Summary.Row>
    
  );
};

SummaryRow.propTypes = {
  pageData: PropTypes.array.isRequired,
  monthRange: PropTypes.array.isRequired,
  dateRange: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

const InVsOut = ({
  accountOptions,
  categoryOptions,
  loadingOptions,
  userId,
}) => {
  /**
   * Form instance
   */
  const [form] = Form.useForm();


  /**
   * State for date range
   */
  const [dateRange, setDateRange] = useState(
    getDatesRange(new Date(), new Date())
  );

  /**
   * Data and data loader states
   */
  const [dataInflows, setDataInflows] = useState([]);
  const [dataOutflows, setDataOutflows] = useState([]);
  //utility
  const [dataProfit, setDataProfit] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [rangeMonths, setRangeMonths] = useState(3);

  const updateListMonths = useCallback(() => {
    let currentMonth = new Date().getMonth();

    let monthNames = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
];
    let selectedMonths = [];

    // Inicio

    for (let i = 0; i < rangeMonths; i++) {
      let index = currentMonth - i;
      if (index < 0) {
        index = index + 12;
      }
      selectedMonths.push(monthNames[index]);
    }

    // Final

    return selectedMonths.reverse();
  }, [rangeMonths]);

  const monthRange = updateListMonths();

  useEffect(() => {
    updateListMonths();
  }, [updateListMonths]);

  /**
   * Get report data handler
   */
  const handleGetInVsOut = useCallback(async () => {
    try {
      const category = await form.getFieldValue("category");
      const account = await form.getFieldValue("account");

      const today = new Date();
      const endYear = today.getFullYear();
      const endMonth = today.getMonth() + 1;

      const endMonthYear = new Date(endYear, endMonth, 0);
      const startMonthYear = new Date(endYear, endMonth - rangeMonths, 1);

      const params = {
        accountId: account || 0,
        userId,
        subcategoryId: category || 0,
        startDate: startMonthYear,
        endDate: endMonthYear,
      };

      setLoadingData(true);

      axiosInstance({
        method: "POST",
        url: "/transactions/summaryBySubcategory",
        data: params,
      })
        .then(({ data: { list } }) => {
          /**
           * Separating inflows by category
           */

          const nestedInflowData = list.reduce((acc, curr) => {
            const { subcategory, inflows } = curr;
            const categoryId = subcategory ? subcategory.id : "Sin categorizar";
            const categoryName = subcategory
              ? subcategory.name
              : "Sin categorizar";
            const monthKey = new Date(
              curr.month.substring(0, 7) + "-02"
            ).toLocaleDateString("es-ES", { month: "short", year: "2-digit" }); // group by year and month only

            const inflowsByMonth = acc[categoryId] || {};
            const inflowsForCategory = inflowsByMonth[monthKey] || [];
            inflowsForCategory.push({
              id: categoryId,
              name: categoryName,
              value: inflows,
            });

            inflowsByMonth[monthKey] = inflowsForCategory;
            acc[categoryId] = inflowsByMonth;
            return acc;
          }, {});

          /**
           * Separating outflows by category
           */
          const nestedOutflowData = list.reduce((acc, curr) => {
            const { subcategory, outflows } = curr;
            const categoryId = subcategory ? subcategory.id : "Sin categorizar";
            const categoryName = subcategory
              ? subcategory.name
              : "Sin categorizar";
            const monthKey = new Date(
              curr.month.substring(0, 7) + "-02"
            ).toLocaleDateString("es-ES", { month: "short", year: "2-digit" }); // group by year and month only

            const outflowsByMonth = acc[categoryId] || {};
            const outflowsForCategory = outflowsByMonth[monthKey] || [];
            outflowsForCategory.push({
              id: categoryId,
              name: categoryName,
              value: outflows,
            });

            outflowsByMonth[monthKey] = outflowsForCategory;
            acc[categoryId] = outflowsByMonth;

            return acc;
          }, {});

          /**
           * Assigning inflows to month
           */
          const inflowData = Object.entries(nestedInflowData).map(
            ([categoryId, inflowsByMonth]) => {
              const row = { key: categoryId };
              let total = 0;

              row.category =
                inflowsByMonth[Object.keys(inflowsByMonth)[0]][0].name; // Use the first available category name

              Object.keys(inflowsByMonth).forEach((monthKey) => {
                const inflowsForCategory = inflowsByMonth[monthKey];

                inflowsForCategory.forEach(({ id, value }) => {
                  const columnKey = monthKey.replace("-", " "); // Convert "YYYY-MM" to "MMM YY"
                  
                  row[columnKey] = row[columnKey] || {
                    key: `${categoryId}_${columnKey}`,
                    value: 0,
                  };

                  row[columnKey].value += value;
                  total += value;
                });

                row.total = total;
              });

              row.average = (row.total / rangeMonths).toFixed(2);
              return row;
            }
          );

          /**
           * Assigning outflows to month
           */
          const outflowData = Object.entries(nestedOutflowData).map(
            ([categoryId, outflowsByMonth]) => {
              const row = { key: categoryId };
              let total = 0;

              row.category =
                outflowsByMonth[Object.keys(outflowsByMonth)[0]][0].name; // Use the first available category name

              Object.keys(outflowsByMonth).forEach((monthKey) => {
                const outflowsForCategory = outflowsByMonth[monthKey];

                outflowsForCategory.forEach(({ id, value }) => {
                  const columnKey = monthKey.replace("-", " "); // Convert "YYYY-MM" to "MMM YY"
                  row[columnKey] = row[columnKey] || {
                    key: `${categoryId}_${columnKey}`,
                    value: 0,
                  };

                  row[columnKey].value += value;
                  total += value;
                });

                row.total = total;
              });
              row.average = (row.total / rangeMonths).toFixed(2);
              return row;
            }
          );
          //calculate profit by mont similar as inflow and outflow
          const nestedProfitData = list.reduce((acc, curr) => {
            const { subcategory, inflows, outflows } = curr;
            const categoryId = subcategory ? subcategory.id : "Sin categorizar";
            const categoryName = subcategory
              ? subcategory.name
              : "Sin categorizar";
            const monthKey = new Date(
              curr.month.substring(0, 7) + "-02"
            ).toLocaleDateString("es-ES", { month: "short", year: "2-digit" }); // group by year and month only

            const profitByMonth = acc[categoryId] || {};
            const profitForCategory = profitByMonth[monthKey] || [];
            profitForCategory.push({
              id: categoryId,
              name: categoryName,
              value: inflows - outflows,
            });

            profitByMonth[monthKey] = profitForCategory;
            acc[categoryId] = profitByMonth;
            return acc;
          }, {});

          const profitData = Object.entries(nestedProfitData).map(
            ([categoryId, profitByMonth]) => {
              const row = { key: categoryId };
              let total = 0;

              row.category =
                profitByMonth[Object.keys(profitByMonth)[0]][0].name; // Use the first available category name

              Object.keys(profitByMonth).forEach((monthKey) => {
                const profitForCategory = profitByMonth[monthKey];

                profitForCategory.forEach(({ id, value }) => {
                  const columnKey = monthKey.replace("-", " "); // Convert "YYYY-MM" to "MMM YY"
                  row[columnKey] = row[columnKey] || {
                    key: `${categoryId}_${columnKey}`,
                    value: 0,
                  };

                  row[columnKey].value += value;
                  total += value;
                });

                row.total = total;
              });
              row.average = (row.total / rangeMonths).toFixed(2);
              return row;
            }
          );

          setDataProfit(profitData);
          setDataInflows(inflowData);
          setDataOutflows(outflowData);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingData(false));
    } catch (error) {
      console.log(error);
    }
  },[form, userId, rangeMonths]);

  useEffect(() => {
    handleGetInVsOut();
  }, [handleGetInVsOut]);

  const months = generateMonths(monthRange);

  const ingresosColumns = [
    {
      title: (
        <div className="flex flex-row text-lg font-semibold text-green-500">
          Ingresos
        </div>
      ),
      dataIndex: "category",
      key: "category",
      fixed: "left",
      width: 120,
    },
    ...months,
    {
      title: (
        <span className="text-sm font-semibold text-gray-900 capitalize">
          Promedio
        </span>
      ),
      key: "average",
      align: "right",
      width: "150px",
      responsive: ["md"],
      render: (text, row) => dollarUS.format(row.average),
    },
    {
      title: (
        <span className="text-sm font-semibold text-gray-900 capitalize">
          Total
        </span>
      ),
      key: "total",
      align: "right",
      width: 150,
      responsive: ["lg"],
      fixed: "md:right",
      render: (text, row) => dollarUS.format(row.total),
    },
  ];

  const egresosColumns = [
    {
      title: (
        <div className="flex flex-row text-lg font-semibold text-red-500">
          Egresos
        </div>
      ),
      dataIndex: "category",
      key: "category",
      fixed: "left",
      width: 120,
    },
    ...months,
    {
      title: (
        <span className="text-sm font-semibold text-gray-900 capitalize">
          Promedio
        </span>
      ),
      key: "average",
      align: "right",
      width: "150px",
      responsive: ["md"],
      render: (text, row) => dollarUS.format(row.average),
    },
    {
      title: (
        <span className="text-sm font-semibold text-gray-900 capitalize">
          Total
        </span>
      ),
      key: "total",
      align: "right",
      width: 150,
      responsive: ["lg"],
      fixed: "md:right",
      render: (text, row) => dollarUS.format(row.total),
    },
  ];

  const profitColumns = [
    {
      title: (
        <div className="flex flex-row text-lg font-semibold text-blue-500">
          Utilidad
        </div>
      ),
      dataIndex: "category",
      key: "category",
      fixed: "left",
      width: 120,
    },
    ...months,
    {
      title: (
        <span className="text-sm font-semibold text-gray-900 capitalize">
          Promedio
        </span>
      ),
      key: "average",
      align: "right",
      width: "150px",
      responsive: ["md"],
      render: (text, row) => dollarUS.format(row.average),
    },
    {
      title: (
        <span className="text-sm font-semibold text-gray-900 capitalize">
          Total
        </span>
      ),
      key: "total",
      align: "right",
      width: 150,
      responsive: ["lg"],
      fixed: "md:right",
      render: (text, row) => dollarUS.format(row.total),
    },
  ];



  const handleChangeRangeMonths = (value) => {
    setRangeMonths(value);
  };

  const { Option } = Select;

  return (
    <>
      <div className="flex flex-row items-center justify-between p-2 w-auto h-12 bg-white border-b-2 border-b-gray-200">
        <div className="flex flex-col justify-end w-full">
          <div className="flex flex-row justify-between w-full">
            <Form form={form} component={false}>
              <div className="flex flex-row gap-8">
                <div className="flex flex-col w-full h-full align-middle">
                  <Form.Item name="category" className="mb-0">
                    <Select
                      placeholder="Todas las categorías"
                      notFoundContent={"No existen categorías"}
                      loading={loadingOptions}
                      allowClear
                      onChange={handleGetInVsOut}
                      className="w-52"
                    >
                      {Object.values(categoryOptions || {})?.map(
                        ({ id, name, textColor }) => (
                          <Select.Option key={id} value={id}>
                            <i
                              className="bi bi-circle-fill"
                              style={{
                                color: textColor,
                                fontSize: "12px",
                                marginRight: "5px",
                              }}
                            ></i>
                            {name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex flex-col w-full h-full align-middle">
                  <Form.Item name="account" className="mb-0">
                    <Select
                      placeholder="Todas las cuentas"
                      notFoundContent={"No existen cuentas"}
                      loading={loadingOptions}
                      allowClear
                      onChange={handleGetInVsOut}
                      className="w-52"
                    >
                      {accountOptions}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="flex flex-col w-full h-full align-middle">
                  <Select
                    className="w-64"
                    defaultValue={3}
                    onChange={handleChangeRangeMonths}
                  >
                    <Option value={3}>3 meses</Option>
                    <Option value={6}>6 meses</Option>
                    <Option value={9}>9 meses</Option>
                    <Option value={12}>12 meses</Option>
                  </Select>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="flex flex-row p-4 w-full m-0 bg-white">
        <div className="flex flex-col w-full gap-4">
          <div className="flex flex-row text-2xl font-bold">
            Ingresos vs. Gastos
          </div>
          <div className="flex flex-col w-full h-full gap-6 overflow-auto">
            <Table
              dataSource={dataInflows}
              columns={ingresosColumns}
              className="w-full"
              pagination={false}
              locale={{
                emptyText: () => (
                  <p className="text-center text-xs font-medium text-gray-700 my-4">
                    No existe información para este reporte
                  </p>
                ),
              }}
              loading={loadingData}
              scroll={{
                x: "100%",
              }}
              summary={(pageData) => renderSummaryRow(pageData, monthRange, dateRange, "inflow")}
            />

            <Table
              dataSource={dataOutflows}
              columns={egresosColumns}
              className="w-full"
              pagination={false}
              locale={{
                emptyText: () => (
                  <p className="text-center text-xs font-medium text-gray-700 my-4">
                    No existe información para este reporte
                  </p>
                ),
              }}
              loading={loadingData}
              scroll={{
                x: "100%",
              }}
              summary={(pageData) => renderSummaryRow(pageData, monthRange, dateRange, "outflow")}
            />

            {/* create profit by month table with red color < 0 and green > 0  */}
            {/* renderSummaryRow with profit data */}

            <Table
              dataSource={dataProfit}
              columns={profitColumns}
              className="w-full"
              pagination={false}
              locale={{
                emptyText: () => (
                  <p className="text-center text-xs font-medium text-gray-700 my-4">
                    No existe información para este reporte
                  </p>
                ),
              }}
              loading={loadingData}
              scroll={{
                x: "100%",
              }}
              summary={(pageData) => renderSummaryRow(pageData, monthRange, dateRange, "profit")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

InVsOut.propTypes = {
  accountOptions: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  loadingOptions: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export default InVsOut;
