import React from "react";
import Stepper from "../../components/settings/import/Stepper";

const ImportFile = () => {
  return (
    <>
      <div className="flex flex-col gap-8">
        <span className="settings-title">Importar movimientos bancarios</span>
        <div className="flex flex-col gap-16">
          <div className="flex flex-row w-full">
            <div className="flex flex-col gap-10 w-full">
              <span className="settings-subtitle">
                Selecciona un archivo para importar
              </span>
              <Stepper />
            </div>
            <div></div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex flex-col gap-10">
              <span className="settings-subtitle">
                Te explicamos como se maneja tu data
              </span>
              <p
                className="text-gray-600"
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum
                felis eu pede mollis pretium. Integer tincidunt. Cras dapibu
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportFile;
