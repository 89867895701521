import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import React, { useState } from "react";
import { accountTypes } from "../data/accountTypes";
import { colourOptions } from "../utils/colourOptions";
import axiosInstance from "../utils/axiosIntance";
import CustomModalLoader from "./customModalLoader/CustomModalLoader";
import { ToastContainer, toast } from "react-toastify";

const { Option } = Select;

const accountTypeOptions = accountTypes.map((account, index) => {
  return (
    <Option key={account.id} value={index}>
      {account.name}
    </Option>
  );
});

const AddAccountModal = ({
  isModalOpen,
  toggleNewAccount,
  email,
  fetchAccounts,
  fetchLoans,
  fetchCreditCards,
}) => {
  /**
   * Form instance
   */
  const [form] = Form.useForm();

  /**
   * Account modal content handler
   */
  const [step, setStep] = useState(0);

  const [typeAccount, setTypeAccount] = useState("account");
  const [isCreditCard, setIsCreditCard] = useState(false);

  /**
   * State to show loader
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Color options
   */
  const colorOptions = colourOptions.map(({ label, color }) => {
    return (
      <Option key={color} value={color}>
        <i
          className="bi bi-circle-fill mr-2"
          style={{
            color: color,
            fontSize: "12px",
          }}
        ></i>
        {label}
      </Option>
    );
  });

  const onOk = async () => {
    try {
      const {
        name,
        type,
        balance,
        color,
        limiteTdc,
        montoAbonado,
        montoPrestamo,
        pagoMinimo,
        tasaInteres,
        expirationDate,
      } = await form.validateFields();

      const errors = form.getFieldError;
      console.log(errors);
      console.log(form.getFieldsValue());
      let urlPath = "/accounts/add-account";
      if (typeAccount === "tdc") {
        urlPath = "/credit-card/add-credit-card";
      } else if (typeAccount === "loan") {
        urlPath = "/loan/add-loan";
      }
      setIsLoading(true);
      const params = {
        userEmail: email,
        name: name,
        type: type,
        creditAmmount: limiteTdc,
        expirationDate: expirationDate,
        loanAmount: montoPrestamo,
        payedAmount: montoAbonado,
        minimumPayedAmount: pagoMinimo,
        interestRate: tasaInteres,
        balance: balance,
        color: color,
      };
      axiosInstance({
        method: "post",
        url: urlPath,
        data: params,
      })
        .then(() => setStep(1))
        .catch(() => {
          toast.error("Error al crear la cuenta", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        })
        .finally(() => {
          setIsLoading(false);
          // form.resetFields();
          fetchAccounts();
          fetchLoans();
          fetchCreditCards();
        });
    } catch (errorInfo) {
      console.error("Validate Failed!");
      console.log("ErrorInfo: ", errorInfo);
      return;
    }
  };

  const handleClosemodal = () => {
    form.resetFields();
    setStep(0);
    toggleNewAccount();
  };

  const handleChange = (index) => {
    const account = accountTypes[index];
    console.log("Account: ", account);
    setTypeAccount(account.type);
  };

  return (
    <>
      <Modal
        title="Nueva cuenta"
        open={isModalOpen}
        onCancel={handleClosemodal}
        // footer={
        //   step === 0
        //     ? [
        //         <Popconfirm
        //           title="Desea cancelar?"
        //           onConfirm={handleClosemodal}
        //           cancelText="No"
        //           okText="Sí"
        //           okButtonProps={{ className: "bg-primary text-white" }}
        //         >
        //           <Button>Cancelar</Button>
        //         </Popconfirm>,
        //         <Popconfirm
        //           title="Desea crear la cuenta?"
        //           onConfirm={onOk}
        //           cancelText="No"
        //           okText="Sí"
        //           okButtonProps={{ className: "bg-primary text-white" }}
        //         >
        //           <Button className="bg-primary text-white">Crear</Button>
        //         </Popconfirm>,
        //       ]
        //     : [
        //         <Button
        //           onClick={() => {
        //             setStep(0);
        //           }}
        //         >
        //           Crear otra
        //         </Button>,
        //         <Button
        //           className="bg-primary text-white"
        //           onClick={handleClosemodal}
        //         >
        //           Continuar
        //         </Button>,
        //       ]
        // }
        footer={
          step === 0
            ? [
                <Button
                  key="back"
                  onClick={handleClosemodal}
                >
                  Cancelar
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={onOk}
                  className="bg-primary text-white"
                >
                  Crear
                </Button>,
              ]
            : [
                <Button
                  key="back"
                  onClick={() => {
                    setStep(0);
                  }}
                  className="bg-primary text-white"
                >
                  Crear otra
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleClosemodal}
                  className="bg-primary text-white"
                >
                  Continuar
                </Button>,
              ]
        }
      >
        {step === 0 && (
          <Form form={form} component={false}>
            <div className="flex flex-col gap-2">
              <label>Nombre de cuenta</label>
              <Form.Item
                className="mb-2"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="Ingrese el nombre de la cuenta"
                />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              <label>Tipo de cuenta</label>
              <Form.Item
                className="mb-2"
                name="type"
                rules={[
                  { required: true, message: "Este campo es obligatorio!" },
                ]}
              >
                <Select
                  placeholder="Seleccione un tipo de cuenta"
                  onChange={handleChange}
                >
                  {accountTypeOptions}
                </Select>
              </Form.Item>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                typeAccount === "tdc" ? "block" : "hidden"
              }`}
            >
              <label>Límite de tarjeta de crédito</label>
              <Form.Item
                className="mb-2"
                name="limiteTdc"
                rules={[
                  {
                    required: isCreditCard,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa el límite de tu tarjeta de crédito"
                  min={0}
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                typeAccount === "loan" ? "block" : "hidden"
              }`}
            >
              <label>Monto del préstamo</label>
              <Form.Item
                className="mb-2"
                name="montoPrestamo"
                rules={[
                  {
                    required: typeAccount === "loan",
                    message: "El monto del préstamo es obligatorio!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa el monto del préstamo"
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                typeAccount === "loan" ? "block" : "hidden"
              }`}
            >
              <label>Saldo adeudado</label>
              <Form.Item
                className="mb-2"
                name="montoAbonado"
                rules={[
                  {
                    required: typeAccount === "loan",
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa el monto abonado a la deuda"
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                typeAccount === "loan" || typeAccount === "tdc"
                  ? "block"
                  : "hidden"
              }`}
            >
              <label>Pago minimo</label>
              <Form.Item
                className="mb-2"
                name="pagoMinimo"
                rules={[
                  {
                    required: typeAccount === "loan" || typeAccount === "tdc",
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa la tasa de interés de tu tarjeta de crédito"
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              {typeAccount === "loan" && (
                <label>Fecha de vencimiento de la deuda</label>
              )}
              {typeAccount === "tdc" && (
                <label>Fecha de corte de la tarjeta</label>
              )}
              <Form.Item
                className="mb-2"
                name="expirationDate"
                rules={[
                  {
                    required: typeAccount === "loan" || typeAccount === "tdc",
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <DatePicker
                  type="number"
                  placeholder="Ingresa la fecha de vencimiento de la deuda"
                  className="w-full"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                typeAccount === "tdc" || typeAccount === "loan"
                  ? "block"
                  : "hidden"
              }`}
            >
              <label>Tasa de interés</label>
              <Form.Item
                className="mb-2"
                name="tasaInteres"
                rules={[
                  {
                    required: false,
                    message: "Este campo es obligatorio!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || (value >= 0 && value <= 100)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("El valor debe estar entre 0 y 100!")
                      );
                    },
                  }),
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa la tasa de interés de tu tarjeta de crédito"
                  min={0}
                  max={100}
                  addonAfter={"%"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div
              className={`flex flex-col gap-2 ${
                typeAccount === "tdc" || typeAccount === "account"
                  ? "block"
                  : "hidden"
              }`}
            >
              <label>Balance</label>

              <Form.Item
                className="mb-2"
                name="balance"
                rules={[
                  {
                    required: typeAccount === "account",
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Ingresa el balance de la cuenta"
                  min={0}
                  addonAfter={"$"}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"
                  precision={2}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              <label>Color de cuenta</label>
              <Form.Item
                className="mb-2"
                name="color"
                rules={[
                  { required: true, message: "Este campo es obligatorio!" },
                ]}
              >
                <Select
                  placeholder="Seleccione un color para la cuenta"
                  className="w-full"
                >
                  {colorOptions}
                </Select>
              </Form.Item>
            </div>
          </Form>
        )}
        {step === 1 && (
          <div className="container">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-row w-full justify-center">
                <i
                  className="bi bi-patch-check-fill"
                  style={{
                    color: "#198754",
                    fontSize: "68px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </div>
              <div className="flex flex-row w-full justify-center">
                <h4>La cuenta se ha creado con éxito</h4>
              </div>
              <div className="flex flex-row w-full justify-center text-center">
                Ahora puedes conectar una cuenta bancaria, reconciliar tu cuenta
                o añadir transacciones nuevas.
              </div>
            </div>
          </div>
        )}
      </Modal>
      <CustomModalLoader isOpen={isLoading} />
      <ToastContainer />
    </>
  );
};

export default AddAccountModal;
