import "../css/general.css";
import "../css/webflow.css";

function Header() {
  return (
    <header role="banner" className="navbar w-nav">
      <div className="navbarcontainer w-container">
        <div className="navbar-content">
          <div className="navbar-left">
            <div className="navbar-brand">
              <a
                href="index.html"
                aria-current="page"
                className="link-block w-inline-block w--current"
              >
                <img
                  src={require("../images/logo.svg").default}
                  loading="lazy"
                  width="168"
                  alt="brand logo"
                />
              </a>
            </div>
            <div className="navbar-menu">
              <a href="#beneficios" className="navbar-link w-nav-link">
                <div className="text">Beneficios</div>
              </a>
              <a href="#funciona" className="navbar-link w-nav-link">
                <div className="text">Como funciona</div>
              </a>
              <a href="#clientes" className="navbar-link w-nav-link">
                <div className="text">Clientes</div>
              </a>
            </div>
          </div>
          <nav role="navigation" className="navbar-right w-nav-menu">
            <a href="/auth/login" className="navbar-link w-nav-link">
              <div className="text-2">Iniciar sesión</div>
            </a>
            <a href="/auth/login" className="button menuoverlay w-nav-link">
              <div className="text-3">Comenzar gratis</div>
            </a>
          </nav>
          <div className="navbar-brand mobile">
            <a
              href="/auth/login"
              aria-current="page"
              className="link-block w-inline-block w--current"
            >
              <img
                src={require("../images/logo.svg").default}
                loading="lazy"
                width="168"
                alt="brand logo"
              />
            </a>
          </div>
        </div>
        <div className="menu-button w-nav-button">
          <div className="icon w-icon-nav-menu"></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
