import React, { useState } from "react";
import Header from "../../components/dashboard/categories/Header";
import Body from "../../components/dashboard/categories/Body";
import { getSessionStorageItem } from "../../utils/service/localStorageService";

const Categories = () => {
  const { id } = JSON.parse(getSessionStorageItem("user"));
  const [subcategoriesCount, setSubcategoriesCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);

  return (
    <div className="p-4 account-container">
      <Header groupCount={groupCount} subcategoriesCount={subcategoriesCount} />
      <div className="flex flex-row accounts-body">
        <Body
          userId={id}
          setGroupsCount={setGroupCount}
          setSubcategoriesCount={setSubcategoriesCount}
        />
      </div>
    </div>
  );
};

export default Categories;
