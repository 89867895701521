import { Button, DatePicker, Form, Input, InputNumber, Popconfirm } from "antd";
import GraphicsLoan from "./GraphicsLoan";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSessionStorageItem } from "../../../utils/service/localStorageService";
import axiosInstance from "../../../utils/axiosIntance";
import dayjs from "dayjs";
import { Radio } from "antd/lib";
import { data } from "autoprefixer";

const LoanTool = (loanData) => {
  const [form] = Form.useForm();
  const [formSimulate] = Form.useForm();

  const { type, id } = useParams();
  const { id: userId } = JSON.parse(getSessionStorageItem("user"));

  const [dataLoan, setDataLoan] = useState(null);
  const [simulateData, setSimulateData] = useState(null);
  const [graphicsData, setGraphicsData] = useState([]);
  const [mockData, setMockData] = useState([]);
  const [checkYear, setCheckYear] = useState(true);
  const [checkPay, setCheckPay] = useState(false);
  const [checkSimulate, setCheckSimulate] = useState("year");
  const [messageSimulate, setMessageSimulate] = useState("");
  const [ammounPay, setAmmounPay] = useState(0);
  const [lastYearPayed, setLastYearPayed] = useState(0);

  const fetchLoanData = useCallback(() => {
    axiosInstance({
      url: `/${type}/${userId}/${id}`,
      method: "GET",
    })
      .then(({ data }) => {
        console.log("data del fetch", data);
        setDataLoan(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, type, userId]);

  const generateRealData = useCallback(() => {
    let realData = [];
    let value =
      dataLoan.loanAmount + (dataLoan.loanAmount * dataLoan.interestRate) / 100;
    for (let i = actualYear; i < lastLoanYear; i++) {
      if (i === actualYear) {
        realData.push({
          year: i,
          value: value,
          category: "deuda real",
        });
      }
      value -= dataLoan.minimumPayedAmount * 12;
      realData.push({
        year: i,
        value: value,
        category: "deuda real",
      });
    }
    return realData;
  }, [dataLoan]);

  const generateMockData = (simulated) => {
    let mockData = [];
    let value = 0;

    if (checkSimulate === "pay" && !Number.isNaN(simulated.pay)) {
      console.log("if pay");
      value =
        dataLoan.loanAmount +
        (dataLoan.loanAmount * dataLoan.interestRate) / 100;

      console.log("value", value);

      for (let i = actualYear; i < lastLoanYear; i++) {
        if (i === actualYear) {
          mockData.push({
            year: i,
            value: value,
            category: "proyeccion de deuda",
          });
        }
        value -= simulated.pay * 12;
        mockData.push({
          year: i,
          value: value,
          category: "proyeccion de deuda",
        });

        if (value < 0) {
          setLastYearPayed(i);
          const diff = lastLoanYear - actualYear;
          const interestSaved =
            diff * dataLoan.interestRate * dataLoan.loanAmount;
          setMessageSimulate("");
          // setMessageSimulate(
          //   `Se han ahorrado $${interestSaved} en intereses y se ha pagaria la deuda en ${diff} años mas temprano.`
          // );
          break;
        }
      }
    }

    if (checkSimulate === "year" && !Number.isNaN(simulated.year)) {
      debugger;
      value =
        dataLoan.loanAmount +
        (dataLoan.loanAmount * dataLoan.interestRate) / 100;

      console.log("Simulate By Year");

      for (let i = actualYear; i < simulated.year; i++) {
        if (i === actualYear) {
          mockData.push({
            year: i,
            value: value,
            category: "proyeccion de deuda",
          });
        }
        value -= (dataLoan.loanAmount * dataLoan.interestRate) / 100;
        console.log("value", value);
        mockData.push({
          year: i,
          value: value,
          category: "proyeccion de deuda",
        });
      }
    }

    return mockData;
  };

  const handleSimulate = async () => {
    const { date, pay } = await formSimulate.getFieldValue();
    const simulated = {
      year: new Date(date).getFullYear(),
      pay: pay,
    };

    console.log("simulated", simulated);
    setSimulateData(simulated);

    const realdata = generateRealData();
    const mockData = generateMockData(simulated);

    console.log("mockData", mockData);

    setGraphicsData([...realdata, ...mockData]);
  };

  useEffect(() => {
    fetchLoanData();
  }, [fetchLoanData]);

  useEffect(() => {
    if (dataLoan) {
      const dataGenerated = generateRealData();
      setGraphicsData(dataGenerated);
      console.log("real data", dataGenerated);
    }
  }, [dataLoan, generateRealData]);

  const formatter = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const formatterPercentaje = (value) => {
    return Number(value).toFixed(2).replace(".", ",");
  };

  const actualYear = new Date().getFullYear();
  const lastLoanYear = new Date(dataLoan?.expirationDate).getFullYear();
  console.log("graphicsData", graphicsData);

  return (
    <>
      <div className="py-8 px-10">
        <h1 className="flex flex-row text-2xl font-bold uppercase">
          {dataLoan?.name}
        </h1>
        <div className="w-full h-screen flex gap-20">
          <div className="w-1/5">
            <Form
              form={form}
              component={false}
              fields={[
                {
                  name: "montoDeuda",
                  value: dataLoan?.loanAmount,
                },
                {
                  name: "actualBalance",
                  value:
                    dataLoan?.loanAmount +
                    (dataLoan?.interestRate / 100) * dataLoan?.loanAmount -
                    dataLoan?.payedAmount,
                },
                {
                  name: "minimumPayedAmount",
                  value: dataLoan?.minimumPayedAmount,
                },
                {
                  name: "expirationDate",
                  value: dayjs(dataLoan?.expirationDate),
                },
                {
                  name: "interestRate",
                  value: dataLoan?.interestRate,
                },
              ]}
            >
              <div className="flex flex-col gap-2">
                {dataLoan?.type === "loan" && (
                  <label>Monto inicial de deuda</label>
                )}
                <Form.Item className="mb-2" name="montoDeuda">
                  <InputNumber
                    name="montoDeuda"
                    disabled={true}
                    formatter={formatter}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    addonAfter={"$"}
                    className="w-full"
                    placeholder="Ingrese el nombre de la cuenta"
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-2">
                <label>Saldo de la deuda</label>
                <Form.Item className="mb-2" name="actualBalance">
                  <InputNumber
                    disabled={true}
                    formatter={formatter}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    addonAfter={"$"}
                    name="actualBalance"
                    className="w-full"
                    placeholder="Ingrese el nombre de la cuenta"
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-2">
                <label>Pago minimo</label>
                <Form.Item className="mb-2" name="minimumPayedAmount">
                  <InputNumber
                    name="minimumPayedAmount"
                    disabled={true}
                    formatter={formatter}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    addonAfter={"$"}
                    className="w-full"
                    placeholder="Ingrese el nombre de la cuenta"
                  />
                </Form.Item>
              </div>

              {dataLoan?.type === "loan" && (
                <div className="flex flex-col gap-2">
                  <label>Vencimiento de la deuda</label>
                  <Form.Item className="mb-2" name="expirationDate">
                    <DatePicker
                      disabled={true}
                      name="expirationDate"
                      className="w-full"
                      placeholder="Ingrese el nombre de la cuenta"
                    />
                  </Form.Item>
                </div>
              )}

              <div className="flex flex-col gap-2">
                <label>Tasa de interes</label>
                <Form.Item className="mb-2" name="interestRate">
                  <InputNumber
                    disabled={true}
                    formatter={formatterPercentaje}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    addonAfter={"%"}
                    name="interestRate"
                    className="w-full"
                    placeholder="Ingrese el nombre de la cuenta"
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="w-4/5">
            {dataLoan !== null && dataLoan != undefined ? (
              <GraphicsLoan dataGraphics={graphicsData} />
            ) : null}
            <div className="w-full mt-16">
              <h2 className="text-xl">Simulador de pago de deuda</h2>
              <div className="flex  gap-2 ">
                <Form
                  form={formSimulate}
                  className="flex gap-12 mr-10"
                  fields={[
                    {
                      name: "pay",
                    },
                    {
                      name: "date",
                    },
                  ]}
                >
                  <div className="flex flex-col gap-2 ">
                    <label>Año a pagar</label>

                    <div className="flex items-center">
                      <Radio
                        value="date"
                        checked={checkYear}
                        onChange={async () => {
                          setCheckYear(!checkYear);
                          if (checkPay) {
                            setCheckSimulate("year");
                            setCheckPay(false);
                          }
                          await formSimulate.setFieldValue({
                            pay: null,
                          });
                          console.log(formSimulate.getFieldValue());
                        }}
                      />
                      <Form.Item className="mb-2 flex" name="date">
                        <DatePicker
                          name="date"
                          disabled={checkPay}
                          placeholder="Ingrese el año deseado a pagar las deuda"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 ">
                    <label>Pago mensual</label>

                    <div className="flex items-center">
                      <Radio
                        value="pay"
                        checked={checkPay}
                        onChange={async () => {
                          setCheckPay(!checkPay);
                          if (checkYear) {
                            setCheckSimulate("pay");
                            setCheckYear(false);
                          }
                          await formSimulate.setFieldValue({
                            year: undefined,
                          });
                          console.log(formSimulate.getFieldValue());
                        }}
                      />
                      <Form.Item
                        className="mb-2 flex flex-row gap-4"
                        name="pay"
                      >
                        <InputNumber
                          disabled={checkYear}
                          name="pay"
                          type="number"
                          addonAfter={"$"}
                          placeholder="Ingrese el monto deseado a pagar mensualmente"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Form>

                <Button
                  onClick={handleSimulate}
                  className="bg-primary text-white flex self-end mb-2"
                >
                  Simular
                </Button>
              </div>
            </div>
            <div className="text-green-500 font-roboto font-bold text-xl mt-8">
              {messageSimulate}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanTool;
