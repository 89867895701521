import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./DragDropZone.scss";

import * as XLSX from "xlsx";

import Papa from "papaparse";

/**
 * Declarind regex to handle different type of dates in file
 */
const regex_DDMMYYYY = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/; // DD/MM/YYYY
const regex_MMDDYYYY = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/; // MM/DD/YYYY
const regex_DdMmYyyy = /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-\d{4}$/; // DD-MM-YYYY
const regex_MmDdYyyy = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/; // MM-DD-YYYY
const regex_DDmmmYYYY =
  /^(0?[1-9]|[12][0-9]|3[01])-(jan|ene|feb|mar|abr|apr|may|jun|jul|ago|aug|sep|oct|nov|dic|dec)-\d{4}$/i; // DD mmm YYYY
const regex_DD_MMMM_YYYY =
  /^(0?[1-9]|[12][0-9]|3[01]) (january|enero|february|febrero|march|marzo|april|abril|may|mayo|june|junio|july|julio|august|agosto|september|septiembre|october|octubre|november|noviembre|december|diciembre) \d{4}$/i; // DD mmmmmm YYYY

/**
 * Arrays of months to get positions and parse it as date
 */
var months = {
  ene: 0,
  feb: 1,
  mar: 2,
  abr: 3,
  may: 4,
  jun: 5,
  jul: 6,
  ago: 7,
  sep: 8,
  oct: 9,
  nov: 10,
  dic: 11,
};

const acceptedFormats = [".xls", ".xlsx", ".csv"];

const formatFileToCsv = (getData, fileName) => {
  let indexHeaders;

  for (let index in getData) {
    const nonEmptyCell = getData[index].filter((r) => r !== "");
    if (nonEmptyCell.length > 4) {
      indexHeaders = index;
      break;
    }
  }

  const rows = getData.slice(indexHeaders);

  const headers = rows[0].map((header) => {
    return header.replace(/(['"])/g, "\\$1");
  });

  const indexFecha = headers
    .map((item, index) => (item.includes("Fecha") ? index : -1))
    .filter((index) => index !== -1)[0];

  // const indexFecha = headers.indexOf("Fecha");

  const dateFormatter = new Intl.DateTimeFormat("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  });

  const finalRows = rows
    .slice(1)
    .map((r) => {
      if (!r[indexFecha]) return;
      /**
       * Handle condition if date match with any regex
       * if handles dates format like "dd/mm/yyyy", "mm/dd/yyyy", "dd mmm yyyy" and "dd mmmmm yyyy"
       * else handles excel serial format
       */
      const unformattedDate = r[indexFecha];
      if (
        regex_DDMMYYYY.test(unformattedDate) ||
        regex_MMDDYYYY.test(unformattedDate) ||
        regex_DdMmYyyy.test(unformattedDate) ||
        regex_MmDdYyyy.test(unformattedDate) ||
        regex_DDmmmYYYY.test(unformattedDate) ||
        regex_DD_MMMM_YYYY.test(unformattedDate)
      ) {
        if (regex_DDMMYYYY.test(unformattedDate)) {
          const partes = unformattedDate.split("/");

          r[indexFecha] = dateFormatter.format(
            new Date(partes[2], partes[1] - 1, partes[0])
          );
        } else if (regex_DdMmYyyy.test(unformattedDate)) {
          const partes = unformattedDate.split("-");

          r[indexFecha] = dateFormatter.format(
            new Date(partes[2], partes[1] - 1, partes[0])
          );
        } else if (regex_MMDDYYYY.test(unformattedDate)) {
          const partes = unformattedDate.split("/");

          r[indexFecha] = dateFormatter.format(
            new Date(partes[2], partes[0] - 1, partes[1])
          );
        } else if (regex_MmDdYyyy.test(unformattedDate)) {
          const partes = unformattedDate.split("-");

          r[indexFecha] = dateFormatter.format(
            new Date(partes[2], partes[0] - 1, partes[1])
          );
        } else if (regex_DDmmmYYYY.test(unformattedDate)) {
          const partes = unformattedDate.split("-");
          const month = months[partes[1].toLowerCase()];

          r[indexFecha] = dateFormatter.format(
            new Date(partes[2], month, partes[0])
          );
        } else {
          r[indexFecha] = dateFormatter.format(new Date(unformattedDate));
        }
      } else {
        r[indexFecha] = dateFormatter.format(
          new Date((unformattedDate - 25569) * 86400 * 1000)
        );
      }

      return r;
    })
    .filter((r) => r);

  const toCsv = Papa.unparse({
    fields: headers,
    data: finalRows,
  });

  const blob = new Blob([toCsv], { type: "text/csv;charset=utf-8;" });

  return { headers, file: new File([blob], fileName, { type: blob.type }) };
};

const DragDropZone = ({ file, setFile, setFileHeaders }) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = async function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files.length > 1) {
      toast.error("Solo puedes importar un archivo", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (
      !acceptedFormats.some((format) =>
        e.dataTransfer.files[0].name
          .toLowerCase()
          .endsWith(format.toLowerCase())
      )
    ) {
      toast.error(
        "El archivo que desea importar no cumple con el formato requerido",
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        }
      );
    }

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const addFile = e.dataTransfer.files[0];
      let csvFile;

      if (
        addFile.name.toLowerCase().endsWith(".xlsx") ||
        addFile.name.toLowerCase().endsWith(".xls") ||
        addFile.name.toLowerCase().endsWith(".csv")
      ) {
        const data = await addFile.arrayBuffer();
        const wb = XLSX.read(data);

        const getData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          header: 1,
          blankrows: false,
        });

        const { headers, file } = formatFileToCsv(getData, addFile.name);

        csvFile = file;
        setFileHeaders(headers);
        setFile(csvFile);
      }
    }
  };

  const handleChange = async function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const addFile = e.target.files[0];
      let csvFile;

      if (
        addFile.name.toLowerCase().endsWith(".xlsx") ||
        addFile.name.toLowerCase().endsWith(".xls") ||
        addFile.name.toLowerCase().endsWith(".csv")
      ) {
        const data = await addFile.arrayBuffer();
        const wb = XLSX.read(data);

        const getData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          header: 1,
          blankrows: false,
          dateNF: "yyyy-mm-dd",
        });

        const { headers, file } = formatFileToCsv(getData, addFile.name);

        csvFile = file;
        setFileHeaders(headers);
        setFile(csvFile);
      }
    }
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      className="p-5"
    >
      <input
        type="file"
        id="input-file-upload"
        multiple={false}
        onChange={handleChange}
        accept=".csv, .xls, .xlsx"
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {file ? (
          <div className="flex flex-col justify-center items-center gap-3">
            <i className="text-4xl text-success bi bi-file-earmark-arrow-up"></i>
            <span>{file.name}</span>
          </div>
        ) : (
          <div>
            <div className="flex flex-col justify-center items-center gap-2 text-gray-600">
              <i className="text-4xl bi bi-file-earmark-arrow-up"></i>
              <span
                style={{
                  fontWeight: "510",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                Selecciona un archivo para importar
              </span>
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                o arrastra y suelta aquí
              </span>
            </div>

            <span
              className="text-gray-600"
              style={{
                fontWeight: "510",
                fontSize: "10px",
                lineHeight: "12px",
              }}
            >
              {"Archivos compatibles: “.csv” ; “.xls”; “.txt”; “.ofx”; “.qif”"}
            </span>
          </div>
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      <ToastContainer />
    </form>
  );
};

export default DragDropZone;
