function Goals() {
  return (
    <div className="goals">
      <div className="container-3">
        <div className="title-section-2">
          <div className="text-30">Que puedes lograr con OnMission</div>
          <div className="text-31">
            ¡No esperes más! Deja que OnMission sea tu mejor aliado
          </div>
        </div>
        <div className="columns-5 f2wf-columns">
          <div className="column-6">
            <div className="card-3">
              <div className="content-3">
                <div className="info">
                  <div className="frame-1000004416">
                    <img
                      src={require("../images/Vectors-Wrapper_22.svg").default}
                      loading="lazy"
                      width="24"
                      height="24"
                      alt=""
                      className="vectors-wrapper-18"
                    />
                  </div>
                  <div className="text-21">
                    Darle una vida mejor a tu familia
                  </div>
                </div>
              </div>
            </div>
            <div className="conexion"></div>
            <div className="card-3">
              <div className="content-3">
                <div className="info">
                  <div className="frame-1000004416">
                    <img
                      src={require("../images/Vectors-Wrapper_23.svg").default}
                      loading="lazy"
                      width="24"
                      height="24"
                      alt=""
                      className="vectors-wrapper-18"
                    />
                  </div>
                  <div className="text-21">Organiza tu vida y tus finanzas</div>
                </div>
              </div>
            </div>
            <div className="conexion"></div>
            <div className="card-3">
              <div className="content-3">
                <div className="info">
                  <div className="frame-1000004416">
                    <img
                      src={require("../images/Vectors-Wrapper_24.svg").default}
                      loading="lazy"
                      width="24"
                      height="24"
                      alt=""
                      className="vectors-wrapper-18"
                    />
                  </div>
                  <div className="text-21">
                    No sentirte agobiado por el dinero
                  </div>
                </div>
              </div>
            </div>
            <div className="conexion"></div>
            <div className="card-3">
              <div className="content-3">
                <div className="info">
                  <div className="frame-1000004416">
                    <img
                      src={require("../images/Vectors-Wrapper_25.svg").default}
                      loading="lazy"
                      width="24"
                      height="24"
                      alt=""
                      className="vectors-wrapper-18"
                    />
                  </div>
                  <div className="text-21">Cumplir tus metas</div>
                </div>
              </div>
            </div>
            <div className="conexion"></div>
            <div className="card-3">
              <div className="content-3">
                <div className="info">
                  <div className="frame-1000004416">
                    <img
                      src={require("../images/Vectors-Wrapper_26.svg").default}
                      loading="lazy"
                      width="24"
                      height="24"
                      alt=""
                      className="vectors-wrapper-18"
                    />
                  </div>
                  <div className="text-21">Preparar tu jubilación</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column-7"></div>
        </div>
        <div className="action-tray">
          <a href="#/auth/login" className="link-block-2 button w-inline-block">
            <div className="text-block">COMENZAR GRATIS</div>
          </a>
          <div className="text-31">¡Hazlo ahora y gratis!</div>
        </div>
      </div>
    </div>
  );
}

export default Goals;
