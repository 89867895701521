import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import "./index.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { App } from "./App";
import { ContextProvider } from "./context/ContextProvider";
import AppProvider from "./context/AppProvider";
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <>
    {/* <AuthProvider> */}
    <ContextProvider>
      <BrowserRouter>
        <AppProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </AppProvider>
      </BrowserRouter>
    </ContextProvider>
    {/* </AuthProvider> */}
  </>
);
