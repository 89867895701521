import { Button, Form, Modal, Select } from "antd";
import React from "react";
import PropTypes from 'prop-types';

const NewTransactionAccounModal = ({
  toggle,
  isOpen,
  accountOptions,
  saveNewTransaction,
}) => {
  /**
   * Form instance
   */
  const [form] = Form.useForm();

  const handleSave = async () => {
    try {
      const { account } = await form.validateFields();

      await saveNewTransaction(account);

      form.resetFields();
    } catch (error) {}
  };

  return (
    <Modal
      title="Seleccione una cuenta para la nueva transacción"
      open={isOpen}
      onCancel={toggle}
      footer={[
        <Button key={1} onClick={toggle}>No</Button>,
        <Button key={2} onClick={handleSave} className="bg-primary text-white">
          Sí
        </Button>,
      ]}
    >
      <Form form={form} component={false}>
        <div className="flex flex-col py-4">
          <Form.Item
            className="mb-2"
            name="account"
            rules={[
              { required: true, message: "Este campo es obligatorio!" },
            ]}
          >
            <Select placeholder="Seleccione una cuenta">
              {accountOptions.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

NewTransactionAccounModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  accountOptions: PropTypes.array.isRequired,
  saveNewTransaction: PropTypes.func.isRequired,
};

export default NewTransactionAccounModal;
