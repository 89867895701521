import React from "react";
import { Col, Row } from "reactstrap";

const Header = ({ groupCount, subcategoriesCount }) => {
  return (
    <Row className="accounts-header">
      <Col>
        <Row className="mb-3">
          <h4 style={{ margin: "0px" }} className="accounts-title">
            Categorías
          </h4>
        </Row>
        <div className="flex flex-row gap-5">
          <div className="flex flex-row gap-2 text-gray-500">
            <i className="bi bi-pin-angle-fill"></i>
            <span>{`${subcategoriesCount} categorías`}</span>
          </div>
          <div className="flex flex-row gap-2 text-gray-500">
            <i className="bi bi-stack"></i>
            <span>{`${groupCount} grupos`}</span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
