function Clients() {
  return (
    <div id="clientes" className="testimonials">
      <div className="container-4">
        <div className="small-container-3">
          <div className="_2-columns f2wf-columns">
            <div className="column-8">
              <div className="text-19">
                <div className="text-32">Algo que debes probar</div>
                <div className="text-33">Unete a nuestros usuarios</div>
                <div className="text-34">
                  Se parte de una comunidad que va en crecimiento, llena de
                  pensadores, motivadores, independientes, persistentes y
                  ambiciosos - estas en la plataforma indicada.
                </div>
              </div>
            </div>
            <div className="column-9">
              <a
                href="/auth/login"
                className="link-block-2 button clients w-inline-block"
              >
                <div className="text-block clients">ORGANIZA TUS FINANZAS</div>
              </a>
            </div>
          </div>
        </div>
        <div className="slideshow">
          <div className="card-4">
            <div className="content-4">
              <div className="icon-2">
                <img
                  src={require("../images/numeric-1-custom.png").default}
                  loading="lazy"
                  width="24"
                  height="24"
                  alt=""
                  className="vectors-wrapper-18"
                />
              </div>
              <div className="text-35">
                “No tenia tiempo para organizar mis finanzas, gastaba más de lo que ingresaba. 
                Con OnMission planificar mis finanzas y crear objetivos de gastos”
              </div>
            </div>
            <div className="author">
              <div className="text-36">Directora Financiera</div>
              <div className="text-37">Mariana Herrera</div>
              <div className="text-38">40 Años</div>
            </div>
          </div>
          <div className="card-4">
            <div className="content-4">
              <div className="icon-2">
                <img
                  src={require("../images/numeric-2-custom.png").default}
                  loading="lazy"
                  width="24"
                  height="24"
                  alt=""
                  className="vectors-wrapper-18"
                />
              </div>
              <div className="text-35">
                “Al ser independiente tengo que llevar un mejor control de mis
                ingresos y gastos, OnMission me permitió consolidar mis finanzas
                de una forma rápida y fácil”
              </div>
            </div>
            <div className="author">
              <div className="text-36">Independiente</div>
              <div className="text-37">Aida Manzanares</div>
              <div className="text-38">68 Años</div>
            </div>
          </div>
          <div className="card-4">
            <div className="content-4">
              <div className="icon-2">
                <img
                  src={require("../images/numeric-3-custom.png").default}
                  loading="lazy"
                  width="24"
                  height="24"
                  alt=""
                  className="vectors-wrapper-18"
                />
              </div>
              <div className="text-35">
              “Gracias OnMissio!, me ha ayudado a tener controlados mis gastos. 
              Ahora se en que invierto mi dinero y manejar un presupuesto”
              </div>
            </div>
            <div className="author">
              <div className="text-36">Entrenador Personal</div>
              <div className="text-37">Wilder Alvarez</div>
              <div className="text-38">32 Años</div>
            </div>
          </div>
          <div className="card-4">
            <div className="content-4">
              <div className="icon-2">
                <img
                  src={require("../images/numeric-4-custom.png").default}
                  loading="lazy"
                  width="24"
                  height="24"
                  alt=""
                  className="vectors-wrapper-18"
                />
              </div>
              <div className="text-35">
              “Fácil, intuitivo, OnMission te permite controlar tus finanzas 
              y planificar metas para lograr tus objetivos financieros.”
              </div>
            </div>
            <div className="author">
              <div className="text-36">Ingeniero Industrial</div>
              <div className="text-37">Oldemar Castillo</div>
              <div className="text-38">39 Años</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
