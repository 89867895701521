import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { dollarUS } from "../../../utils/dollarFormat";
import { Table } from "antd";
import CustomLoader from "../../dashboard/loader/CustomLoader";

const PreviewFinalFile = ({ headers, file, assignedData }) => {
  const [dataPreview, setDataPreview] = useState([]);
  const [hasTwoColumns, setHasTwoColumns] = useState(false);

  useEffect(() => {
    const reader = new FileReader();

    setHasTwoColumns(
      !headers.some((obj) =>
        Object.keys(obj).some((key) => key.includes("ammount"))
      )
    );

    reader.readAsText(file);

    reader.onload = () => {
      const { data } = Papa.parse(reader.result, { header: true });

      let dateHeader = "";
      let payeeHeader = "";
      let descriptionHeader = "";
      let categoryHeader = "";
      let debitHeader = "";
      let creditHeader = "";
      let amountHeader = "";

      assignedData.forEach((ad) => {
        const [k, v] = Object.entries(ad)[0];

        switch (k) {
          case "date":
            dateHeader = v;
            break;
          case "payee":
            payeeHeader = v;
            break;
          case "description":
            descriptionHeader = v;
            break;
          case "category":
            categoryHeader = v;
            break;
          case "debit":
            debitHeader = v;
            break;
          case "credit":
            creditHeader = v;
            break;
          case "ammount":
            amountHeader = v;
            break;
          default:
            break;
        }
      });

      setDataPreview(
        data.slice(0, 6).map((row) => {
          return {
            date: row[dateHeader],
            payee: payeeHeader ? row[payeeHeader] : "-",
            description: row[descriptionHeader],
            category: categoryHeader ? row[categoryHeader] : "-",
            debit: hasTwoColumns ? row[debitHeader] : "-",
            credit: hasTwoColumns ? row[creditHeader] : "-",
            amount: hasTwoColumns ? "-" : row[amountHeader],
          };
        })
      );
    };
  }, [file, assignedData, hasTwoColumns, headers]);

  const tableHeaders = hasTwoColumns
    ? [
        { key: "date", label: "Fecha" },
        { key: "payee", label: "Proveedor" },
        { key: "description", label: "Descripción" },
        { key: "category", label: "Categoría" },
        { key: "debit", label: "Débito" },
        { key: "credit", label: "Crédito" },
      ]
    : [
        { key: "date", label: "Fecha" },
        { key: "payee", label: "Proveedor" },
        { key: "description", label: "Descripción" },
        { key: "category", label: "Categoría" },
        { key: "amount", label: "Monto" },
      ];

  const columns = tableHeaders.map((header) => {
    return {
      title: header.label,
      dataIndex: header.key,
      key: header.key,
      render: (text) => {
        if (
          header.key === "credit" ||
          header.key === "debit" ||
          header.key === "amount"
        ) {
          return dollarUS.format(text);
        }
        return text;
      },
    };
  });

  if (dataPreview.length === 0) return <CustomLoader />;

  return (
    <div className="flex flex-row justify-between items-start h-full w-full gap-2 p-6 bg-white border-2 border-gray-200 rounded-md">
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row justify-center items-center h-full border-2 border-gray-200 rounded-md bg-white p-6 w-full">
          <div className="flex flex-col w-full h-full gap-2">
            <span
              style={{
                fontWeight: "510",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#212529",
              }}
            >
              Previsualización de data a importar:
            </span>
            <div className="w-full">
              <Table
                columns={columns}
                dataSource={dataPreview}
                pagination={false}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewFinalFile;
