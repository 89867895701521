import React from "react";

import "./css/normalize.css";
import "./css/webflow.css";
import "./css/general.css";
import Header from "./components/Header";
import HeroStack from "./components/Hero-Stack";
import Metrics from "./components/Metrics";
import Banner2 from "./components/Banner2";
import Beneficios from "./components/Beneficios";
import Banner3 from "./components/Banner3";
import Steps from "./components/Steps";
import Goals from "./components/Goals";
import Clients from "./components/Clients";
import Footer from "./components/Footer";

const Landing = () => {
  return (
    <>
      <Header />
      <HeroStack />
      <Metrics />
      <Banner2 />
      <Beneficios />
      <Banner3 />
      <Steps />
      <Goals />
      <Clients />
      <Footer />
    </>
  );
};

export default Landing;
