import { Outlet, Navigate } from "react-router-dom";

import { LOGIN } from "../routes";
import { getSessionStorageItem } from "../utils/service/localStorageService";

export const ProtectRoutes = () => {
  /* const { cookies } = useAuth(); */
  const token = getSessionStorageItem("token");

  return token ? <Outlet /> : <Navigate to={LOGIN} />;
};
