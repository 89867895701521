import React, { useState } from "react";

import AccountsHeader from "../../components/dashboard/accounts/Header";

import { useParams } from "react-router-dom";

import { getSessionStorageItem } from "../../utils/service/localStorageService";
import { Tabs } from "antd";
import Detailed from "../../components/dashboard/accounts/body/Detailed";

const Accounts = () => {
  /* const { cookies } = useAuth(); */
  const { email, id: userId } = JSON.parse(getSessionStorageItem("user"));
  const user = { email: email, userId: userId };

  const { id: account } = useParams();

  const [lastUpdate, setLastUpdate] = useState(null);

  const [totalInflow, setTotalInflow] = useState(0);
  const [totalOutflow, setTotalOutflow] = useState(0);
  const [totalSelectedBalance, setTotalSelectedBalance] = useState(0);

  const [totalSelectedRows, setTotalSelectedRows] = useState(0);

  const [selectedAccount, setSelectedAccount] = useState(null);

  const totalStates = {
    setTotalInflow,
    setTotalOutflow,
    setTotalSelectedBalance,
  };

  return (
    <div className="p-4 account-container">
      <AccountsHeader
        lastUpdate={lastUpdate}
        totalInflow={totalInflow}
        totalOutflow={totalOutflow}
        totalSelectedBalance={totalSelectedBalance}
        totalSelectedRows={totalSelectedRows}
        selectedAccount={selectedAccount}
      />
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Transacciones",
            key: "1",
            children: (
              <Detailed
                account={account}
                user={user}
                setLastUpdate={setLastUpdate}
                totalStates={totalStates}
                setTotalSelectedRows={setTotalSelectedRows}
                setSelectedAccount={setSelectedAccount}
              />
            ),
          },
          // {
          //   label: "Reporte gráfico",
          //   key: "2",
          //   children: <Graphical user={user} accountId={account} />,
          // },
        ]}
      />
    </div>
  );
};

export default Accounts;
