import React from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../routes";

const TokenSent = ({ userEmail, setUserEmail }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full gap-7">
      <div className="flex flex-row">
        <i
          className="text-primary text-5xl font-roboto bi bi-chevron-left cursor-pointer"
          onClick={() => {
            setUserEmail("");
            navigate(LOGIN);
          }}
        ></i>
      </div>
      <div className="flex flex-row justify-center">
        <i className="text-success text-5xl font-roboto bi bi-patch-check-fill"></i>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h1 className="text-primary font-roboto">
            Confirma tu correo electrónico
          </h1>
          <h6 className="text-gray-600 font-roboto mt-[7px] mb-0">
            Hemos enviado un correo con un link para confirmar su registro:
          </h6>
        </div>
      </div>
      <div className="flex flex-row h-24">
        <span className="flex flex-col w-full justify-center content-center text-gray-700">
          {userEmail}
        </span>
      </div>
      <div className="flex flex-row">
        <h6 className="text-gray-600 font-roboto mt-[7px] mb-0">
          Por favor verifica tu bandeja de entrada y haz click en el botón de
          confimar para finalizar el proceso de registro
        </h6>
      </div>
      <div className="flex flex-row justify-center">
        <button className="border-0 text-primary" onClick={() => {}}>
          {"Volver a enviar"}
        </button>
      </div>
    </div>
  );
};

export default TokenSent;
