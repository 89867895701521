import React, { useEffect, useState, useCallback } from "react";
import { Input, Row } from "reactstrap";
import CustomLoader from "../loader/CustomLoader";
import axiosInstance from "../../../utils/axiosIntance";
import AddEditSubcategory from "./AddEditSubcategory";
import AddEditGroup from "./AddEditGroup";

import InfiniteScroll from "react-infinite-scroller";
import { Button, Form, Select, Space, Table, Tooltip, Input as AntdInput } from "antd";
import { ToastContainer, toast } from "react-toastify";
import CustomModalLoader from "../../customModalLoader/CustomModalLoader";
import DeleteCategoriesWarning from "./DeleteCategoriesWarning";

import PropTypes from 'prop-types';

const Body = ({ userId, setGroupsCount, setSubcategoriesCount }) => {
  /**
   * States for infinite loader
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [subcategories, setSubcategories] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  /* State for modals */
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);

  /* State for parsed category groups to select */
  const [groupOptions, setGroupOptions] = useState([]);

  /**
   * State to editing row
   */
  const [editingRow, setEditingRow] = useState(null);

  /**
   * Form instance
   */
  const [form] = Form.useForm();

  const [loadingEdit, setLoadingEdit] = useState(false);

  /**
   * Loading data
   */
  const [isLoading, setIsLoading] = useState(false);

  const [newSubcategoryId, setNewSubcategoryId] = useState(null);

  /* Functions that toggle the modals */
  const toggleCategoryModal = () =>
    setIsOpenCategoryModal(!isOpenCategoryModal);
  const toggleGroupModal = () => setIsOpenGroupModal(!isOpenGroupModal);

  /**
   * Delete categories handler
   */
  const [isOpenDeleteWarning, setIsOpenDeleteWarning] = useState(false);
  const toggleDeleteWarning = () =>
    setIsOpenDeleteWarning(!isOpenDeleteWarning);

  /**
   * Handle for search
   */
  const [search, setSearch] = useState("");

  const refetchGroups = useCallback(() => {
    // Fetching categories
    axiosInstance({
      method: "get",
      url: `/category/${userId}`,
    })
      .then(({ data }) => {
        setGroupsCount(data.length);
        setGroupOptions(data);
      })
      .catch((err) => console.log(err));
  }, [userId, setGroupsCount, setGroupOptions]);

  /**
   * Fetching subcategories paginated
   */
  const fetchSubcategories = (pageParam) => {
    setIsLoading(true);

    axiosInstance({
      url: `/subcategory/${userId}/${pageParam}/20`,
      method: "GET",
    })
      .then(({ data }) => {
        const { content, number, totalPages, totalElements } = data;
        setCurrentPage(pageParam);

        const newSubcategories = Object.values(content || {})?.map((subc) => ({
          key: subc.id,
          ...subc,
        }));

        let merge = [...subcategories, ...newSubcategories];
        //remove duplicates
        merge = merge.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );

        setSubcategories([...merge]);

        setSubcategoriesCount(totalElements);

        setHasMore(number + 1 < totalPages);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    refetchGroups();

    setIsLoading(true);

    axiosInstance({
      url: `/subcategory/${userId}/1/20`,
      method: "GET",
    })
      .then(({ data }) => {
        const { content, number, totalPages, totalElements } = data;

        const newSubcategories = Object.values(content || {})?.map((subc) => ({
          key: subc.id,
          ...subc,
        }));

        setSubcategories([...newSubcategories]);

        setSubcategoriesCount(totalElements);

        setHasMore(number + 1 < totalPages);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [refetchGroups, userId, setSubcategoriesCount]);

  const rowSelection = {
    onChange: (selectedRowKeys, selected) => {
      setSelectedRows(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.subcategoryType === "DEFAULT",
    }),
  };

  const handleUpdateSubcategory = async (row) => {
    try {
      setLoadingEdit(true);

      const { group, name } = await form.validateFields();

      const params = {
        id: row.id,
        name: name,
        description: "",
        categoryId: group,
        userId,
        textColor: row.textColor,
        bgColor: row.bgColor,
      };

      axiosInstance({
        url: `/subcategory/update`,
        method: "PUT",
        data: params,
      })
        .then((res) =>{
          const index = subcategories.findIndex((item) => item.id === row.id);
          const newArray = [...subcategories];
          newArray[index] = { ...newArray[index], name: name, category: { id: group } };
          setSubcategories(newArray);
        })
        .catch((err) =>
          toast.error("Error al crear la cuenta", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          })
        )
        .finally(() => {
          setEditingRow(null);
          // fetchSubcategories(1);
          setLoadingEdit(false);
        });
    } catch (err) {}
  };

  const columns = [
    {
      title: "Categoría",
      dataIndex: "subcategory",
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      render: (text, row) =>
        editingRow === row.id ? (
          <Form.Item
            className="mb-0"
            name="name"
            rules={[
              {
                required: true,
                message: "La descripción está vacía!",
              },
            ]}
            initialValue={row.name}
          >
            <Input />
          </Form.Item>
        ) : (
          <div className="flex flex-row h-full items-center">
            <div
              className="text-center content-center text-xs rounded-full w-3 h-3"
              style={{
                backgroundColor: row.textColor,
                marginRight: "5px",
              }}
            ></div>
            <span>{row.name}</span>
          </div>
        ),
    },
    {
      title: "Grupo",
      dataIndex: "group",
      sorter: (a, b) => a.category.name.localeCompare(b.category.name),
      showSorterTooltip: false,
      render: (text, row) =>
        editingRow === row.id ? (
          <div className="flex flex-col gap-2">
            <Form.Item
              className="mb-0"
              name="group"
              initialValue={row.category.id}
            >
              <Select
                placeholder="Seleccione un grupo"
              >
                {groupOptions.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        ) : (
          <Tooltip placement="topLeft" title={row.category.name}>
            {row.category.name}
          </Tooltip>
        ),
    },
    {
      width: "8%",
      render: (text, row) =>
        editingRow === row.id ? (
          <div className="flex flex-row gap-2">
            <div className="flex flex-col">
              <Button 
                className="bg-primary text-white text-xs w-full"
                onClick={() => handleUpdateSubcategory(row)}
              >
                <i className="bi bi-save text-white"></i>
              </Button>
            </div>

            <div className="flex flex-col">
              <Button
                className="bg-white text-primary text-xs border border-primary"
                onClick={() => setEditingRow(null)}
              >
                <i className="bi bi-x-circle text-primary"></i>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row">
            <Button
              className="bg-primary text-white text-xs w-full"
              onClick={() => {
                form.resetFields();
                setEditingRow(row.id);
              }}
              disabled={row.subcategoryType === "DEFAULT"}
            >
              <i className="bi bi-pencil-square text-white"></i>
            </Button>
          </div>
        ),
    },
  ];

  const handleDeleteSubcategries = () => {
    setIsLoading(true);

    axiosInstance({
      url: "/subcategory/delete",
      method: "DELETE",
      data: selectedRows.map((row) => row.id),
    })
      .then(async ({ data }) => {
        toast.success("Se ha/n eliminado la/s categoría/s", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });

        setIsLoading(true);

        const numberOfRecords = currentPage * 20;

        axiosInstance({
          url: `/subcategory/${userId}/1/${numberOfRecords}`,
          method: "GET",
        })
          .then(({ data }) => {
            const { content, number, totalPages, totalElements } = data;

            const newSubcategories = Object.values(content || {})?.map(
              (subc) => ({
                key: subc.id,
                ...subc,
              })
            );

            setSubcategories([...newSubcategories]);

            setSubcategoriesCount(totalElements);

            setHasMore(number + 1 < totalPages);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setIsLoading(false);
            setIsOpenDeleteWarning(false);
          });
      })
      .catch((err) => {
        toast.error("Error al eliminar la/s categoría/s", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInfiniteScroll = () => {
    fetchSubcategories(currentPage + 1);
  };

  const handleCreateGroup = async (name) => {
    try {
      const params = {
        name,
        createdAt: new Date(),
        userId,
      };

      setIsLoading(true);

      axiosInstance({
        url: "/category",
        method: "POST",
        data: params,
      })
        .then(() => {
          refetchGroups();
        })
        .catch(() => {
          toast.error("Ocurrió un error al crear grupo", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateCategory = (params) => {
    setIsLoading(true);

    axiosInstance({
      url: "/subcategory",
      method: "POST",
      data: params,
    })
      .then(({ data }) => {
        setNewSubcategoryId(data.id);
        // add key to data
        data.key = data.id;
        setSubcategories((prev) => [...prev, data]);
      })
      .catch(() =>
        toast.error("Ocurrió un error al crear categoría", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateFilter = (params) => {
    setIsLoading(true);

    const data = { ...params, subcategoryId: newSubcategoryId };

    axiosInstance({
      url: "/tags",
      method: "POST",
      data: data,
    })
      .then(() => {})
      .catch(() => {
        toast.error("Error al crear filtro de categoría", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchCategories = () => {
    setIsLoading(true);
    if (search) {
      const params = {
        userId,
        word: search,
      };

      axiosInstance({
        url: "/subcategory/search",
        data: params,
        method: "POST",
      })
        .then(({ data }) => {
          
          const newSubcategories = Object.values(data || {})?.map((subc) => ({
            key: subc.id,
            ...subc,
          }));

          setSubcategories([...newSubcategories]);

          setSubcategoriesCount(newSubcategories.length);

          setHasMore(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      const records = currentPage * 20;
      axiosInstance({
        url: `/subcategory/${userId}/1/${records}`,
        method: "GET",
      })
        .then(({ data }) => {
          const { content, number, totalPages, totalElements } = data;

          const newSubcategories = Object.values(content || {})?.map(
            (subc) => ({
              key: subc.id,
              ...subc,
            })
          );

          setSubcategories([...newSubcategories]);

          setSubcategoriesCount(totalElements);

          setHasMore(number + 1 < totalPages);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center justify-between p-2 w-full h-12 bg-white border-b-2 border-b-gray-200">
          <div className="flex flex-row w-full text-primary my-0 mx-2 cursor-pointer h-5 gap-10">
            <button
              className="flex flex-row justify-start align-middle gap-1"
              onClick={toggleCategoryModal}
              onKeyDown={toggleCategoryModal}
            >
              <i className="bi bi-plus-circle-dotted my-0 h-4 w-4"></i>
              <span>Nueva categoría</span>
            </button>
            <button
              className="flex flex-row justify-start align-middle gap-1"
              onClick={toggleGroupModal}
              onKeyDown={toggleGroupModal}
            >
              <i className="bi bi-plus-circle-dotted my-0 h-4 w-4"></i>
              <span>Nuevo grupo</span>
            </button>
            <button
              className="flex flex-row justify-start align-middle gap-1"
              onClick={() => {
                if (selectedRows.length > 0) {
                  toggleDeleteWarning();
                } else {
                  toast.warn("Debes seleccionar al menos una categoría", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }}
              onKeyDown={() => {
                if (selectedRows.length > 0) {
                  toggleDeleteWarning();
                } else {
                  toast.warn("Debes seleccionar al menos una categoría", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }}
            >
              <i className="bi bi-trash2 my-0 h-4 w-4"></i>
              <span>Eliminar</span>
            </button>
          </div>
          <div className="flex flex-row justify-end align-middle w-full">
            <Space.Compact>
              <AntdInput
                placeholder="Buscar"
                className="rounded-l-xl"
                onChange={(e) => setSearch(e.target.value)}
                onPressEnter={searchCategories}
              />
              <Button
                className="rounded-r-xl bg-primary"
                onClick={searchCategories}
              >
                <i className="bi bi-search relative text-white text-xs"></i>
              </Button>
            </Space.Compact>
          </div>
        </div>
        <Row className="tab-content-body">
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleInfiniteScroll}
            className="w-full"
            loader={<CustomLoader key={0} />}
          >
            <Form form={form}>
              <Table
                dataSource={subcategories}
                columns={columns}
                className="w-full"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                loading={isLoading}
                pagination={false}
                locale={{
                  emptyText: () => (
                    <p className="text-center text-xs font-medium text-gray-700 my-4">
                      No existen categorías
                    </p>
                  ),
                }}
              />
            </Form>
          </InfiniteScroll>
        </Row>
      </div>
      <AddEditSubcategory
        isOpen={isOpenCategoryModal}
        toggle={toggleCategoryModal}
        groupOptions={groupOptions}
        setSubcategories={setSubcategories}
        userId={userId}
        handleCreateCategory={handleCreateCategory}
        handleCreateFilter={handleCreateFilter}
      />
      <AddEditGroup
        isOpen={isOpenGroupModal}
        toggle={toggleGroupModal}
        handleCreateGroup={handleCreateGroup}
      />
      <DeleteCategoriesWarning
        isOpen={isOpenDeleteWarning}
        toggle={toggleDeleteWarning}
        handleDelete={handleDeleteSubcategries}
      />
      <CustomModalLoader isOpen={loadingEdit} />
      <ToastContainer />
    </>
  );
};

Body.propTypes = {
  userId: PropTypes.number.isRequired,
  setGroupsCount: PropTypes.func.isRequired,
  setSubcategoriesCount: PropTypes.func.isRequired,
};

export default Body;
