function HeroStack() {
  return (
    <div className="hero-stack">
      <div className="container">
        <div className="small-container">
          <div className="title-section">
            <div className="title-section">
              <div className="text-4">Toma Control de tus Finanzas.</div>
              <div className="text-5">
                OnMission es el herramienta de planificación de finanzas personales
                que necesitas.
              </div>
            </div>
            <div className="actions">
              <a href="/auth/login" target="_blank" className="button-2 button">
                <div className="text-6">Planifica tus finanzas gratis</div>
                <img
                  src={require("../images/Vectors-Wrapper.svg").default}
                  loading="lazy"
                  width="18"
                  height="18"
                  alt=""
                  className="vectors-wrapper-2"
                />
              </a>
            </div>
          </div>
          <div className="image-wrapper">
            <div className="div-block">
              <img
                src={require("../images/container2.svg").default}
                loading="lazy"
                width="730"
                height="225"
                alt="hero illustration"
                className="vectors-wrapper-3"
              />
            </div>
            <div className="frame-1000004402">
              <div className="frame-1000004265">
                <img
                  src={require("../images/Vectors-Wrapper_1.svg").default}
                  loading="lazy"
                  width="152.34898376464844"
                  height="152.34898376464844"
                  alt=""
                  className="vectors-wrapper-4"
                />
                <div className="frame-90426">
                  <div className="frame-90421">
                    <div className="frame-1000004261">
                      <img
                        src={require("../images/Vectors-Wrapper_2.svg").default}
                        loading="lazy"
                        width="15.23490047454834"
                        height="15.23490047454834"
                        alt=""
                        className="vectors-wrapper-5"
                      />
                      <div className="text-7">Entretenimiento</div>
                    </div>
                    <div className="frame-1000004411">
                      <div className="text-8">$5,408</div>
                      <div className="heading-h9">USD</div>
                    </div>
                  </div>
                  <div className="frame-90421">
                    <div className="frame-1000004261">
                      <img
                        src={require("../images/Vectors-Wrapper_3.svg").default}
                        loading="lazy"
                        width="15.23490047454834"
                        height="15.23490047454834"
                        alt=""
                        className="vectors-wrapper-5"
                      />
                      <div className="text-7">Educación</div>
                    </div>
                    <div className="frame-1000004411">
                      <div className="text-8">$7,246</div>
                      <div className="heading-h9">USD</div>
                    </div>
                  </div>
                  <div className="frame-90421">
                    <div className="frame-1000004262">
                      <img
                        src={require("../images/Vectors-Wrapper_4.svg").default}
                        loading="lazy"
                        width="15.23490047454834"
                        height="15.23490047454834"
                        alt=""
                        className="vectors-wrapper-5"
                      />
                      <div className="text-7">Aporte Social</div>
                    </div>
                    <div className="frame-1000004411">
                      <div className="text-8">$3,148</div>
                      <div className="heading-h9">USD</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-1000004405">
              <div className="frame-1000004271">
                <div className="frame-1000004268">
                  <div className="frame-1000004285">
                    <div className="heading-h4">$34,194</div>
                    <div className="heading-h7">USD</div>
                  </div>
                  <div className="body-med">Balance total</div>
                </div>
                <div className="frame-90421">
                  <div className="frame-1000004270">
                    <div className="frame-1000004286">
                      <div className="heading-h8">$27,040</div>
                      <div className="heading-h9">USD</div>
                    </div>
                    <div className="body-ss">Debito</div>
                  </div>
                  <div className="divider"></div>
                  <div className="frame-1000004270">
                    <div className="frame-1000004286">
                      <div className="heading-h8-2">$7,154</div>
                      <div className="heading-h9">USD</div>
                    </div>
                    <div className="body-ss-2">Credito</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-1000004403">
              <div className="frame-90405">
                <div className="frame-90417">
                  <div className="categor-as_16px circle_cat">
                    <div className="h">E</div>
                  </div>
                  <div className="text-9">Entretenimiento</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src={require("../images/Vectors-Wrapper_5.svg").default}
                        loading="lazy"
                        width="64"
                        height="9"
                        alt=""
                        className="vectors-wrapper-6"
                      />
                    </div>
                    <div className="text-10">94%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-2 circle_cat">
                    <div className="c">A</div>
                  </div>
                  <div className="text-9">Alquiler de Vivienda</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src={require("../images/Vectors-Wrapper_6.svg").default}
                        loading="lazy"
                        width="40"
                        height="9"
                        alt=""
                        className="vectors-wrapper-7"
                      />
                    </div>
                    <div className="text-10">27%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-3 circle_cat">
                    <div className="r">S</div>
                  </div>
                  <div className="text-9">Seguro de Automóvil</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src={require("../images/Vectors-Wrapper_7.svg").default}
                        loading="lazy"
                        width="58"
                        height="8"
                        alt=""
                        className="vectors-wrapper-8"
                      />
                    </div>
                    <div className="text-10">74%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-4 circle_cat">
                    <div className="a">G</div>
                  </div>
                  <div className="text-9">Gastos médicos</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src={require("../images/Vectors-Wrapper_8.svg").default}
                        loading="lazy"
                        width="25"
                        height="8"
                        alt=""
                        className="vectors-wrapper-9"
                      />
                    </div>
                    <div className="text-10">12%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-5 circle_cat">
                    <div className="text-11">C</div>
                  </div>
                  <div className="text-9">Cuidado personal</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src="images/Vectors-Wrapper_9.svg"
                        loading="lazy"
                        width="22"
                        height="8"
                        alt=""
                        className="vectors-wrapper-10"
                      />
                    </div>
                    <div className="text-10">10%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-6 circle_cat">
                    <div className="r-2">S</div>
                  </div>
                  <div className="text-9">Servicio de Agua</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src="images/Vectors-Wrapper_10.svg"
                        loading="lazy"
                        width="37"
                        height="8"
                        alt=""
                        className="vectors-wrapper-11"
                      />
                    </div>
                    <div className="text-10">21%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-7 circle_cat">
                    <div className="h-2">A</div>
                  </div>
                  <div className="text-9">Aporte Social</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src="images/Vectors-Wrapper_11.svg"
                        loading="lazy"
                        width="53"
                        height="8"
                        alt=""
                        className="vectors-wrapper-12"
                      />
                    </div>
                    <div className="text-10">58%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="categor-as_16px-8 circle_cat">
                    <div className="r-3">E</div>
                  </div>
                  <div className="text-9">Educación</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src="images/Vectors-Wrapper_12.svg"
                        loading="lazy"
                        width="65"
                        height="9"
                        alt=""
                        className="vectors-wrapper-13"
                      />
                    </div>
                    <div className="text-10">80%</div>
                  </div>
                </div>
                <div className="frame-90417">
                  <div className="circle_cat">
                    <div className="a-2">G</div>
                  </div>
                  <div className="text-9">Gastos Automómovil</div>
                  <div className="frame-1000004274">
                    <div className="frame-1000004272">
                      <img
                        src="images/Vectors-Wrapper_13.svg"
                        loading="lazy"
                        width="49.69565200805664"
                        height="8.282608985900879"
                        alt=""
                        className="vectors-wrapper-14"
                      />
                    </div>
                    <div className="text-10">60%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-1000004404">
              <div className="frame-1000004261">
                <div className="text-12">
                  <div className="text-12 text-12">
                    <span className="_36253100-0">$36,2531.</span>
                    <span className="_36253100-1">00</span>
                  </div>
                </div>
                <div className="heading-h7">USD</div>
                <div className="frame-1000002316">
                  <div className="heading-h7-2">+1.29%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroStack;
