import axios from "axios";

const getToken = () =>
  sessionStorage.getItem("token") ? sessionStorage.getItem("token") : "";

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = getAuthorizationHeader();
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
