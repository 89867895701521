import { Line } from "@ant-design/charts";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";

const GraphicsLoan = ({ dataGraphics }) => {
  const config = {
    data: dataGraphics,
    padding: "auto",
    xField: "year",
    yField: "value",

    seriesField: "category",
    xAxis: {
      // type: 'timeCat',
      tickCount: 10,
    },
    yAxis: {
      tickCount: 10,
    },
  };

  return <Line {...config} />;
};

export default GraphicsLoan;
