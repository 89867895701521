import React, { useCallback, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import axiosInstance from "../../../../utils/axiosIntance";
import { dollarUS } from "../../../../utils/dollarFormat";
import { getDate } from "../../../../utils/getDate";
import CustomLoader from "../../loader/CustomLoader";

import esES from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { ConfigProvider, DatePicker, Form, Progress, Select } from "antd";

dayjs.locale("es");

const CustomTooltipPie = ({ active, payload, title }) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col border-gray-300 shadow-sm p-2 rounded bg-gray-50 text-xs gap-2">
        <span className="capitalize">{`${payload[0].name}`}</span>
        <span className="inflows">{`${title}: ${dollarUS.format(
          payload[0].value
        )}`}</span>
      </div>
    );
  }

  return null;
};

const Graphical = ({ user: { userId }, loadingOptions, accountOptions }) => {
  const { RangePicker } = DatePicker;

  /**
   * State for loaders
   */
  const [loadingBars, setLoadingBars] = useState(false);
  const [loadingPie, setLoadingPie] = useState(false);

  /**
   * Bar chart data
   */
  const [barChartData, setBarChartData] = useState([]);
  const [totalBarChartData, setTotalBarChartData] = useState(0);

  /**
   * Pie chart data
   */
  const [pieIngresosData, setPieIngresosData] = useState([]);
  const [totalPieIngresosData, setTotalPieIngresosData] = useState(0);

  const [pieEgresosData, setPieEgresosData] = useState([]);
  const [totalPieEgresosData, setTotalPieEgresosData] = useState(0);

  const [accountId, setAccountId] = useState(0);

  /**
   * State for filters
   */
  const [selectedDate, setSelectedDate] = useState({
    start: new Date(),
    end: new Date(),
  });

  /**
   * State for pie middle text
   */
  const [inflowsPieText, setInflowsPieText] = useState(null);
  const [outflowsPieText, setOutflowsPieText] = useState(null);

  const handleBarChartData = useCallback(
    (start, end) => {
      const params = {
        accountId,
        userId,
        subcategoryId: 0,
        startDate: getDate(new Date(start)),
        endDate: getDate(new Date(end)),
      };

      console.log("Params", params);

      setLoadingBars(true);

      axiosInstance({
        method: "POST",
        url: "/transactions/getByMacroGroup",
        data: params,
      })
        .then(({ data }) => {
          const mappedData = data.map((item) => ({
            month: dayjs(item.month).format("YYYY-MM"),
            ingresos: item.ingresos,
            egresos: item.egresos,
          }));

          const { totalIngresos, totalEgresos } = data.reduce(
            (acc, item) => {
              acc.totalIngresos += item.ingresos || 0;
              acc.totalEgresos += item.egresos || 0;
              return acc;
            },
            { totalIngresos: 0, totalEgresos: 0 }
          );

          setBarChartData(mappedData);
          setTotalBarChartData(totalIngresos - totalEgresos);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingBars(false));
    },
    [accountId, userId]
  );

  const handlePieChartData = useCallback(
    (start, end) => {
      const params = {
        accountId,
        userId,
        subcategoryId: 0,
        startDate: getDate(new Date(start)),
        endDate: getDate(new Date(end)),
      };

      setLoadingPie(true);

      axiosInstance({
        method: "POST",
        url: "/transactions/getSubcategoryResumByGroup",
        data: params,
      })
        .then(({ data }) => {
          const ingresosData = data.filter(
            ({ category_type }) => category_type === "INGRESOS"
          );

          const egresosData = data.filter(
            ({ category_type }) => category_type === "EGRESOS"
          );

          const totalIngresos = ingresosData.reduce(
            (acc, item) => acc + item.total_amount,
            0
          );

          const totalEgresos = egresosData.reduce(
            (acc, item) => acc + item.total_amount,
            0
          );

          setPieIngresosData(ingresosData);
          setPieEgresosData(egresosData);
          setTotalPieIngresosData(totalIngresos);
          setTotalPieEgresosData(totalEgresos);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingPie(false));
    },
    [accountId, userId]
  );

  useEffect(() => {
    const { start, end } = selectedDate;

    handleBarChartData(start, end);
    handlePieChartData(start, end);
  }, [handleBarChartData, handlePieChartData, selectedDate]);

  const inflowsMouseEnter = ({ payload }) => {
    if (totalPieIngresosData === 0) {
      setInflowsPieText(0);
      return;
    }
    const percent = (
      (100 / totalPieIngresosData) *
      payload.total_amount
    ).toFixed(2);

    setInflowsPieText(percent);
  };

  const outflowsMouseEnter = ({ payload }) => {
    if (totalPieEgresosData === 0) {
      setOutflowsPieText(0);
      return;
    }
    const percent = (
      (100 / totalPieEgresosData) *
      payload.total_amount
    ).toFixed(2);

    setOutflowsPieText(percent);
  };

  return (
    <div className="flex flex-col w-full h-full p-6 bg-white gap-4">
      <div className="flex flex-row w-full justify-between items-center border-b pb-2">
        <div className="flex flex-col align-middle">
          <span
            style={{
              fontWeight: "510",
              fontSize: "16px",
              lineHeight: "19px",
            }}
            className="text-dark"
          >
            Visión general de transacciones
          </span>
        </div>
        <div className="flex flex-col md:flex-row h-full align-middle">
          <div className="flex flex-col w-full mx-4 h-full align-middle">
            <Form.Item name="account" className="mb-0">
              <Select
                placeholder="Todas las cuentas"
                notFoundContent={"No existen cuentas"}
                loading={loadingOptions}
                allowClear
                onChange={(value) => {
                  setAccountId(value);
                  handleBarChartData();
                }}
                className="w-52"
              >
                {accountOptions}
              </Select>
            </Form.Item>
          </div>
          <ConfigProvider locale={esES}>
            <RangePicker
              placeholder={["Fecha Inicial", "Fecha Final"]}
              format={"MM/YYYY"}
              className="w-64"
              onChange={(dates) => {
                const fechaInicio = dates[0].$d;
                const fechaFin = dates[1].$d;

                const endYear = fechaFin.getFullYear();
                const endMonth = fechaFin.getMonth() + 1;

                const endMonthYear = new Date(endYear, endMonth, 0);

                const startYear = fechaInicio.getFullYear();
                const startMonth = fechaInicio.getMonth();

                const startMonthYear = new Date(startYear, startMonth, 1);

                setSelectedDate({ start: startMonthYear, end: endMonthYear });

                handleBarChartData(startMonthYear, endMonthYear);
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between items-center">
        <div className="w-full">
          {loadingBars ? (
            <CustomLoader />
          ) : (
            <div className="flex flex-col w-full gap-3">
              {barChartData.length > 0 ? (
                <div className="flex flex-col w-full p-3 gap-3">
                  <div className="flex flex-row w-full h-full justify-between items-center">
                    <div className="flex gap-2 align-bottom">
                      <span
                        style={{
                          fontWeight: "274",
                          fontSize: "40px",
                          lineHeight: "48px",
                        }}
                      >
                        {dollarUS.format(totalBarChartData.toFixed(2))}
                      </span>
                      <span className="align-text-bottom">USD</span>
                    </div>
                    <div className="flex flex-row gap-4">
                      <div>
                        <i
                          className="bi bi-circle-fill"
                          style={{
                            color: "#2C7BE5",
                            width: "8px",
                            height: "8px",
                            marginRight: "4px",
                          }}
                        ></i>
                        <span>Ingresos</span>
                      </div>
                      <div>
                        <i
                          className="bi bi-circle-fill"
                          style={{
                            color: "#DC3545",
                            width: "8px",
                            height: "8px",
                            marginRight: "4px",
                          }}
                        ></i>
                        <span>Egresos</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full h-full justify-between items-center">
                    <div className="container min-h-10 max-h-80 w-full">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart width={800} height={400} data={barChartData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" />
                          <YAxis />
                          <Tooltip />
                          <Bar dataKey="ingresos" fill={"#2C7BE5"} />
                          <Bar dataKey="egresos" fill={"#DC3545"} />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row w-full h-full justify-between items-center">
                  <div className="container min-h-10 max-h-80 w-full">
                    <div className="text-center text-xs font-medium text-gray-700 my-4">
                      No hay información para los parámetros seleccionados
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row w-full justify-between items-center border-b pb-2">
        <div className="flex flex-col align-middle">
          <span
            style={{
              fontWeight: "510",
              fontSize: "16px",
              lineHeight: "19px",
            }}
            className="text-dark"
          >
            Reporte de categorías
          </span>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between items-center">
        <div className="w-full">
          {loadingPie ? (
            <CustomLoader />
          ) : (
            <div className="flex flex-col w-full gap-3">
              {pieEgresosData.length > 0 || pieIngresosData.length > 0 ? (
                <div className="flex flex-row w-full p-3 gap-3">
                  <div className="flex flex-col h-full w-1/5 p-4 ga-4">
                    <div className="flex flex-row w-full gap-3">
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row">
                          <span
                            style={{
                              fontWeight: "510",
                              fontSize: "24px",
                              lineHeight: "28px",
                            }}
                            className="text-success"
                          >
                            {`${dollarUS.format(
                              totalPieIngresosData - totalPieEgresosData
                            )}`}
                          </span>
                          <span
                            style={{
                              fontWeight: "510",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }}
                          >
                            USD
                          </span>
                        </div>
                        <div className="flex flex-row">
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                            className="text-success"
                          >
                            {`Balance total`}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-3">
                      <div className="flex flex-col w-1/2 gap-2">
                        <div className="flex flex-row gap-2">
                          <span
                            style={{
                              fontWeight: "510",
                              fontSize: "12px",
                              lineHeight: "13px",
                            }}
                            className="text-primary"
                          >
                            {`${dollarUS.format(totalPieIngresosData)}`}
                          </span>
                          <span
                            style={{
                              fontWeight: "510",
                              fontSize: "10px",
                              lineHeight: "12px",
                              color: "#ADB5BD",
                            }}
                          >
                            USD
                          </span>
                        </div>
                        <div className="flex flex-row">
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "14px",
                            }}
                            className="text-primary"
                          >
                            {`Crédito`}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2 gap-2">
                        <div className="flex flex-row gap-2">
                          <span
                            style={{
                              fontWeight: "510",
                              fontSize: "12px",
                              lineHeight: "13px",
                            }}
                            className="text-danger"
                          >
                            {`${dollarUS.format(totalPieEgresosData)}`}
                          </span>
                          <span
                            style={{
                              fontWeight: "510",
                              fontSize: "10px",
                              lineHeight: "12px",
                              color: "#ADB5BD",
                            }}
                          >
                            USD
                          </span>
                        </div>
                        <div className="flex flex-row">
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "14px",
                            }}
                            className="text-danger"
                          >
                            {`Débito`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-full w-4/5 p-4 ga-4">
                    <div className="flex flex-row w-full mb-3 pb-3 border-b align-middle">
                      <div className="flex flex-col w-full gap-3">
                        <div className="flex flex-row w-full text-lg text-primary">
                          Ingresos
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="flex flex-col h-full w-full">
                            {pieIngresosData.map((item, index) => {
                              const {
                                subcategory_name,
                                total_amount,
                                text_color,
                              } = item;

                              const barColor = "#2C7BE5";

                              return (
                                <div
                                  key={index}
                                  className="flex flex-row w-full h-full gap-2 items-center"
                                >
                                  <div className="w-1/5">
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                      }}
                                      className="text-gray-900"
                                    >
                                      {subcategory_name}
                                    </span>
                                  </div>
                                  <div className="flex flex-row w-3/5 h-full items-center">
                                    <Progress
                                      percent={
                                        (total_amount / totalPieIngresosData) *
                                        100
                                      }
                                      strokeColor={barColor}
                                      showInfo={false}
                                    />
                                  </div>
                                  <div className="flex flex-row w-1/5 justify-end items-center">
                                    <span
                                      className="flex flex-row w-full justify-end"
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {dollarUS.format(total_amount)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex flex-col w-full justify-center items-center">
                            <PieChart width={300} height={300}>
                              <Pie
                                data={pieIngresosData}
                                dataKey="total_amount"
                                nameKey="subcategory_name"
                                outerRadius={100}
                                innerRadius={85}
                                fill="#8884d8"
                                paddingAngle={5}
                                onMouseEnter={inflowsMouseEnter}
                                onMouseLeave={() => setInflowsPieText(null)}
                              >
                                {pieIngresosData.map((entry, index) => {
                                  const { text_color } = entry;

                                  const color = "#2C7BE5";
                                  return (
                                    <Cell key={`cell-${index}`} fill={color} />
                                  );
                                })}
                              </Pie>
                              <text
                                x={150}
                                y={150}
                                textAnchor="middle"
                                dominantBaseline="central"
                                className="text-xl"
                              >
                                {inflowsPieText ? `${inflowsPieText}%` : "-"}
                              </text>
                              <Tooltip
                                content={<CustomTooltipPie title="Ingresos" />}
                                position={{ x: 220, y: 200 }}
                              />
                            </PieChart>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full">
                      <div className="flex flex-col w-full gap-3">
                        <div className="flex flex-row w-full text-lg text-danger">
                          Egresos
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="flex flex-col h-full w-full">
                            {pieEgresosData.map((item, index) => {
                              const {
                                subcategory_name,
                                total_amount,
                                text_color,
                              } = item;

                              const barColor = "#DC3545";

                              return (
                                <div
                                  key={index}
                                  className="flex flex-row w-full h-full gap-2 items-center"
                                >
                                  <div className="w-1/5">
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                      }}
                                      className="text-gray-900"
                                    >
                                      {subcategory_name}
                                    </span>
                                  </div>
                                  <div className="flex flex-row w-3/5 h-full items-center">
                                    <Progress
                                      percent={
                                        (total_amount / totalPieIngresosData) *
                                        100
                                      }
                                      strokeColor={barColor}
                                      showInfo={false}
                                    />
                                  </div>
                                  <div className="flex flex-row w-1/5 justify-end items-center">
                                    <span
                                      className="flex flex-row w-full justify-end"
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {dollarUS.format(total_amount)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex flex-col w-full justify-center items-center">
                            <PieChart width={300} height={300}>
                              <Pie
                                data={pieEgresosData}
                                dataKey="total_amount"
                                nameKey="subcategory_name"
                                outerRadius={100}
                                innerRadius={85}
                                fill="#8884d8"
                                paddingAngle={5}
                                onMouseEnter={outflowsMouseEnter}
                                onMouseLeave={() => setOutflowsPieText(null)}
                              >
                                {pieEgresosData.map((entry, index) => {
                                  const { text_color } = entry;

                                  const color = "#DC3545";
                                  return (
                                    <Cell key={`cell-${index}`} fill={color} />
                                  );
                                })}
                              </Pie>
                              <text
                                x={150}
                                y={150}
                                textAnchor="middle"
                                dominantBaseline="central"
                                className="text-xl"
                              >
                                {outflowsPieText ? `${outflowsPieText}%` : "-"}
                              </text>
                              <Tooltip
                                content={<CustomTooltipPie title="Ingresos" />}
                                position={{ x: 220, y: 200 }}
                              />
                            </PieChart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row w-full h-full justify-between items-center">
                  <div className="container min-h-10 max-h-80 w-full">
                    <div className="text-center text-xs font-medium text-gray-700 my-4">
                      No hay información para los parámetros seleccionados
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Graphical;
