import { Button, Modal } from "antd";
import React from "react";
import PropTypes from 'prop-types';

const ConfirmDeleteTransactionModal = ({
  toggle,
  isOpen,
  handleDeleteTransactions,
}) => {
  return (
    <Modal
      title="Confirmar eliminar transacciones"
      open={isOpen}
      onCancel={toggle}
      footer={[
        <Button key={1} onClick={toggle}>No</Button>,
        <Button
          key={2}
          onClick={handleDeleteTransactions}
          className="bg-danger text-white"
        >
          Sí
        </Button>,
      ]}
    >
      <div className="container">
        <span>¿Desea eliminar la/s transacción/es?</span>
      </div>
    </Modal>
  );
};

ConfirmDeleteTransactionModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDeleteTransactions: PropTypes.func.isRequired,
};

export default ConfirmDeleteTransactionModal;
