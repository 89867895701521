import React, { useState } from "react";
import { ChevronLeft } from "react-feather";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import WhatsappButton from "../../assets/icons/whatsapp.svg";
import { Bell } from "react-feather";
import avatar from "../../assets/imgs/avatars/rcmen1.png";
import { Link } from "react-router-dom";

import { ACCOUNTS, GENERAL_SETTINGS } from "../../routes";
import { useAuth } from "../../hooks/userConstext";

const Navbar = () => {
  const { logout } = useAuth();
  const [notifications, setNotifications] = useState(105);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="container flex flex-row justify-between p-2 md:mx-6 relative bg-light">
      <div className="flex items-center gap-2">
        <Link
          className="flex items-center bg-transparent text-sm text-gray-500 hover:text-primary no-underline"
          to={`${ACCOUNTS}/0`}
        >
          <ChevronLeft />
          Volver a la app
        </Link>
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        <button
          disabled
          className="flex bg-white text-gray-500 border-0 rounded-md h-fit"
        >
          <img
            src={WhatsappButton}
            alt="Whatsapp"
            style={{ width: "24px", height: "24px" }}
          />
          {"Soporte"}
        </button>

        <div className="relative rounded-md text-white cursor-pointer select-none">
          <Badge
            pill
            color="danger"
            className="absolute rounded-sm px-2 bottom-4"
            style={{ fontSize: "9px" }}
          >
            {notifications > 99 ? "+99" : notifications}
          </Badge>
          <Bell fill="#adb5bd" stroke="#adb5bd" />
        </div>

        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="text-gray-500 text-base"
        >
          <DropdownToggle className="border-0 bg-transparent hover:bg-main">
            <img
              src={avatar}
              alt="avatar"
              style={{ width: "32px", height: "32px" }}
            />
          </DropdownToggle>
          <DropdownMenu className="mt-1">
            <DropdownItem>
              <Link
                to={`${ACCOUNTS}/0`}
                style={{ textDecoration: "none", color: "#6C757D" }}
              >
                Ir al dashboard
              </Link>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem disabled>
              <i
                className="bi bi-award-fill"
                style={{ fontSize: "14px", color: "#2C7BE5" }}
              ></i>
              Mejorar a PRO
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <Link
                to={GENERAL_SETTINGS}
                style={{ textDecoration: "none", color: "#6C757D" }}
              >
                Configuración de cuenta
              </Link>
            </DropdownItem>
            <DropdownItem
              style={{ textDecoration: "none", color: "#6C757D" }}
              onClick={logout}
            >
              Cerrar sesión
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
