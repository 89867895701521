import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RectangularLogo from "../../assets/imgs/logo/rectangular.svg";
import SquareLogo from "../../assets/imgs/logo/square.svg";
import { LOGIN } from "../../routes";
import { Col, Row } from "antd";

const Auth = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen">
      <Row className="w-full h-full p-0 m-0">
        <Col
          lg={8}
          md={12}
          sm={24}
          xs={24}
          className="h-full bg-light-primary content-evenly px-16 py-16 overflow-auto"
        >
          <Row>
            <img
              src={SquareLogo}
              alt="logo"
              width="54px"
              onClick={() => navigate(LOGIN)}
              style={{
                cursor: "pointer",
              }}
            />
          </Row>
          <Row>
            <Col className="w-full h-full ">
              <Row>
                <div className="flex flex-col w-full">{children}</div>
              </Row>
              <Row className="justify-evenly">
                <Link to="#">{"Términos de uso"}</Link> |{"  "}
                <Link to="#">{"Política de Privacidad"}</Link> |{"  "}
                <Link to="#">{"Acerca de"}</Link>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={16} md={12} sm={0} xs={0} className="h-full bg-blue">
          <div className="w-full h-full bg-cover welcome-background">
            <div className="bg-blue-600/70 w-full h-full flex justify-center align-middle">
              <img src={RectangularLogo} alt="OnMission" className="w-1/5" />
            </div>
          </div>
        </Col>
      </Row>
      {/* <div className="flex flex-row w-auto h-auto">
        <div className="lg:w-2/3">
          <div className="w-full h-full bg-cover welcome-background">
            <div className="bg-blue-600/70 w-full h-full flex justify-center align-middle">
              <img src={RectangularLogo} alt="OnMission" className="w-1/5" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Auth;
