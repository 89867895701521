import { ConfigProvider, DatePicker, Form, Input } from "antd";
import React from "react";

import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import { Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import CustomModalLoader from "../customModalLoader/CustomModalLoader";
import { useState } from "react";
import axios from "axios";

import { LOGIN, CONFIRM_SIGN_UP } from "../../routes";

import esEs from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useNavigate } from "react-router-dom";

const SignUpForm = ({ setUserEmail }) => {
  const navigate = useNavigate();

  /**
   * Form instance
   */
  const [form] = Form.useForm();

  /**
   * State for loader
   */
  const [loadingSignUp, setLoadingSignUp] = useState(false);

  /**
   * Sign up handler
   */
  const handleSignUp = async () => {
    // setLoadingSignUp(true);
    try {
      await form.validateFields();

      const dateOfBirth = new Date(form.getFieldValue("dateOfBirth").$d);

      const year = dateOfBirth.toLocaleString("default", { year: "numeric" });
      const month = dateOfBirth.toLocaleString("default", { month: "2-digit" });
      const day = dateOfBirth.toLocaleString("default", { day: "2-digit" });

      const email = form.getFieldValue("email");

      const data = {
        email,
        firstName: form.getFieldValue("firstName"),
        lastName: form.getFieldValue("lastName"),
        dateOfBirth: `${year}-${month}-${day}`,
        country: "PA",
        password: form.getFieldValue("password"),
        phone: form.getFieldValue("phone"),
      };

      axios({
        url: `${process.env.REACT_APP_SERVER}/auth/sign-up`,
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER,
        },
      })
        .then(() => {
          setUserEmail(email);

          setLoadingSignUp(false);

          navigate(CONFIRM_SIGN_UP);

          //setCurrentForm("tokenSent");
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response?.data || "Error al registrar usuario", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });

          setLoadingSignUp(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col h-full gap-7">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h1 className="text-primary font-roboto">Registrarse</h1>
          <h6 className="text-gray-600 font-roboto mt-[7px] mb-0">
            Bienvenido a OnMission
          </h6>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col w-full">
          <Form form={form}>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col lg:w-1/2 sm:w-full">
                  <Form.Item
                    className="mb-2"
                    name="firstName"
                    rules={[
                      { required: true, message: "Este campo es obligatorio" },
                    ]}
                  >
                    <Input
                      className="w-full"
                      placeholder="Nombre"
                      size="small"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col lg:w-1/2 sm:w-full">
                  <Form.Item
                    className="mb-2"
                    name="lastName"
                    rules={[
                      { required: true, message: "Este campo es obligatorio" },
                    ]}
                  >
                    <Input
                      className="w-full"
                      placeholder="Apellido"
                      size="small"
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                className="mb-2"
                name="email"
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="Correo electrónico"
                  size="small"
                />
              </Form.Item>
              <Form.Item
                className="mb-2"
                name="password"
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <Input.Password
                  className="w-full"
                  placeholder="Contraseña"
                  size="small"
                />
              </Form.Item>
              <Form.Item
                className="mb-2"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Las contraseñas no coinciden");
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="w-full"
                  placeholder="Confirmar contraseña"
                  size="small"
                />
              </Form.Item>
              <ConfigProvider locale={esEs}>
                <Form.Item
                  className="mb-2"
                  name="dateOfBirth"
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    className="w-full h-8"
                    placeholder="Fecha de nacimiento"
                    showToday={false}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                  />
                </Form.Item>
              </ConfigProvider>
              <Form.Item
                className="mb-2"
                name="country"
                /* rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]} */
              >
                <ReactFlagsSelect
                  disabled
                  selected={"PA"}
                  customLabels={{
                    PA: "Panamá",
                  }}
                  countries={["PA"]}
                  className="h-11"
                />
              </Form.Item>
              <Form.Item
                className="mb-2"
                name="phone"
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <PhoneInput
                  country={"pa"}
                  inputStyle={{
                    width: "100%",
                    height: "32px",
                    fontSize: "12px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    backgroundColor: "#fff",
                  }}
                  dropdownStyle={{
                    borderRadius: "5px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                  }}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col gap-2 justify-center w-full">
          <Button
            color="primary"
            className="flex flex-row"
            onClick={handleSignUp}
          >
            Registrarse
          </Button>
          <span className="text-center">o</span>
          <Button
            className="btn-google-login"
            style={{ width: "100%" }}
            disabled
          >
            Registrarse con Google
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-center text-sm text-gray-600">
        ¿Ya tienes cuenta?{" "}
        <button
          className="border-0 text-primary"
          onClick={() => navigate(LOGIN)}
        >
          {"Inicia sesión"}
        </button>
      </div>
      <ToastContainer />
      <CustomModalLoader isOpen={loadingSignUp} />
    </div>
  );
};

export default SignUpForm;
