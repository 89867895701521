function Steps() {
  return (
    <div id="funciona" className="steps">
      <div className="container-3">
        <div className="title-section-2">
          <div className="text-25">Tus finanzas bajo control</div>
          <div className="text-26">
            Solo necesitas 3 simples pasos para tener tus finanzas planificada
          </div>
        </div>
        <div className="columns-4 f2wf-columns">
          <div className="card-2">
            <div className="image-wrapper-2">
              <div className="text-27">1</div>
            </div>
            <div className="content-3">
              <div className="content-3">
                <div className="text-25">Registro</div>
                <div className="text-28">
                  Utilizar tu email para crear tu cuenta en 
                  segundos
                </div>
              </div>
            </div>
          </div>
          <div className="card-2">
            <div className="image-wrapper-2">
              <div className="text-27">2</div>
            </div>
            <div className="content-3">
              <div className="content-3">
                <div className="text-25">Crea tus cuentas</div>
                <div className="text-28">
                  Crea tus categorías y carga tus transacciones de forma fácil y
                  rápida
                </div>
              </div>
            </div>
          </div>
          <div className="card-2">
            <div className="image-wrapper-2">
              <div className="text-27">3</div>
            </div>
            <div className="content-3">
              <div className="content-3">
                <div className="text-25">Transacciones</div>
                <div className="text-28">
                  Planifica tu presupuesto y ten control de tus gastos con
                  gráficas interactivas
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-29">
          ¡Ya no esperes al 15 y al 30, manejar tus finanzas nunca ha sido tan
          facil!
        </div>
      </div>
    </div>
  );
}

export default Steps;
