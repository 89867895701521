function Metrics() {
  return (
    <div className="features-metrics">
      <div className="container-2">
        <div className="small-container-2">
          <div className="columns f2wf-columns">
            <div className="card">
              <div className="image">
                <img
                  src={require("../images/Vectors-Wrapper_14.svg").default}
                  loading="lazy"
                  width="260"
                  height="188"
                  alt=""
                  className="vectors-wrapper-15"
                />
              </div>
              <div className="frame-1000004401">
                <div className="text-13">Controla tus gastos</div>
              </div>
            </div>
            <div className="vectors-wrapper-16"></div>
            <div className="card">
              <div className="image-2">
                <img
                  src={require("../images/Vectors-Wrapper_15.svg").default}
                  loading="lazy"
                  width="260"
                  height="260"
                  alt=""
                  className="vectors-wrapper-17"
                />
              </div>
              <div className="text-13">Administra tus ingresos</div>
            </div>
            <div className="vectors-wrapper-16"></div>
            <div className="card">
              <div className="image-2">
                <img
                  src={require("../images/Vectors-Wrapper_16.svg").default}
                  loading="lazy"
                  width="260"
                  height="260"
                  alt=""
                  className="vectors-wrapper-17"
                />
              </div>
              <div className="text-14">Alcanza tus objetivos financieros</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Metrics;
