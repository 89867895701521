import { useEffect, useState } from "react";
import axiosInstance from "../axiosIntance";

export const useFetchAccountsByUser = (userId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    axiosInstance({
      method: "GET",
      url: `/accounts/list-accounts/${userId}`,
    })
      .then((res) => setData(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [userId]);
  return { loading, data };
};
