import React, { useState } from "react";
import { colourOptions } from "../../../utils/colourOptions";
import { Button, Form, Input, Modal, Select } from "antd";
import PropTypes from 'prop-types';

const AddEditSubcategory = ({
  isOpen,
  toggle,
  groupOptions,
  userId,
  handleCreateCategory,
  handleCreateFilter,
}) => {
  /**
   * Instance of forms
   */
  const [subcategoryForm] = Form.useForm();
  const [subcategoryFilterForm] = Form.useForm();

  /**
   * Account modal content handler
   */
  const [step, setStep] = useState(0);

  /**
   * Close modal handler
   */
  const handleCloseModal = () => {
    subcategoryForm.resetFields();
    setStep(0);
    toggle();
  };

  const handleSubmit = async () => {
    try {
      const { name, group, color } = await subcategoryForm.validateFields();
      const params = {
        name: name,
        description: "",
        bgColor: color + "bb",
        textColor: color,
        createdAt: new Date(),  
        categoryId: group,
        userId,
      };
      await handleCreateCategory(params);      
      setStep(step + 1);
  
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    } 
  };

  const handleFilterSubmit = async () => {
    try {
      const { filterValue } = await subcategoryFilterForm.validateFields();

      const params = {
        filter: filterValue,
        description: "",
        userId,
      };

      await handleCreateFilter(params);
      setStep(step + 1);
    }
    catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  let footerButtons;
  if (step === 0) {
    footerButtons = [
      <Button key="cancel" onClick={handleCloseModal}>Cancelar</Button>,
      <Button
        key="create0"
        type="primary"
        htmlType="submit"
        onClick={handleSubmit}
        className="bg-primary text-white"
      >
        Crear
      </Button>,
    ];
  } else if (step === 1) {
    footerButtons = [
      <Button
        key="omit"
        onClick={() => {
          setStep(step + 1);
          subcategoryFilterForm.resetFields();
        }}
      >
        Omitir
      </Button>,
      <Button
        key="create1"
        onClick={handleFilterSubmit}
        className="bg-primary text-white"
      >
        Crear
      </Button>,
    ];
  } else {
    footerButtons = [
      <Button
        key="createAnother"
        onClick={() => {
          setStep(0);
        }}
      >
        Crear otra
      </Button>,
      <Button
        key="continue"
        className="bg-primary text-white"
        onClick={handleCloseModal}
      >
        Continuar
      </Button>,
    ];
  }

  return (
    <Modal
      title="Crear categoría"
      open={isOpen}
      onCancel={handleCloseModal}
      footer={footerButtons}
    >
      {step === 0 && (
        <Form form={subcategoryForm} onFinish={handleSubmit}>
          <div className="flex flex-col gap-2 my-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="nameValue">Nombre</label>
              <Form.Item
                className="mb-2"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="Ingrese el nombre de la categoría"
                />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="groupValue">Grupo</label>
              <Form.Item
                className="mb-2"
                name="group"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
              <Select placeholder="Seleccione un grupo">
                {groupOptions.map(({ id, name }) => (
                  <Select.Option key={id+"-"+step} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
              </Form.Item>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="colorValue">Color</label>
              <Form.Item
                className="mb-2"
                name="color"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio!",
                  },
                ]}
              >
                <Select placeholder="Seleccione un color para la categoría">
                  {colourOptions.map(({ color, label }) => (
                    <Select.Option key={color+"-"+step} value={color}>
                      <i
                        className="bi bi-circle-fill"
                        style={{
                          color: color,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      />
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
      {step === 1 && (
        <div className="flex flex-col h-full gap-7">
          <h6 className="text-gray-600 mt-2 mb-0">
            Selecciona una categoría y agrega una palabra para que sea
            categorizada automáticamente al importar un archivo.
          </h6>
          <div className="flex flex-col w-full">
            <Form form={subcategoryFilterForm} component={false}>
              <div className="flex flex-col gap-2">
                <label htmlFor="filterValue">Valor del filtro</label>
                <Form.Item
                  className="mb-2"
                  name="filterValue"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio!",
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    placeholder="Ingrese el valor del filtro"
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="container">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row w-full justify-center">
              <i
                className="bi bi-patch-check-fill"
                style={{
                  color: "#198754",
                  fontSize: "68px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></i>
            </div>
            <div className="flex flex-row w-full justify-center">
              <h4>La categoría se ha creado con éxito</h4>
            </div>
            <div className="flex flex-row w-full justify-center text-center">
              Ahora puedes crear transacciones y categorizarlas, así como
              también crear nuevos filtros para autocategorizar las
              transacciones importadas o creadas manualmente.
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

AddEditSubcategory.propTypes = {
  groupOptions: PropTypes.array.isRequired,
  userId: PropTypes.number.isRequired,
  handleCreateCategory: PropTypes.func.isRequired,
  handleCreateFilter: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,

  // other propTypes...
};

export default AddEditSubcategory;
