function Banner2() {
  return (
    <div className="first-cta">
      <div className="container-2">
        <div className="small-container-2">
          <div className="columns-2 f2wf-columns">
            <div className="text-15">
              <div className="text-16">
                Inicia tu misión hacia la libertad financiera
              </div>
              <div className="text-17">
                No mas tablas de excel, ni aplicaciones complicadas de usar
              </div>
            </div>
            <a
              href="/auth/login"
              className="link-block-2 button w-inline-block"
            >
              <div className="text-block">COMENZAR GRATIS</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner2;
