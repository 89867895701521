import React, { useState } from "react";
/* import Select from "react-select"; */

import { dollarUS } from "../../../utils/dollarFormat";

import DragDropZone from "./DragDropZone";
import DataPreview from "./DataPreview";
import { ToastContainer, toast } from "react-toastify";

import { useFetchAccountsByUser } from "../../../utils/fetch/fetchAccounts";
import axiosInstance from "../../../utils/axiosIntance";
import axios from "axios";
import { getSessionStorageItem } from "../../../utils/service/localStorageService";
import PreviewFinalFile from "./PreviewFinalFile";
import CustomLoader from "../../dashboard/loader/CustomLoader";

import { ACCOUNTS } from "../../../routes";
import { Button, Popconfirm, Select } from "antd";
import { useNavigate } from "react-router-dom";

const StepComponent = ({ step, setStep }) => {
  const navigate = useNavigate();
  const { email, id: userId } = JSON.parse(getSessionStorageItem("user"));

  const { data: accountsData, loading: accountsLoading } =
    useFetchAccountsByUser(userId);

  /** Accounts states */
  const [selectedAccount, setSelectedAccount] = useState(null);

  /** Import file state */
  const [file, setFile] = useState(null);
  const [fileHeaders, setFileHeaders] = useState([]);
  let fileUrl = "";

  /** Assign data states */
  const [assignedData, setAssignedData] = useState([]);

  /* Loader for upload file */
  const [loadingUploadToDB, setLoadingUploadToDB] = useState(false);
  const [loadingUploadToCloud, setLoadingUploadToCloud] = useState(false);

  /* State to store the response of file uploaded */
  const initialUploadValue = { added: 0, conflict: 0, duplicated: 0 };
  const [uploadResponse, setUploadResponse] = useState(initialUploadValue);

  /** Account step handlers */
  const toImportFileStep = () => {
    if (!selectedAccount) {
      toast.error("Debe seleccionar una cuenta", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setFile(null);
    setStep((prev) => prev + 1);
  };

  /** Import file step handlers */
  const backToAccountsStep = () => {
    setSelectedAccount(null);
    setStep((prev) => prev - 1);
  };

  const toDataPreview = () => {
    if (!file) {
      toast.error("Debe seleccionar un documento para importar", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setAssignedData([]);
    setStep((prev) => prev + 1);
  };

  /** Data preview handlers */
  const backToImportFileStep = () => {
    setStep((prev) => prev - 1);
  };

  const toPreviewFinalFile = () => {
    const twoColumns = !fileHeaders
      .map((item) => item.toLowerCase())
      .includes("monto");

    if (assignedData.length === 0) {
      toast.error("Debe asignar una valores a las columnas obligatorias", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });

      return;
    } else {
      if (twoColumns) {
        if (
          assignedData[0].date &&
          assignedData[2].description &&
          assignedData[4].debit &&
          assignedData[5].credit
        ) {
          setStep((prev) => prev + 1);
        } else {
          if (!assignedData[0].date)
            toast.error('Debe asignar una columna a para el campo de "Fecha"', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });

          if (!assignedData[2].description)
            toast.error(
              'Debe asignar una columna a para el campo de "Descripción"',
              {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "light",
              }
            );

          if (!assignedData[4].debit)
            toast.error(
              'Debe asignar una columna a para el campo de "Débito"',
              {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "light",
              }
            );

          if (!assignedData[5].credit)
            toast.error('Debe asignar una columna a para el campo de "Cr"', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
        }
      } else {
        if (
          assignedData[0].date &&
          assignedData[2].description &&
          assignedData[4].ammount
        ) {
          setStep((prev) => prev + 1);
        } else {
          if (!assignedData[0].date)
            toast.error('Debe asignar una columna a para el campo de "Fecha"', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });

          if (!assignedData[2].description)
            toast.error(
              'Debe asignar una columna a para el campo de "Descripción"',
              {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "light",
              }
            );

          if (!assignedData[4].ammount)
            toast.error('Debe asignar una columna a para el campo de "Monto"', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
        }
      }
    }
  };

  /** Preferences handlers */
  const backToDataPreview = () => {
    //setAssignedData([]);
    setStep((prev) => prev - 1);
  };

  const toSuccessProcces = async () => {
    setLoadingUploadToCloud(true);
    setLoadingUploadToDB(true);
    const url = `${process.env.REACT_APP_CLOUDINARY_URL}/raw/upload`;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET);
    formData.append("folder", email);
    formData.append("public_id", file.name);

    await axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((response) => {
        fileUrl = response.data.url;
      })
      .catch((err) =>
        toast.error("Error al cargar el documento", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        })
      )
      .finally(() => {
        setLoadingUploadToCloud(false);
      });

    const importFileData = {
      userEmail: email,
      accountId: selectedAccount,
      headers: assignedData,
      url: fileUrl,
      /**
       * Indicates if the file has Inflows/Outflows columns or just a single column with ammount
       */
      hasTwoColumns: !fileHeaders
        .map((item) => item.toLowerCase())
        .includes("monto"),
    };

    await axiosInstance({
      url: "/transactions/add-multiple-transactions",
      method: "post",
      data: importFileData,
    })
      .then(({ data }) => {
        setUploadResponse(data);
        setStep((prev) => prev + 1);
        toast.success("Se ha importado su información", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.error("Error al importar las transacciones", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setLoadingUploadToDB(false);
      });
  };

  if (accountsLoading) return <CustomLoader />;

  if (loadingUploadToCloud || loadingUploadToDB) return <CustomLoader />;

  switch (step) {
    case 1:
      return (
        <>
          <div className="flex flex-col justify-center items-center w-full h-full border-2 gap-4 p-5">
            <i className="text-2xl text-primary bi bi-bank2"></i>
            <span className="settings-subtitle">
              Seleccione la cuenta en donde deseas importar la data
            </span>
            {
              <Select
                placeholder="Seleccione una cuenta"
                className="w-1/2"
                onChange={setSelectedAccount}
              >
                {accountsData.map((account) => (
                  <Select.Option key={account.id} value={account.id}>
                    <div className="flex justify-between w-full">
                      <span>{account.name}</span>
                      <span>{dollarUS.format(account.balance)}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            }
          </div>
          <div className="flex flex-row w-full justify-end items-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={toImportFileStep}
            >
              Siguiente
            </button>
          </div>
        </>
      );
    case 2:
      return (
        <div className="p-2">
          <DragDropZone
            file={file}
            setFile={setFile}
            setFileHeaders={setFileHeaders}
          />
          <div className="flex flex-row w-full justify-between items-center">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={backToAccountsStep}
            >
              Atrás
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toDataPreview}
            >
              Siguiente
            </button>
          </div>
        </div>
      );
    case 3:
      return (
        <>
          <DataPreview
            setAssignedData={setAssignedData}
            fileHeaders={fileHeaders}
            setFileHeaders={setFileHeaders}
            file={file}
          />
          <div className="flex flex-row w-full justify-between items-center">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={backToImportFileStep}
            >
              Atrás
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toPreviewFinalFile}
            >
              Siguiente
            </button>
          </div>
        </>
      );
    case 4:
      return (
        <>
          <PreviewFinalFile
            headers={assignedData}
            file={file}
            assignedData={assignedData}
          />

          <div className="flex flex-row w-full justify-between items-center">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={backToDataPreview}
            >
              Atrás
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toSuccessProcces}
            >
              Siguiente
            </button>
          </div>
        </>
      );
    default: {
      const { added, conflict, duplicated } = uploadResponse;
      return (
        <div className="flex flex-col w-full h-full justify-center items-center gap-3">
          <div className="flex justify-center items-center rounded-full bg-white text-success text-6xl ">
            <i className="bi bi-patch-check-fill"></i>
          </div>
          <div className="flex flex-col w-full h-fit justify-center items-center">
            <span>¡Excelente!</span>
            <span>La importación ha sido completada.</span>
            <ul className="p-3 space-y-3">
              <li className="space-x-2">
                <i className="text-success bi bi-record-circle-fill"></i>
                <span>{`${added} transacciones importadas y categorizadas.`}</span>
              </li>
              <li className="space-x-2">
                <i className="text-warning bi bi-record-circle-fill"></i>
                <span>{`${conflict} transacciones importadas y pendientes de categorizar.`}</span>
              </li>
              <li className="space-x-2">
                <i className="text-danger bi bi-record-circle-fill"></i>
                <span>{`${duplicated} transacciones previamente importadas.`}</span>
              </li>
            </ul>
            <div className="flex flex-row justify-end">
              <Popconfirm
                title="Desea importar otro archivo?"
                onConfirm={() => setStep(1)}
                cancelText="No"
                okText="Sí"
                okButtonProps={{ className: "bg-primary text-white" }}
              >
                <Button>Importar otro archivo</Button>
              </Popconfirm>
              ,
              <Popconfirm
                title="Desea volver al inicio?"
                onConfirm={() => navigate(`${ACCOUNTS}/0`)}
                cancelText="No"
                okText="Sí"
                okButtonProps={{ className: "bg-primary text-white" }}
              >
                <Button className="bg-primary text-white">
                  Volver al inicio
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      );
    }
  }
};

const Stepper = () => {
  const steps = [
    {
      title: "Seleccionar cuenta",
      icon: <i className="text-2xl bi bi-bank2"></i>,
    },
    {
      title: "Selecciona tu archivo",
      icon: <i className="text-2xl bi bi-file-earmark-arrow-up"></i>,
    },
    {
      title: "Organizar contenido",
      icon: <i className="text-2xl bi bi-ui-checks-grid"></i>,
    },
    {
      title: "Preferencias",
      icon: <i className="text-2xl bi bi-toggles"></i>,
    },
  ];

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        {steps.map((step, index) => {
          return (
            <div
              key={index}
              className={`step-item ${currentStep === index + 1 && "active"} ${
                index + 1 < currentStep && "complete"
              }`}
            >
              <div className="relative step active">
                {step.icon}
                {index + 1 < currentStep && (
                  <div className="flex justify-center items-center rounded-full bg-white text-success absolute left-2/3 top-2/3 w-5 h-5">
                    <i className="bi bi-patch-check-fill"></i>
                  </div>
                )}
              </div>
              <span
                className={`${
                  currentStep === index + 1 ? "text-primary" : "text-gray-500"
                } text-xs justify-center`}
              >
                {step.title}
              </span>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-4 w-full h-auto">
        <StepComponent step={currentStep} setStep={setCurrentStep} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Stepper;
